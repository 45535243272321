import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { State } from "@types";
import { setVisitFilter } from "shared/state/ui/careAccess/actions";
import CentersComboBox from "features/careAccess/components/CentersComboBox";
import Typography from "components/Typography";
import Chip from "components/Chip";
import { MemberClinic } from "shared/fetch/src/models/MemberClinic";

interface IVisitsFilterSectionProps {
  hasMultipleCenters: boolean;
  allClinics: MemberClinic[];
}

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  careFilters: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "23px",
    [breakpoints.down("md")]: {
      display: "block",
    },
  },
  chipsWrapper: {
    alignSelf: "center",
    [breakpoints.down("md")]: {
      marginTop: "8px",
      marginBottom: "6px",
    },
  },
  chip: {
    color: palette.secondary.main,
    border: `1px solid ${palette.secondary.main}`,
    backgroundColor: palette.bluePastelAlt,
    borderRadius: "15px",
    padding: "0px 10px",
    margin: "0px 0px 0px 10px",
    "&:hover": {
      color: palette.secondary.main,
      border: `1px solid ${palette.secondary.main}`,
      backgroundColor: `${palette.bluePastelAlt} !important`,
    },
    [breakpoints.down("md")]: {
      margin: "0px 10px 0px 0px",
    },
  },
  activeChip: {
    color: palette.white,
    backgroundColor: `${palette.secondary.main} !important`,
    fontWeight: 700,
    "&:hover": {
      color: palette.white,
      backgroundColor: `${palette.secondary.main} !important`,
      fontWeight: 700,
    },
  },
}));

const VisitsFilterSection: FunctionComponent<IVisitsFilterSectionProps> = ({
  hasMultipleCenters,
  allClinics,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { visits, centers } = useSelector(
    (state: State) => state.ui.careAccess
  );

  const chipFilterOptions = [
    { label: "In person", value: "in_person" },
    { label: "Video/Phone", value: "virtual" },
  ];

  const handleChipFilter = (value: string) => {
    dispatch(setVisitFilter(value));
  };

  return (
    <div className={classes.careFilters}>
      {hasMultipleCenters ? (
        <CentersComboBox allClinics={allClinics} />
      ) : (
        <Typography
          appearance="smallBody"
          color="textSecondary"
          style={{ alignSelf: "center" }}
        >
          {centers.centerName}
        </Typography>
      )}

      <div className={classes.chipsWrapper}>
        {chipFilterOptions.map((chip, index) => {
          const isSelected = chip.value === visits.visitFilter;
          return (
            <Chip
              key={index}
              label={chip.label}
              onClick={() => handleChipFilter(chip.value)}
              variant="outlined"
              aria-label={isSelected ? `${chip.label}, selected` : chip.label}
              className={clsx(classes.chip, isSelected && classes.activeChip)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default VisitsFilterSection;
