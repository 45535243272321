// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     AppointmentTypeConfigSchema,
    AppointmentTypeConfigSchemaFromJSON,
    AppointmentTypeConfigSchemaToJSON,
} from './AppointmentTypeConfigSchema';
import {
     AppointmentTypeConfigsResponseBase,
    AppointmentTypeConfigsResponseBaseFromJSON,
    AppointmentTypeConfigsResponseBaseToJSON,
} from './AppointmentTypeConfigsResponseBase';
import {
     PaginationProps,
    PaginationPropsFromJSON,
    PaginationPropsToJSON,
} from './PaginationProps';
/**
 * @type AppointmentTypeConfigsResponse
 * @export
 */
export interface AppointmentTypeConfigsResponse extends AppointmentTypeConfigsResponseBase, PaginationProps {
}

export function AppointmentTypeConfigsResponseFromJSON(json: any): AppointmentTypeConfigsResponse {
    return {
        ...AppointmentTypeConfigsResponseBaseFromJSON(json),
        ...PaginationPropsFromJSON(json),
    };
}

export function AppointmentTypeConfigsResponseToJSON(value?: AppointmentTypeConfigsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AppointmentTypeConfigsResponseBaseToJSON(value),
        ...PaginationPropsToJSON(value),
    };
}

