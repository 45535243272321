import React, { FunctionComponent } from "react";
import FormControlLabelComponent, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

const FormControlLabel: FunctionComponent<FormControlLabelProps> = (props) => {
  return <FormControlLabelComponent {...props} />;
};

export default FormControlLabel;
