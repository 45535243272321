import { useQuery } from "react-query";
import { fetchEpisodesOfCareApi } from "shared/fetch/src/apis/EpisodesOfCareApi";

const episodeMessageSubjectsKey = () => ["episodeMessageSubjects"];

export default () => {
  const {
    data,
    isLoading: isPending,
    isSuccess,
    isError,
    refetch,
  } = useQuery(episodeMessageSubjectsKey(), () =>
    fetchEpisodesOfCareApi.fetchEpisodeMessageSubjects()
  );

  return {
    data,
    isPending,
    isSuccess,
    isError,
    refetch,
  };
};
