import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import { State } from "@types";
import useGetMemberClinics from "shared/features/selfSchedule/useGetMemberClinics";
import { MemberClinic } from "shared/fetch/src/models/MemberClinic";
import IOutletContext from "./OutletContextInterface";

const useFetchCenters = () => {
  const { memberId } = useOutletContext<IOutletContext>();
  const { services, locationState } = useSelector(
    (state: State) => state.ui.careAccess
  );
  const { memberClinics, isPending } = useGetMemberClinics(
    {
      id: memberId!,
      usState: locationState.code,
      serviceLineId: services.serviceLineId,
    },
    { enabled: !!memberId }
  );

  const unsortedClinics: MemberClinic[] = memberClinics
    ? [
        ...(memberClinics.closestClinics || []),
        ...(memberClinics.otherClinics || []),
      ]
    : [];

  const allClinics = unsortedClinics.sort((a, b) => {
    if (a?.name && b?.name && a?.name < b?.name) {
      return -1;
    } else {
      return 1;
    }
  });

  return {
    isPending,
    allClinics,
  };
};

export default useFetchCenters;
