export const Steps = {
  SERVICES: "services",
  DECISIONS: "decisions",
  MESSAGE: "messages",
  CENTERS: "centers",
  VISITS: "visits",
  DATE_PICKER: "date-picker",
  FINALIZE: "finalize",
};

export const MessagesFlow = {
  SERVICES: "services",
  DECISIONS: "decisions",
  MESSAGE: "messages",
};

export const VisitsFlow = {
  SERVICES: "services",
  DECISIONS: "decisions",
  CENTERS: "centers",
  VISITS: "visits",
  DATE_PICKER: "date-picker",
  FINALIZE: "finalize",
};
