import React from "react";
import { useOutletContext } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { setCurrentStep } from "shared/state/ui/careAccess/actions";
import Asset from "components/Asset";
import Typography from "components/Typography";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    maxWidth: "313px",
    margin: "46px auto auto auto",
    textAlign: "center",
  },
  text: {
    color: palette?.links?.secondaryHover,
    marginTop: "19px",
  },
}));

const PickerEmptyState = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId, isMinor, clinicContact } =
    useOutletContext<IOutletContext>();

  return (
    <div className={classes.wrapper}>
      {isMinor ? (
        <>
          <Asset name="care-empty-state" hiddenFromScreenReader />
          <Typography appearance="h5" color="textSecondary">
            There are no providers available for this visit type
          </Typography>
          <Typography appearance="body" className={classes.text}>
            {`Call your Care Team at ${clinicContact} to get assistance.`}
          </Typography>
          <Typography appearance="body" className={classes.text}>
            If this is an emergency call 911.
          </Typography>
        </>
      ) : (
        <>
          <Asset name="care-empty-state" hiddenFromScreenReader />
          <Typography appearance="h5" color="textSecondary">
            There are no providers available for this visit type
          </Typography>
          <Typography appearance="body" className={classes.text}>
            <Link
              to={`/members/${memberId}/care-access/messages`}
              onClick={() => dispatch(setCurrentStep({ step: "messages" }))}
              data-testid="message-care-team"
            >
              Message your Care Team
            </Link>
            <span>{`, or call them at ${clinicContact} to get assistance.`}</span>
          </Typography>
          <Typography appearance="body" className={classes.text}>
            If this is an emergency call 911.
          </Typography>
        </>
      )}
    </div>
  );
};

export default PickerEmptyState;
