// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CtmPodAssignment,
    CtmPodAssignmentFromJSON,
    CtmPodAssignmentToJSON,
} from './CtmPodAssignment';


/**
 * 
 * @export
 * @interface CtmPodAssignmentResponse
 */
export interface CtmPodAssignmentResponse  {
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignmentResponse
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CtmPodAssignmentResponse
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof CtmPodAssignmentResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof CtmPodAssignmentResponse
     */
    last: number;
    /**
     * 
     * @type {Array<CtmPodAssignment>}
     * @memberof CtmPodAssignmentResponse
     */
    results?: Array<CtmPodAssignment>;
}

export function CtmPodAssignmentResponseFromJSON(json: any): CtmPodAssignmentResponse {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': json['page'],
        'last': json['last'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(CtmPodAssignmentFromJSON),
    };
}

export function CtmPodAssignmentResponseToJSON(value?: CtmPodAssignmentResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(CtmPodAssignmentToJSON),
    };
}


