import React from "react";
import { useMediaQuery, useTheme, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "110vw",
      [theme.breakpoints.down("lg")]: {
        width: "90vw",
      },
    },
  })
);

const DatePickerSkeleton: React.FunctionComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileAndIpadScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className={classes.root}>
      {isMobileAndIpadScreen ? (
        <>
          <Grid container>
            <Skeleton
              height="15px"
              width="70%"
              style={{ margin: "30px 10px 0px 10px" }}
            />
            <Skeleton height="15px" width="60%" style={{ margin: "10px" }} />
          </Grid>
          <Grid container>
            <Skeleton height="90px" width="90px" style={{ margin: "10px" }} />
            <Skeleton height="90px" width="90px" style={{ margin: "10px" }} />
            <Skeleton height="90px" width="90px" style={{ margin: "10px" }} />
          </Grid>
          <Grid container>
            <Skeleton
              height="15px"
              width="70%"
              style={{ margin: "30px 10px 0px 10px" }}
            />
            <Skeleton height="15px" width="60%" style={{ margin: "10px" }} />
          </Grid>
          <Grid container>
            <Skeleton height="90px" width="90px" style={{ margin: "10px" }} />
            <Skeleton height="90px" width="90px" style={{ margin: "10px" }} />
            <Skeleton height="90px" width="90px" style={{ margin: "10px" }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item>
              <Skeleton
                height="15px"
                width="250px"
                style={{ margin: "30px 10px 10px 10px" }}
              />
              <Skeleton
                height="15px"
                width="200px"
                style={{ margin: "10px" }}
              />
            </Grid>

            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
          </Grid>
          <Grid container>
            <Grid item>
              <Skeleton
                height="15px"
                width="250px"
                style={{ margin: "30px 10px 10px 10px" }}
              />
              <Skeleton
                height="15px"
                width="200px"
                style={{ margin: "10px" }}
              />
            </Grid>

            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
          </Grid>

          <Grid container>
            <Grid item>
              <Skeleton
                height="15px"
                width="250px"
                style={{ margin: "30px 10px 10px 10px" }}
              />
              <Skeleton
                height="15px"
                width="200px"
                style={{ margin: "10px" }}
              />
            </Grid>

            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
          </Grid>

          <Grid container>
            <Grid item>
              <Skeleton
                height="15px"
                width="250px"
                style={{ margin: "30px 10px 10px 10px" }}
              />
              <Skeleton
                height="15px"
                width="200px"
                style={{ margin: "10px" }}
              />
            </Grid>

            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
            <Skeleton height="100px" width="100px" style={{ margin: "10px" }} />
          </Grid>
        </>
      )}
    </div>
  );
};

export default DatePickerSkeleton;
