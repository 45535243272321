import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "components/TextField";
import Autocomplete from "components/Autocomplete";

const useStyles = makeStyles(({ palette }) => ({
  autoComplete: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.text.tertiary}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.secondary.main}`,
    },
    "& .Mui-focused": {
      "& .MuiSvgIcon-root": {
        color: palette.secondary.main,
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: "8px",
      paddingTop: (props: any) => `${props.padding} !important`,
      paddingBottom: (props: any) => `${props.padding} !important`,
      backgroundColor: (props: any) =>
        props.isPrimary ? palette.bluePastelAlt : palette.white,
      "& .MuiOutlinedInput-notchedOutline": {
        border: (props: any) =>
          props.isPrimary
            ? `1px solid ${palette?.appBackground?.blue}`
            : `1px solid ${palette?.text?.mediumDarkGray}`,
      },
      "&:hover": {
        backgroundColor: (props: any) =>
          props.isPrimary
            ? palette.bluePastelHover
            : palette?.appBackground?.lightestGray,
      },
      "& .MuiInputBase-input": {
        height: (props: any) =>
          `${props.isMobileScreen ? "2em !important" : ""}`,
        paddingTop: (props: any) =>
          `${props.isMobileScreen ? "0px" : ""} !important`,
        paddingBottom: (props: any) =>
          `${props.isMobileScreen ? "0px" : ""} !important`,
      },
    },
  },
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "&[aria-selected='true']": {
          backgroundColor: palette?.appBackground?.blue,
          color: palette.white,
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
      },
    },
  },
}));

interface IOption {
  code: string;
  label: string;
}
interface ILocationComboBox {
  options: IOption[];
  selectedValue: IOption;
  onChange: (value: IOption) => void;
  customWidth?: string;
  isMobileScreen?: boolean;
  isPrimary?: boolean;
  padding?: string;
}

const LocationComboBox: FunctionComponent<ILocationComboBox> = ({
  options,
  selectedValue,
  onChange,
  customWidth,
  isMobileScreen = false,
  isPrimary = false,
  padding,
}) => {
  const classes = useStyles({ padding, isMobileScreen, isPrimary });

  const getOptionSelected = (option: any, value: any) => {
    if (!value) {
      return false;
    } else {
      return value.code === option.code;
    }
  };

  return (
    <>
      <Autocomplete
        id="current-location-states"
        disableClearable
        autoHighlight
        className={classes.autoComplete}
        options={options}
        value={!!selectedValue?.code ? selectedValue : null}
        getOptionLabel={(option) => option.label || ""}
        isOptionEqualToValue={getOptionSelected}
        size="small"
        ListboxProps={{
          className: classes.listboxItem,
        }}
        sx={!!customWidth ? { width: customWidth } : { width: "95%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            aria-label="Enter current location"
          />
        )}
        onChange={(_e: any, value: any) => {
          onChange(value);
        }}
      />
    </>
  );
};

export default LocationComboBox;
