import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { setTimelineDestinationUrl } from "shared/state/ui/member";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import { JsonUserAffinityEnum } from "shared/fetch/src/models/JsonUser";
import { selectLoggedInUser } from "shared/features/user/selectors";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import AddIcon from "@mui/icons-material/Add";
import TodayIcon from "@mui/icons-material/Today";
import EmailIcon from "@mui/icons-material/Email";
import {
  ButtonBase,
  Drawer,
  Hidden,
  SvgIcon,
  SvgIconProps,
} from "@mui/material";
import useNavBarRoutes, { NavRoute } from "./navigationBarRoutes";
import DraftReplySafeLink from "components/Button/DraftReplySafeLink";
import Button from "components/Button";
import Typography from "components/Typography";
import Divider from "components/Divider";
import { eocRootPath } from "containers/Routing";
import useAccess from "hooks/useAccess";
import compact from "lodash/compact";
import CurrentLocationModal from "features/careAccess/components/CurrentLocationModal";
import UserModel from "models/User";

interface GetCareOption {
  name: string;
  pathname: string;
  e2e: string;
  icon: JSX.Element;
}

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexGrow: 1,
      position: "fixed",
      bottom: 0,
      width: "100%",
      zIndex: 2,
      boxShadow: `0px -4px 6px ${theme.palette.boxShadowLightGray}`,
    },
    gridAlignmentContainer: {
      display: "grid",
      padding: (props: any) =>
        props.isIneligibleAndHasCareAccess ? "0px 8px 10px 8px" : "0px 8px",
      gridTemplateColumns: (props: any) =>
        props.isIneligibleAndHasCareAccess
          ? "repeat(4, 1fr)"
          : "repeat(5, 1fr)",
      gridTemplateRows: "1fr",
      gridGap: 0,
      gridTemplateAreas: (props: any) =>
        props.isIneligibleAndHasCareAccess
          ? `"item1 item2 item4 item5"`
          : `"item1 item2 item3 item4 item5"`,
      justifyContent: "center",
    },
    flexibleAlignmentContainer: {
      display: "flex",
      justifyContent: "space-evenly",
      padding: "0px 16px",
    },
    navBarIcon: {
      fill: theme.palette.text.mediumDarkGrayHover,
      paddingBottom: theme.spacing(0.5),
    },
    selectedNavBarIcon: {
      fill: theme.palette.yellow,
      paddingBottom: theme.spacing(0.5),
    },
    label: {
      fontSize: theme.typography?.miniCaption?.fontSize,
      lineHeight: "0.75rem",
      textAlign: "center",
    },
    labelColor: {
      color: theme.palette.text.secondary,
    },
    selectedLabelColor: {
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
    gridAlignmentNavBarOption: {
      padding: "16px 6px 8px 6px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    buttonLayout1: {
      "& :nth-child(1)": { gridArea: "item1" },
    },
    buttonLayout2: {
      "& :nth-child(1)": { gridArea: "item2" },
      "& :nth-child(2)": { gridArea: "item4" },
    },
    buttonLayout3: {
      "& :nth-child(1)": { gridArea: "item1" },
      "& :nth-child(2)": { gridArea: "item2" },
      "& :nth-child(3)": { gridArea: "item4" },
    },
    buttonLayout4: {
      "& :nth-child(1)": { gridArea: "item1" },
      "& :nth-child(2)": { gridArea: "item2" },
      "& :nth-child(3)": { gridArea: "item4" },
      "& :nth-child(4)": { gridArea: "item5" },
    },
    flexibleAlignmentNavBarOption: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
    },
    buttonAndLabel: {
      display: "block",
      gridArea: "item3",
      justifySelf: "center",
      position: "relative",
      top: -24,
    },
    buttonContainer: {
      backgroundColor: "white",
      borderRadius: "50%",
      width: 64,
      height: 64,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: `0px -4px 6px ${theme.palette.boxShadowLightGray}`,
    },
    hideBoxShadow: {
      position: "absolute",
      backgroundColor: "white",
      width: 68,
      height: 32,
      top: 24,
    },
    getCareButton: {
      position: "absolute",
      borderRadius: "50%",
      width: 54,
      height: 54,
      backgroundColor: theme.palette.yellow,
      minWidth: 48,
      "&:hover": {
        backgroundColor: theme.palette.yellow,
      },
      zIndex: 3,
    },
    addIcon: {
      fill: `${theme.palette.text.primary}`,
      justifySelf: "flex-end",
    },
    drawerPaper: {
      borderRadius: "10px 10px 0px 0px",
    },
    drawerMenu: {
      margin: `${theme.spacing(3.5)} ${theme.spacing(4)} 0px`,
    },
    drawerMenuItem: {
      display: "flex",
      paddingBottom: theme.spacing(4),
      justifyContent: "flex-start",
    },
    drawerIcon: {
      width: 30,
      fill: theme.palette.yellow,
      justifySelf: "center",
    },
    drawerLabel: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
      fontSize: theme.typography?.bodySmall?.fontSize,
      alignSelf: "center",
    },
    mobileNav: {
      backgroundColor: "White",
      zIndex: 2,
    },
    titleContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexGrow: 1,
      paddingTop: 16,
      paddingBottom: 16,
    },
    title: {
      textAlign: "center",
      alignSelf: "center",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      fontSize: theme?.typography?.body?.fontSize,
      fontWeight: "bold",
      color: theme?.palette?.text?.primary,
    },
    closeButton: {
      paddingLeft: 16,
      fontSize: theme?.typography?.body?.fontSize,
      textDecoration: "underline",
      "& .MuiTypography-colorPrimary": {
        color: theme?.palette?.secondary?.main,
      },
    },
    divider: {
      width: "100%",
    },
  })
);

const MedicalServicesIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM10 4h4v2h-4V4zm6 11h-3v3h-2v-3H8v-2h3v-3h2v3h3v2z"></path>
  </SvgIcon>
);

const getDrawerMenuOptions = (user: User) => {
  const classes = useStyles();
  const features = selectFeatures();

  const START_CONVERSATION_PERMISSION = PermissionsEnum.EpisodesCreate;
  const SELF_SCHEDULE_PERMISSION = PermissionsEnum.SelfScheduleCreate;
  const hasSelfScheduleAccess = useAccess({ to: SELF_SCHEDULE_PERMISSION });
  const hasSelfSchedule = features.hasSelfSchedule() && hasSelfScheduleAccess;

  const hasStartConversation = useAccess({ to: START_CONVERSATION_PERMISSION });

  const hasDirectMessage =
    features.hasDirectMessaging() && hasStartConversation;

  const hasAffinity = features.hasAffinity();

  const hasCareAccessMenus = features.hasCareAccessMenus();

  const selfScheduleMenuItem = hasSelfSchedule && {
    name: "Schedule a visit",
    pathname: `/members/${user?.id}/self-schedule`,
    e2e: "schedule-visit-link",
    icon: <TodayIcon className={classes.drawerIcon} />,
  };

  const hasSelfScheduleCreatePermission = useAccess({
    to: PermissionsEnum.SelfScheduleCreate,
  });
  const hasEpisodesCreatePermission = useAccess({
    to: PermissionsEnum.EpisodesCreate,
  });

  const hasSelfScheduleAccessOnly =
    hasSelfScheduleCreatePermission && !hasEpisodesCreatePermission;

  const affinitySelfScheduleMenuItem =
    (hasSelfScheduleAccessOnly ||
      [
        JsonUserAffinityEnum.Onsite,
        JsonUserAffinityEnum.Nearsite,
        JsonUserAffinityEnum.InpersonWithoutTravel,
        // @ts-ignore: Object is possibly 'null'.
      ].includes(user?.affinity)) &&
    selfScheduleMenuItem;

  const startAConversationMenuItem = hasStartConversation && {
    name: "Start a conversation",
    pathname: eocRootPath,
    e2e: "start-care-now-link",
    icon: <MedicalServicesIcon className={classes.drawerIcon} />,
  };

  const startCareNowMenuItem = [
    JsonUserAffinityEnum.Remote,
    JsonUserAffinityEnum.Nearsite,
    // @ts-ignore: Object is possibly 'null'.
  ].includes(user.affinity) &&
    !isDependantAccess() && {
      name: "Start Care Now",
      pathname: eocRootPath,
      e2e: "start-care-now-link",
      icon: <MedicalServicesIcon className={classes.drawerIcon} />,
    };

  const directMessageMenuItem = hasDirectMessage && {
    name: "Send a Direct Message",
    pathname: "/direct-messaging",
    e2e: "direct-message-link",
    icon: <EmailIcon className={classes.drawerIcon} />,
  };

  const careAccessMenuItem = user?.minor
    ? {
        name: "Schedule a visit",
        pathname: `/members/${user?.id}/care-access/services`,
        e2e: "care-access-schedule-link",
        icon: <TodayIcon className={classes.drawerIcon} />,
      }
    : {
        name: "Get Care Now",
        pathname: `/members/${user?.id}/care-access/services`,
        e2e: "care-access-get-care-link",
        icon: <MedicalServicesIcon className={classes.drawerIcon} />,
      };

  const affinityButtonOrder = [
    startCareNowMenuItem,
    affinitySelfScheduleMenuItem,
    directMessageMenuItem,
  ];

  const noCareAccessDisplayButtons = hasAffinity
    ? compact(affinityButtonOrder)
    : compact([startAConversationMenuItem, selfScheduleMenuItem]);

  return hasCareAccessMenus && !UserModel.isUserIneligible(user)
    ? [careAccessMenuItem]
    : noCareAccessDisplayButtons;
};

const GetCareDrawer = (props: any) => {
  const { options: userGetCareOptions, open, onClose } = props;
  const classes = useStyles();

  return (
    <Drawer
      data-e2e="get-care-drawer"
      data-testid="get-care-mobile-drawer"
      anchor="bottom"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      role="dialog"
      aria-modal="true"
      aria-labelledby="get-care-modal-title"
    >
      <div className={classes.titleContainer}>
        <div className={classes.closeButton}>
          <ButtonBase role="button" onClick={onClose}>
            Close
          </ButtonBase>
        </div>
        <div id="get-care-modal-title" className={classes.title}>
          Get Care
        </div>
      </div>
      <div className={classes.divider}>
        <Divider />
      </div>
      <div className={classes.drawerMenu}>
        {userGetCareOptions?.map((option: GetCareOption, index: number) => (
          <ButtonBase
            className={classes.drawerMenuItem}
            data-e2e={option.e2e}
            to={option.pathname}
            component={DraftReplySafeLink}
            role="link"
            key={index}
            onClick={(e: any) => {
              e.preventDefault();
              onClose();
            }}
          >
            {option.icon}
            <Typography className={classes.drawerLabel}>
              {option.name}
            </Typography>
          </ButtonBase>
        ))}
      </div>
    </Drawer>
  );
};

const GetCareButton = (props: any) => {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <div data-e2e="get-care-button" className={classes.buttonAndLabel}>
      <div className={classes.buttonContainer}>
        <Button
          aria-labelledby="get-care"
          className={classes.getCareButton}
          onClick={onClick}
        >
          <AddIcon
            className={classes.addIcon}
            style={{
              width: 42,
              height: 42,
            }}
          />
        </Button>
        <div className={classes.hideBoxShadow}></div>
      </div>
      <Typography
        id="get-care"
        className={`${classes.label} ${classes.labelColor}`}
      >
        Get Care
      </Typography>
    </div>
  );
};

const MobileNavigationTabs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user: User = selectLoggedInUser();
  const features = selectFeatures();
  const userNav = useNavBarRoutes(user);
  const [isCurrentLocationModalOpen, setCurrentLocationModalOpen] =
    useState(false);
  const isCurrentPathInParentPages =
    userNav.filter((route) => {
      const re = new RegExp(`/^${route.pathname}/.+/`);
      return (
        location.pathname === route.pathname || location.pathname?.match(re)
      );
    })?.length > 0;
  const pathname = location.pathname.includes("/tools")
    ? "/tools"
    : location.pathname;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const { accountInfo } =
    useGetAccountInfo({
      // @ts-ignore: Object is possibly 'null'.
      id: user?.id?.toString(),
    }) || {};

  const hasCareAccessMenus = features.hasCareAccessMenus();
  const handleCareAccessNavigation = (accountState: string | undefined) => {
    if (!accountState) {
      setCurrentLocationModalOpen(true);
    } else {
      navigate(`/members/${user?.id}/care-access/services`);
    }
  };

  const toggleModal = () => {
    setCurrentLocationModalOpen(false);
  };

  const onClickHandler = (toUrl: string) => {
    dispatch(setTimelineDestinationUrl(toUrl));
  };
  const userGetCareOptions: GetCareOption[] = getDrawerMenuOptions(user);
  const isFlexibleAlignment = user?.ctm || !isCurrentPathInParentPages;

  const getButtonLayoutClass = (navOptions: NavRoute[]) => {
    switch (navOptions?.length) {
      case 1:
        return "buttonLayout1";
      case 2:
        return "buttonLayout2";
      case 3:
        return "buttonLayout3";
      case 4:
        return "buttonLayout4";
      default:
        return null;
    }
  };
  const buttonLayout = getButtonLayoutClass(userNav);
  const isIneligible = UserModel.isUserIneligible(accountInfo);

  const classes = useStyles({
    isIneligibleAndHasCareAccess: isIneligible && hasCareAccessMenus,
  });

  return (
    <div className={classes.container}>
      <Hidden only={["sm", "md", "lg", "xl"]}>
        <GetCareDrawer
          options={userGetCareOptions}
          open={drawerOpen}
          onClose={toggleDrawer}
        />
      </Hidden>
      <div data-e2e="navigation-tabs-bottom" className={classes.mobileNav}>
        <div
          className={clsx(
            isFlexibleAlignment || user?.ctm
              ? classes.flexibleAlignmentContainer
              : classes.gridAlignmentContainer,
            !isFlexibleAlignment && buttonLayout && classes[buttonLayout]
          )}
        >
          {userNav.map((route: NavRoute, index: number) => {
            return (
              <ButtonBase
                tabIndex={0}
                key={index}
                data-e2e={route.e2e}
                to={route.pathname}
                onClick={(e: any) => {
                  e.preventDefault();
                  onClickHandler(route?.pathname);
                }}
                component={DraftReplySafeLink}
                className={`${
                  isFlexibleAlignment
                    ? classes.flexibleAlignmentNavBarOption
                    : classes.gridAlignmentNavBarOption
                }`}
              >
                {/* @ts-ignore: Object is possibly 'null'. */}
                {React.cloneElement(route.icon, {
                  className: `${
                    pathname === route.pathname
                      ? classes.selectedNavBarIcon
                      : classes.navBarIcon
                  }`,
                })}
                <Typography
                  className={`${classes.label} ${
                    pathname === route.pathname
                      ? classes.selectedLabelColor
                      : classes.labelColor
                  }`}
                >
                  {route.name}
                </Typography>
              </ButtonBase>
            );
          })}
          {!isFlexibleAlignment && !hasCareAccessMenus && (
            <GetCareButton onClick={toggleDrawer} />
          )}
          {!isFlexibleAlignment && hasCareAccessMenus && !isIneligible && (
            <GetCareButton
              onClick={() => {
                handleCareAccessNavigation(accountInfo?.state);
              }}
            />
          )}
          <CurrentLocationModal
            openModal={isCurrentLocationModalOpen}
            toggle={toggleModal}
          />
        </div>
      </div>
    </div>
  );
};

export default MobileNavigationTabs;
