/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteDocumentRequestBody,
    DeleteDocumentRequestBodyFromJSON,
    DeleteDocumentRequestBodyToJSON,
} from '../models/DeleteDocumentRequestBody'
import {
    DocumentObjectDetails,
    DocumentObjectDetailsFromJSON,
    DocumentObjectDetailsToJSON,
} from '../models/DocumentObjectDetails'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UpdateDocumentRequestBody,
    UpdateDocumentRequestBodyFromJSON,
    UpdateDocumentRequestBodyToJSON,
} from '../models/UpdateDocumentRequestBody'
import {
    UserDocumentsFetchResponse,
    UserDocumentsFetchResponseFromJSON,
    UserDocumentsFetchResponseToJSON,
} from '../models/UserDocumentsFetchResponse'


export interface DeleteDocumentRequest {
    id: string;
    deleteDocumentRequestBody?: DeleteDocumentRequestBody;
}
export interface FetchDocumentRequest {
    id: string;
    thumbnail?: boolean;
}
export interface FetchUserDocumentsRequest {
    id: string;
}
export interface FetchUserPaginatedDocumentsRequest {
    id: string;
    page?: string;
}
export interface UpdateDocumentRequest {
    id: string;
    updateDocumentRequestBody?: UpdateDocumentRequestBody;
}
export interface UploadUserDocumentRequest {
    id: string;
    file: Blob;
    title?: Blob;
    docType?: string;
}


export interface IDocumentsApi {
      deleteDocument: (requestParameters: DeleteDocumentRequest) => Promise<void>
      fetchDocument: (requestParameters: FetchDocumentRequest) => Promise<void>
      fetchDocumentMimeTypes: () => Promise<Array<string>>
      fetchUserDocuments: (requestParameters: FetchUserDocumentsRequest) => Promise<Array<DocumentObjectDetails>>
      fetchUserPaginatedDocuments: (requestParameters: FetchUserPaginatedDocumentsRequest) => Promise<UserDocumentsFetchResponse>
      updateDocument: (requestParameters: UpdateDocumentRequest) => Promise<DocumentObjectDetails>
      uploadUserDocument: (requestParameters: UploadUserDocumentRequest) => Promise<DocumentObjectDetails>
      deleteDocumentWithResponse: (requestParameters: DeleteDocumentRequest) => Promise<{ value: void, response: any}>
      fetchDocumentWithResponse: (requestParameters: FetchDocumentRequest) => Promise<{ value: void, response: any}>
      fetchDocumentMimeTypesWithResponse: () => Promise<{ value: Array<string>, response: any}>
      fetchUserDocumentsWithResponse: (requestParameters: FetchUserDocumentsRequest) => Promise<{ value: Array<DocumentObjectDetails>, response: any}>
      fetchUserPaginatedDocumentsWithResponse: (requestParameters: FetchUserPaginatedDocumentsRequest) => Promise<{ value: UserDocumentsFetchResponse, response: any}>
      updateDocumentWithResponse: (requestParameters: UpdateDocumentRequest) => Promise<{ value: DocumentObjectDetails, response: any}>
      uploadUserDocumentWithResponse: (requestParameters: UploadUserDocumentRequest) => Promise<{ value: DocumentObjectDetails, response: any}>
}

/**
 * no description
 */
export class DocumentsApi extends runtime.BaseAPI {

    /**
     * Delete a document by ID
     */
    deleteDocumentRaw = async (requestParameters: DeleteDocumentRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocument.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteDocumentRequestBodyToJSON(requestParameters.deleteDocumentRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete a document by ID
     */
    deleteDocument = async (requestParameters: DeleteDocumentRequest): Promise<void> => {
        const { runtime } = await this.deleteDocumentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a document by ID
     */
    deleteDocumentWithResponse = async (requestParameters: DeleteDocumentRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteDocumentRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch a document by ID
     */
    fetchDocumentRaw = async (requestParameters: FetchDocumentRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchDocument.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.thumbnail !== undefined) {
            queryParameters['thumbnail'] = requestParameters.thumbnail;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Fetch a document by ID
     */
    fetchDocument = async (requestParameters: FetchDocumentRequest): Promise<void> => {
        const { runtime } = await this.fetchDocumentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a document by ID
     */
    fetchDocumentWithResponse = async (requestParameters: FetchDocumentRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.fetchDocumentRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch mimetypes supported for document upload
     */
    fetchDocumentMimeTypesRaw = async (): Promise<{ runtime: runtime.ApiResponse<Array<string>>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/documents-mimetypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse<any>(response), response };
    }

    /**
     * Fetch mimetypes supported for document upload
     */
    fetchDocumentMimeTypes = async (): Promise<Array<string>> => {
        const { runtime } = await this.fetchDocumentMimeTypesRaw();
        return await runtime.value();
    }

    /**
     * Fetch mimetypes supported for document upload
     */
    fetchDocumentMimeTypesWithResponse = async (): Promise<{ value: Array<string>, response: any}> => {
        const { runtime, response } = await this.fetchDocumentMimeTypesRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch documents for a user ID
     */
    fetchUserDocumentsRaw = async (requestParameters: FetchUserDocumentsRequest): Promise<{ runtime: runtime.ApiResponse<Array<DocumentObjectDetails>>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserDocuments.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentObjectDetailsFromJSON)), response };
    }

    /**
     * Fetch documents for a user ID
     */
    fetchUserDocuments = async (requestParameters: FetchUserDocumentsRequest): Promise<Array<DocumentObjectDetails>> => {
        const { runtime } = await this.fetchUserDocumentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch documents for a user ID
     */
    fetchUserDocumentsWithResponse = async (requestParameters: FetchUserDocumentsRequest): Promise<{ value: Array<DocumentObjectDetails>, response: any}> => {
        const { runtime, response } = await this.fetchUserDocumentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch paginated documents for a user ID
     */
    fetchUserPaginatedDocumentsRaw = async (requestParameters: FetchUserPaginatedDocumentsRequest): Promise<{ runtime: runtime.ApiResponse<UserDocumentsFetchResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserPaginatedDocuments.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/paginated_documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UserDocumentsFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch paginated documents for a user ID
     */
    fetchUserPaginatedDocuments = async (requestParameters: FetchUserPaginatedDocumentsRequest): Promise<UserDocumentsFetchResponse> => {
        const { runtime } = await this.fetchUserPaginatedDocumentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch paginated documents for a user ID
     */
    fetchUserPaginatedDocumentsWithResponse = async (requestParameters: FetchUserPaginatedDocumentsRequest): Promise<{ value: UserDocumentsFetchResponse, response: any}> => {
        const { runtime, response } = await this.fetchUserPaginatedDocumentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update a document by ID
     */
    updateDocumentRaw = async (requestParameters: UpdateDocumentRequest): Promise<{ runtime: runtime.ApiResponse<DocumentObjectDetails>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDocument.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDocumentRequestBodyToJSON(requestParameters.updateDocumentRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DocumentObjectDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Update a document by ID
     */
    updateDocument = async (requestParameters: UpdateDocumentRequest): Promise<DocumentObjectDetails> => {
        const { runtime } = await this.updateDocumentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update a document by ID
     */
    updateDocumentWithResponse = async (requestParameters: UpdateDocumentRequest): Promise<{ value: DocumentObjectDetails, response: any}> => {
        const { runtime, response } = await this.updateDocumentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Upload a document for a user ID
     */
    uploadUserDocumentRaw = async (requestParameters: UploadUserDocumentRequest): Promise<{ runtime: runtime.ApiResponse<DocumentObjectDetails>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadUserDocument.');
        }

                        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadUserDocument.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.docType !== undefined) {
            formParams.append('doc_type', requestParameters.docType as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DocumentObjectDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Upload a document for a user ID
     */
    uploadUserDocument = async (requestParameters: UploadUserDocumentRequest): Promise<DocumentObjectDetails> => {
        const { runtime } = await this.uploadUserDocumentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Upload a document for a user ID
     */
    uploadUserDocumentWithResponse = async (requestParameters: UploadUserDocumentRequest): Promise<{ value: DocumentObjectDetails, response: any}> => {
        const { runtime, response } = await this.uploadUserDocumentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchDocumentsApi: IDocumentsApi  = new DocumentsApi();
