// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     EpisodeOutputAllOf,
    EpisodeOutputAllOfFromJSON,
    EpisodeOutputAllOfToJSON,
} from './EpisodeOutputAllOf';
import {
     EpisodeOutputCore,
    EpisodeOutputCoreFromJSON,
    EpisodeOutputCoreToJSON,
} from './EpisodeOutputCore';
import {
     EpisodeSummaryCoreOutputMarketingMessage,
    EpisodeSummaryCoreOutputMarketingMessageFromJSON,
    EpisodeSummaryCoreOutputMarketingMessageToJSON,
} from './EpisodeSummaryCoreOutputMarketingMessage';
import {
     EpisodeSummaryCoreOutputXopPod,
    EpisodeSummaryCoreOutputXopPodFromJSON,
    EpisodeSummaryCoreOutputXopPodToJSON,
} from './EpisodeSummaryCoreOutputXopPod';
import {
     HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';
import {
     TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from './TimelineEventOutput';
/**
 * @type EpisodeOutput
 * @export
 */
export interface EpisodeOutput extends EpisodeOutputAllOf, EpisodeOutputCore {
}

export function EpisodeOutputFromJSON(json: any): EpisodeOutput {
    return {
        ...EpisodeOutputAllOfFromJSON(json),
        ...EpisodeOutputCoreFromJSON(json),
    };
}

export function EpisodeOutputToJSON(value?: EpisodeOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...EpisodeOutputAllOfToJSON(value),
        ...EpisodeOutputCoreToJSON(value),
    };
}

