// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    CommonQuestionDetailsItem,
    CommonQuestionDetailsItemFromJSON,
    CommonQuestionDetailsItemToJSON,
} from '../models/CommonQuestionDetailsItem'
import {
    CommonQuestionRequestBody,
    CommonQuestionRequestBodyFromJSON,
    CommonQuestionRequestBodyToJSON,
} from '../models/CommonQuestionRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface AddCommonQuestionRequest {
    commonQuestionRequestBody?: CommonQuestionRequestBody;
}


/**
 * creates a common question
 * creates a common question
 */
function addCommonQuestionRaw<T>(requestParameters: AddCommonQuestionRequest, requestConfig: runtime.TypedQueryConfig<T, CommonQuestionDetailsItem> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/common_questions`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CommonQuestionRequestBodyToJSON(requestParameters.commonQuestionRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CommonQuestionDetailsItemFromJSON(body), text);
    }

    return config;
}

/**
* creates a common question
* creates a common question
*/
export function addCommonQuestion<T>(requestParameters: AddCommonQuestionRequest, requestConfig?: runtime.TypedQueryConfig<T, CommonQuestionDetailsItem>): QueryConfig<T> {
    return addCommonQuestionRaw(requestParameters, requestConfig);
}

