// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleAppointmentCore
 */
export interface ScheduleAppointmentCore  {
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    patientId: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    appointmentType: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    componentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleAppointmentCore
     */
    accommodations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleAppointmentCore
     */
    documentIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleAppointmentCore
     */
    selfSchedule?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    episodeId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleAppointmentCore
     */
    initiatedByMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    screenLocation?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    memberCurrentUsState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleAppointmentCore
     */
    reschedule?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleAppointmentCore
     */
    usState?: string;
}

export function ScheduleAppointmentCoreFromJSON(json: any): ScheduleAppointmentCore {
    return {
        'patientId': json['patient_id'],
        'appointmentType': json['appointment_type'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'accommodations': !exists(json, 'accommodations') ? undefined : json['accommodations'],
        'documentIds': !exists(json, 'document_ids') ? undefined : json['document_ids'],
        'selfSchedule': !exists(json, 'self_schedule') ? undefined : json['self_schedule'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'initiatedByMember': !exists(json, 'initiated_by_member') ? undefined : json['initiated_by_member'],
        'screenLocation': !exists(json, 'screen_location') ? undefined : json['screen_location'],
        'memberCurrentUsState': !exists(json, 'member_current_us_state') ? undefined : json['member_current_us_state'],
        'reschedule': !exists(json, 'reschedule') ? undefined : json['reschedule'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'usState': !exists(json, 'us_state') ? undefined : json['us_state'],
    };
}

export function ScheduleAppointmentCoreToJSON(value?: ScheduleAppointmentCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'patient_id': value.patientId,
        'appointment_type': value.appointmentType,
        'reason': value.reason,
        'component_id': value.componentId,
        'accommodations': value.accommodations,
        'document_ids': value.documentIds,
        'self_schedule': value.selfSchedule,
        'episode_id': value.episodeId,
        'initiated_by_member': value.initiatedByMember,
        'screen_location': value.screenLocation,
        'member_current_us_state': value.memberCurrentUsState,
        'reschedule': value.reschedule,
        'cell_phone': value.cellPhone,
        'us_state': value.usState,
    };
}


