import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import { Hearing, Visibility, Language } from "@mui/icons-material";
import { State } from "@types";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import {
  setAccommodations,
  setPreferredLanguage,
  setINeedAccommodations,
} from "shared/state/ui/careAccess/actions";
import Typography from "components/Typography";
import Checkbox from "components/Checkbox";
import LanguageDropdown from "./LanguageDropdown";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";

const useStyles = makeStyles(({ palette }) => ({
  checkboxWrapper: {
    display: "flex",
    "& .MuiButtonBase-root": {
      paddingLeft: 0,
    },
    "& p": {
      alignSelf: "center",
    },
  },
  subCheckboxWrapper: {
    display: "flex",
    "& svg": {
      height: "16px",
      width: "16px",
    },
    "& p": {
      alignSelf: "center",
    },
  },
  icon: {
    fill: palette.text.secondary,
    alignSelf: "center",
    marginLeft: "5px",
  },
  description: {
    marginLeft: "34px",
  },
}));

interface IReUsableCheckbox {
  checked: boolean;
  onChange: (e: any) => void;
  title: string;
  description?: string;
  isBold?: boolean;
}

const SubCheckbox = ({
  checked,
  onChange,
  title,
  description,
}: IReUsableCheckbox) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.subCheckboxWrapper}>
        <Checkbox
          inputProps={{
            "aria-label": `${description}`,
          }}
          color="secondary"
          checked={checked}
          onChange={onChange}
        />
        <Typography fontWeightBold appearance="smallBody" color="textPrimary">
          {title}
        </Typography>
        {title === "Hearing" && <Hearing className={classes.icon} />}
        {title === "Vision" && <Visibility className={classes.icon} />}
        {title === "Language" && <Language className={classes.icon} />}
      </div>

      <Typography
        appearance="smallBody"
        color="textSecondary"
        className={classes.description}
      >
        {description}
      </Typography>
    </>
  );
};

const CareAccessAccommodations = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useOutletContext<IOutletContext>();
  const { accountInfo } = useGetAccountInfo({ id: memberId || "" });
  const { finalize } = useSelector((state: State) => state.ui.careAccess);
  const { iNeedAccommodations, accommodations, preferredLanguage } = finalize;

  useEffect(() => {
    if (!preferredLanguage && !!accountInfo?.preferredLanguage) {
      dispatch(setPreferredLanguage(accountInfo?.preferredLanguage));
    }
  }, [accountInfo?.preferredLanguage]);

  const handleReasonableAccommodations = (_e: any) => {
    dispatch(setINeedAccommodations(!iNeedAccommodations));
  };

  const handleHearing = (_e: any) => {
    dispatch(
      setAccommodations({ ...accommodations, audio: !accommodations?.audio })
    );
  };
  const handleVision = (_e: any) => {
    dispatch(
      setAccommodations({ ...accommodations, visual: !accommodations?.visual })
    );
  };
  const handleLanguage = (_e: any) => {
    dispatch(
      setAccommodations({
        ...accommodations,
        translation: !accommodations?.translation,
      })
    );
  };

  return (
    <>
      <div className={classes.checkboxWrapper}>
        <Checkbox
          inputProps={{
            "aria-label": "I need reasonable accommodations",
          }}
          color="secondary"
          checked={iNeedAccommodations}
          onChange={handleReasonableAccommodations}
        />
        <Typography appearance="smallBody" color="textSecondary">
          I need reasonable accommodations
        </Typography>
      </div>

      {iNeedAccommodations && (
        <>
          <SubCheckbox
            checked={accommodations.audio}
            onChange={handleHearing}
            title="Hearing"
            description="Please have ASL or written assistance available"
          />
          <SubCheckbox
            checked={accommodations.visual}
            onChange={handleVision}
            title="Vision"
            description="Please have assistance available with written or printed material"
          />
          <SubCheckbox
            checked={accommodations.translation}
            onChange={handleLanguage}
            title="Language"
            description="Please have an interpreter assist me with my preferred language"
          />
          {accommodations?.translation && <LanguageDropdown />}
        </>
      )}
    </>
  );
};

export default CareAccessAccommodations;
