import { CTMInfo } from "shared/fetch/src/models/CTMInfo";
import { MemberProvidersResults } from "shared/fetch/src/models/MemberProvidersResults";
import { RecipientOptionSchema } from "shared/fetch/src/models/RecipientOptionSchema";
import { XopPodOutputSchema } from "shared/fetch/src/models/XopPodOutputSchema";

export function isXopPodOutputSchema(
  x: RecipientOptionSchema
): x is XopPodOutputSchema {
  return x.type === "care_team";
}
export function isCTMInfo(x: RecipientOptionSchema): x is CTMInfo {
  return x.type === "ctm";
}

export const subString = (name: string, num = 26) =>
  name.substring(0, num) + "...";

export const trimName = (
  provider: RecipientOptionSchema,
  isDropdownOpen?: boolean
) => {
  // EOC creation and timeline and details page
  if (isDropdownOpen) {
    return provider?.name || provider?.careTeamName;
  }

  if (!!provider?.name) {
    return provider?.name?.length > 26
      ? subString(provider.name)
      : provider?.name || "";
  } else if (isXopPodOutputSchema(provider)) {
    return provider?.careTeamName?.length && provider?.careTeamName?.length > 26
      ? subString(provider?.careTeamName)
      : provider?.careTeamName || " ";
  }
  return " ";
};

export const trimRole = (provider: any, isDropdownOpen?: boolean) => {
  if (isDropdownOpen) {
    return provider?.guilds?.[0] || provider?.role;
  }
  if (
    (provider as MemberProvidersResults)?.role &&
    (provider as MemberProvidersResults)?.role?.length! > 29
  ) {
    return subString(provider?.role, 29);
  } else if (
    (provider as CTMInfo)?.guilds?.[0] &&
    (provider as CTMInfo)?.guilds?.[0]?.length! > 29
  ) {
    return subString(provider?.guilds?.[0], 29);
  } else {
    return provider?.role || provider?.guilds?.[0];
  }
};

export const trimSubtitle = (subtitle?: string, isDropdownOpen?: boolean) => {
  if (isDropdownOpen) {
    return subtitle;
  }
  if (subtitle) {
    if (subtitle.length > 29) {
      return subString(subtitle, 29);
    } else {
      return subtitle;
    }
  }
  return "";
};
