import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { getUser } from "shared/features/user";
import { setCurrentLocation } from "shared/state/ui/careAccess/actions";
import usePatchMemberAccountInfo from "shared/features/memberProfile/usePatchMemberAccountInfo";
import Button from "components/Button";
import IconButton from "components/Button/IconButton";
import Heading from "components/DynamicHeadings";
import DialogContentText from "components/Dialog/DialogContentText";
import Checkbox from "components/Checkbox";
import Typography from "components/Typography";
import Asset from "components/Asset";
import Dialog from "components/Dialog";
import LocationComboBox from "../LocationPicker/LocationComboBox";
import { USStatesList } from "features/careAccess/utils/states";
import useMobileCheck from "hooks/useMobileCheck";

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  currentLocationModal: {
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      width: "418px",
    },
  },
  container: {
    marginRight: "28px",
    marginLeft: "37px",
    [breakpoints.down("md")]: {
      marginRight: spacing(2),
      marginLeft: spacing(2),
    },
  },
  title: {
    marginBottom: "4px",
  },
  buttonsContainer: {
    marginTop: "21px",
    marginBottom: "20px",
    [breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  button: {
    marginRight: spacing(2),
    [breakpoints.down("md")]: {
      marginRight: "0px",
      marginBottom: spacing(2),
    },
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: palette.text.secondary,
  },
  modalInfo: {
    marginBottom: "14px",
  },
  residentialCheckBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "15px",
    "& span": {
      padding: 0,
      marginRight: "4px",
    },
  },
  currentLocationMap: {
    display: "flex",
    justifyContent: "center",
    marginTop: 0,
    marginBottom: 0,
  },
}));

interface ModalProps {
  openModal: boolean;
  toggle: () => void;
}

const CurrentLocationModal: React.FC<ModalProps> = ({ openModal, toggle }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const isMobile = useMobileCheck();
  const [isPreferredResidentialState, setIsPreferredResidentialState] =
    useState(false);
  const [selectedState, setSelectedState] = useState({ label: "", code: "" });
  const { updateUserInfo } = usePatchMemberAccountInfo();

  const allStatesNameCodes =
    USStatesList.map((state) => {
      return { code: state.value, label: state.text };
    }) || [];

  const handleCurrentLocationState = () => {
    dispatch(setCurrentLocation(selectedState));
    if (isPreferredResidentialState) {
      updateUserInfo({
        id: user?.id?.toString() || "",
        body: {
          state: selectedState?.code || "",
        },
      });
    }
    navigate(`/members/${user?.id}/care-access/services`);
  };

  return (
    <Dialog
      open={openModal}
      scroll="paper"
      role="dialog"
      ariaLabelledBy="current-location-modal"
      className={classes.currentLocationModal}
    >
      <IconButton
        aria-label="Enter current location dialog close button"
        data-e2e="close-btn"
        className={classes.closeButton}
        onClick={toggle}
        size="large"
      >
        <CloseIcon />
      </IconButton>

      <div
        className={classes.container}
        data-e2e="current-location-modal-content"
      >
        <div className={classes.currentLocationMap}>
          <Asset name="current-location" hiddenFromScreenReader />
        </div>

        <Heading.LevelBoundary levelOverride={4}>
          <Heading.H
            id="current-location-modal"
            color="textPrimary"
            appearance="h5"
            className={classes.title}
          >
            Where are you now?
          </Heading.H>
        </Heading.LevelBoundary>

        <DialogContentText component="div">
          <Typography appearance="body" className={classes.modalInfo}>
            Due to licensing requirements, our providers must be licensed in the
            state where you are located.
          </Typography>

          <Typography id="current-location-label" variant="body2">
            Enter current location
          </Typography>
          <LocationComboBox
            options={allStatesNameCodes}
            selectedValue={selectedState}
            onChange={setSelectedState}
            padding={isMobile ? "10px" : ""}
            isMobileScreen={isMobile}
          />

          <div className={classes.residentialCheckBox}>
            <Checkbox
              id="use-as-residential-state"
              color="primary"
              aria-label="This is my residential state"
              checked={isPreferredResidentialState}
              onChange={(e: any) => {
                setIsPreferredResidentialState(e.target.checked);
              }}
            />
            <Typography variant="body2">
              This is my residential state
            </Typography>
          </div>
        </DialogContentText>

        <div className={classes.buttonsContainer}>
          <Button
            aria-disabled={!selectedState?.code}
            disabled={!selectedState?.code}
            color="primary"
            onClick={handleCurrentLocationState}
          >
            Confirm current location
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default CurrentLocationModal;
