import React from "react";
import { makeStyles } from "@mui/styles";
import { CapacityFetchResponseResults } from "shared/api/src/models/CapacityFetchResponseResults";
import { CapacityFetchResponseSlotsPerClinic } from "shared/api/src/models/CapacityFetchResponseSlotsPerClinic";
import parseISO from "date-fns/parseISO";
import Box from "components/Box";
import Grid from "components/Grid";
import FetchMoreOnScroll from "components/FetchMoreOnScroll";
import Divider from "components/Divider";
import ProviderVisitTile from "../../components/ProviderVisitTile";
import ClinicSlotsRow from "./ClinicSlotsRow";
import theme from "styles/mui";
import { format } from "date-fns";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";

interface IProps {
  data: CapacityFetchResponseResults[];
  isFetching: boolean;
  visitType: any;
  onAppointmentSlotSelected: any;
  appointmentName: string;
  isVirtualVisit: boolean;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  parentDiv: any;
  careAccessState?: string;
}
interface IRowProps {
  capacityResult: CapacityFetchResponseResults;
  numDays: number;
  visitType: any;
  onAppointmentSlotSelected: any;
  appointmentName: string;
  isVirtualVisit: boolean;
  careAccessState?: string;
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  gridContainer: {
    [breakpoints.up("md")]: {
      marginTop: spacing(1.5),
    },
  },
  providerRow: {
    marginTop: spacing(1),
    marginBottom: spacing(1),
    height: "100%",
    [breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
  },
  providerTile: {
    height: "100%",
    [breakpoints.down("lg")]: {
      marginBottom: spacing(1.5),
    },
  },
  slotsRow: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: spacing(0.5),
    [breakpoints.down("lg")]: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
    },
    [breakpoints.up("xl")]: {
      display: "grid",
      gridTemplateColumns: "repeat(7, auto)",
    },
  },
  slotsRowVirtual: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: spacing(0.5),
    justifyContent: "flex-start",
    [breakpoints.down("lg")]: {
      display: "flex",
      flexWrap: "nowrap",
      width: "100%",
    },
  },
  clinicAndSlotsContainer: {
    marginTop: spacing(0),
    marginBottom: spacing(1),
    display: "flex",
    maxWidth: "100%",
    justifyContent: "flex-start",
    overflowX: "auto",
  },
  virtualClinicsAndSlotsContainer: {
    marginTop: spacing(0),
    marginBottom: spacing(1),
    display: "flex",
    maxWidth: "100%",
    justifyContent: "flex-end",
    overflowX: "auto",
  },
  noAvailableVirtualVisits: {
    marginTop: spacing(0),
    marginBottom: spacing(1),
    display: "flex",
    maxWidth: "100%",
    justifyContent: "flex-start",
  },
  clinicName: {
    backgroundColor: palette.appBackground?.lightestGrayHover,
    width: "fit-content",
    padding: spacing(0.5),
    color: palette.text.primary,
    borderRadius: spacing(0.75),
  },
  noAvailableSlotsContainer: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    overflowX: "scroll",
    "& button": {
      "& span": {
        "&:hover": {
          color: palette?.text?.secondary,
        },
      },
    },
  },
  calendarIcon: {
    marginRight: spacing(1),
  },
  linkText: {
    marginRight: spacing(0.5),
  },
  seeMore: {
    width: "100%",
    padding: "0px 4px 0px 4px",
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.appBackground?.blue}`,
    marginBottom: theme.spacing(2),
  },
  moreProviders: {
    width: "100%",
    padding: "32px 4px 0px 4px",
  },
}));

const CareTeamRow = ({
  capacityResult,
  visitType,
  onAppointmentSlotSelected,
  appointmentName,
  isVirtualVisit,
  careAccessState,
}: IRowProps) => {
  const classes = useStyles();
  const { provider, slotsPerClinic } = capacityResult;

  const hasMultipleClinicsWithSlots =
    slotsPerClinic?.length &&
    slotsPerClinic?.length > 1 &&
    slotsPerClinic?.some((clinics) => !!clinics.slotsCount?.length);
  const hasClinicWithAvailableSlots = slotsPerClinic?.some(
    (clinics) => !!clinics?.slotsCount?.length
  );
  const displayDateTiles =
    hasClinicWithAvailableSlots || hasMultipleClinicsWithSlots;

  const firstProviderGuild = provider.guilds ? provider.guilds[0] : "";
  const getNextAvailableSlot = (
    clinic: CapacityFetchResponseSlotsPerClinic
  ) => {
    if (clinic.nextDateWithSlots) {
      const toISO = parseISO(clinic.nextDateWithSlots || "");
      const formatted = format(toISO, "EEE MMM d") as string;
      return formatted;
    }
    return "";
  };

  return (
    <Grid container className={classes.gridContainer}>
      <Grid container className={classes.providerRow}>
        {provider ? (
          <Grid xs={12} md={4} className={classes.providerTile}>
            <ProviderVisitTile
              providerName={provider.name}
              avatarUrl={provider.avatarUrl ?? ""}
              isPcp={Boolean(provider.isPcp)}
              serviceLine={firstProviderGuild}
              step="providerDatePicker"
              providerId={provider.id}
            />
          </Grid>
        ) : null}
        <Grid xs={12} md={8}>
          {displayDateTiles
            ? slotsPerClinic?.map(
                (clinic: CapacityFetchResponseSlotsPerClinic, i: number) => (
                  <ClinicSlotsRow
                    classes={classes}
                    provider={provider}
                    key={`clinic-slots-row-${i}`}
                    visitType={visitType}
                    appointmentName={appointmentName}
                    clinicName={clinic.clinicName}
                    clinicId={clinic.clinicId}
                    nextAvailableText={getNextAvailableSlot(clinic)}
                    nextDateWithSlots={clinic.nextDateWithSlots}
                    clinicSlots={clinic.slotsCount}
                    onAppointmentSlotSelected={onAppointmentSlotSelected}
                    isVirtualAppointment={isVirtualVisit}
                    careAccessState={careAccessState}
                  />
                )
              )
            : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

const AppointmentCalendar = ({
  data,
  isFetching,
  visitType,
  onAppointmentSlotSelected,
  appointmentName,
  isVirtualVisit,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  parentDiv,
  careAccessState,
}: IProps) => {
  //should we make rows of 7 and hard code these numbers, or rely only on styling?
  const numDaysRequested = 14;
  const showDivider = data?.length > 1;
  const display = data?.length && !isFetching;
  const classes = useStyles();
  const showManualLoad = !isFetchingNextPage && hasNextPage;

  return (
    <Box>
      {display
        ? data.map((el: CapacityFetchResponseResults, i: number) => (
            <Box key={`providerWithSlots-${i}`}>
              <CareTeamRow
                capacityResult={el}
                numDays={numDaysRequested}
                visitType={visitType}
                appointmentName={appointmentName}
                onAppointmentSlotSelected={onAppointmentSlotSelected}
                isVirtualVisit={isVirtualVisit}
                careAccessState={careAccessState}
              />
              {showDivider && <Divider />}
            </Box>
          ))
        : null}
      <FetchMoreOnScroll
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        parentDiv={parentDiv}
        fetchNextPage={fetchNextPage}
        resultsLength={data?.length}
      />
      {showManualLoad && (
        <ButtonStyledAsLink
          className={classes.moreProviders}
          onClick={fetchNextPage}
        >
          View more providers
        </ButtonStyledAsLink>
      )}
    </Box>
  );
};

export default AppointmentCalendar;
