export enum StateRegion {
  WEST = "WEST",
  EAST = "EAST",
  CENTRAL = "CENTRAL",
  NORTHEAST = "NORTHEAST",
}

export interface USAState {
  value: string;
  text: string;
  region?: StateRegion;
}

// Only US states plus District of Columbia, no territories
export const USStatesList: USAState[] = [
  { value: "AK", text: "Alaska", region: StateRegion.WEST },
  { value: "AL", text: "Alabama", region: StateRegion.EAST },
  { value: "AR", text: "Arkansas", region: StateRegion.CENTRAL },
  { value: "AZ", text: "Arizona", region: StateRegion.WEST },
  { value: "CA", text: "California", region: StateRegion.WEST },
  { value: "CO", text: "Colorado", region: StateRegion.WEST },
  { value: "CT", text: "Connecticut", region: StateRegion.NORTHEAST },
  { value: "DC", text: "District of Columbia", region: StateRegion.NORTHEAST },
  { value: "DE", text: "Delaware", region: StateRegion.NORTHEAST },
  { value: "FL", text: "Florida", region: StateRegion.EAST },
  { value: "GA", text: "Georgia", region: StateRegion.EAST },
  { value: "HI", text: "Hawaii", region: StateRegion.WEST },
  { value: "IA", text: "Iowa", region: StateRegion.CENTRAL },
  { value: "ID", text: "Idaho", region: StateRegion.WEST },
  { value: "IL", text: "Illinois", region: StateRegion.CENTRAL },
  { value: "IN", text: "Indiana", region: StateRegion.EAST },
  { value: "KS", text: "Kansas", region: StateRegion.CENTRAL },
  { value: "KY", text: "Kentucky", region: StateRegion.EAST },
  { value: "LA", text: "Louisiana", region: StateRegion.CENTRAL },
  { value: "MA", text: "Massachusetts", region: StateRegion.NORTHEAST },
  { value: "MD", text: "Maryland", region: StateRegion.NORTHEAST },
  { value: "ME", text: "Maine", region: StateRegion.NORTHEAST },
  { value: "MI", text: "Michigan", region: StateRegion.EAST },
  { value: "MN", text: "Minnesota", region: StateRegion.CENTRAL },
  { value: "MO", text: "Missouri", region: StateRegion.CENTRAL },
  { value: "MS", text: "Mississippi", region: StateRegion.EAST },
  { value: "MT", text: "Montana", region: StateRegion.WEST },
  { value: "NC", text: "North Carolina", region: StateRegion.EAST },
  { value: "ND", text: "North Dakota", region: StateRegion.CENTRAL },
  { value: "NE", text: "Nebraska", region: StateRegion.CENTRAL },
  { value: "NH", text: "New Hampshire", region: StateRegion.NORTHEAST },
  { value: "NJ", text: "New Jersey", region: StateRegion.NORTHEAST },
  { value: "NM", text: "New Mexico", region: StateRegion.WEST },
  { value: "NV", text: "Nevada", region: StateRegion.WEST },
  { value: "NY", text: "New York", region: StateRegion.NORTHEAST },
  { value: "OH", text: "Ohio", region: StateRegion.EAST },
  { value: "OK", text: "Oklahoma", region: StateRegion.CENTRAL },
  { value: "OR", text: "Oregon", region: StateRegion.WEST },
  { value: "PA", text: "Pennsylvania", region: StateRegion.NORTHEAST },
  { value: "RI", text: "Rhode Island", region: StateRegion.NORTHEAST },
  { value: "SC", text: "South Carolina", region: StateRegion.EAST },
  { value: "SD", text: "South Dakota", region: StateRegion.CENTRAL },
  { value: "TN", text: "Tennessee", region: StateRegion.EAST },
  { value: "TX", text: "Texas", region: StateRegion.CENTRAL },
  { value: "UT", text: "Utah", region: StateRegion.WEST },
  { value: "VA", text: "Virginia", region: StateRegion.EAST },
  { value: "VT", text: "Vermont", region: StateRegion.NORTHEAST },
  { value: "WA", text: "Washington", region: StateRegion.WEST },
  { value: "WI", text: "Wisconsin", region: StateRegion.CENTRAL },
  { value: "WV", text: "West Virginia", region: StateRegion.EAST },
  { value: "WY", text: "Wyoming", region: StateRegion.WEST },
];
