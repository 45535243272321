// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClinicConfigSchemaModalities
 */
export interface ClinicConfigSchemaModalities  {
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchemaModalities
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicConfigSchemaModalities
     */
    appointmentTypes?: Array<string>;
}

export function ClinicConfigSchemaModalitiesFromJSON(json: any): ClinicConfigSchemaModalities {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
    };
}

export function ClinicConfigSchemaModalitiesToJSON(value?: ClinicConfigSchemaModalities): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'appointment_types': value.appointmentTypes,
    };
}


