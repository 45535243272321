import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import Typography from "components/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import {
  setCurrentStep,
  setHasSkippedDecisionsPage,
  resetMessageData,
} from "shared/state/ui/careAccess/actions";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  wrapper: {
    marginTop: "32px",
    width: "374px",
    [breakpoints.down("sm")]: {
      width: "331px",
    },
  },
  link: {
    textDecoration: "none",
    color: palette.text.link,
    marginBottom: "5px",
  },
  linkWrapper: {
    display: "flex",
    "& p": {
      alignSelf: "center",
      marginRight: "5px",
      [breakpoints.down("sm")]: {
        fontSize: "14px",
        lineHeight: "18px",
      },
    },
  },
  text: {
    [breakpoints.down("sm")]: {
      fontSize: "14px !important",
      lineHeight: "18px !important",
    },
  },
}));

interface IGeneralQuestionsProps {
  isMinor: boolean;
  memberId: string;
  clinicContact: string;
}

const GeneralQuestions: FunctionComponent<IGeneralQuestionsProps> = ({
  isMinor,
  memberId,
  clinicContact,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(setCurrentStep({ step: "messages" }));
    dispatch(resetMessageData());
    dispatch(setHasSkippedDecisionsPage(false));
  };

  return (
    <div className={classes.wrapper}>
      {!isMinor ? (
        <>
          <Link
            to={`/members/${memberId}/care-access/messages`}
            onClick={handleOnClick}
            className={classes.link}
            data-testid="care-access-general-questions-link"
          >
            <span className={classes.linkWrapper}>
              <Typography appearance="bodyEmphasized">
                General questions?
              </Typography>
              <ArrowForwardIcon />
            </span>
          </Link>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.text}
          >
            Ask anything general questions such as parking, hours, referrals,
            benefits, and more
          </Typography>
        </>
      ) : (
        <>
          <Typography
            appearance="bodyEmphasized"
            color="textSecondary"
            className={classes.text}
          >
            General questions?
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.text}
          >
            {`Please call your clinic at ${clinicContact} for general questions such
            as parking, hours, referrals, benefits, and more`}
          </Typography>
        </>
      )}
    </div>
  );
};

export default GeneralQuestions;
