import { scrollPosition$ } from "containers/Routing";
import { pluckFirst, useObservable, useSubscription } from "observable-hooks";
import { useEffect, useState } from "react";
import { filter, map } from "rxjs";
import { withLatestFrom } from "rxjs";

export default ({
  fetchNextPage,
  hasNextPage,
  resultsLength,
  parentDiv,
  nextFetchScrollHeight = 350,
}: {
  fetchNextPage: any;
  hasNextPage: any;
  resultsLength: number | undefined;
  parentDiv: any;
  nextFetchScrollHeight?: number;
}) => {
  const [bottom, setBottom] = useState<number>();
  const [allowFetch, setAllowFetch] = useState(false);

  const bottom$ = useObservable(pluckFirst, [bottom]);
  const allowFetch$ = useObservable(pluckFirst, [allowFetch]);
  const fetchNextPage$ = useObservable(pluckFirst, [fetchNextPage]);

  const bottomScrolled$ = useObservable(() =>
    scrollPosition$.pipe(
      withLatestFrom(bottom$),
      map(
        ([scrollPosition, currentBottom]: [
          scrollPosition: number,
          currentBottom: number
        ]) => {
          return currentBottom - scrollPosition < nextFetchScrollHeight;
        }
      ),
      withLatestFrom(allowFetch$),
      map(
        ([scrolled, allowFetching]: [
          scrolled: boolean,
          allowFetching: boolean
        ]) => scrolled && allowFetching
      ),
      filter((v: boolean) => v),
      withLatestFrom(fetchNextPage$)
    )
  );

  useSubscription(bottomScrolled$, ([shouldFetch, fetchNext]: any) => {
    if (shouldFetch) {
      setAllowFetch(false);
      fetchNext();
    }
  });

  useEffect(() => {
    const rect = parentDiv?.current?.getBoundingClientRect();

    setBottom(rect?.bottom);
    if (
      hasNextPage &&
      rect?.bottom &&
      rect?.bottom < window.innerHeight + nextFetchScrollHeight
    ) {
      fetchNextPage(true);
    } else {
      setAllowFetch(true);
    }
  }, [resultsLength, fetchNextPage]);
};
