import React from "react";
import Typography from "components/Typography";
import Grid from "components/Grid";
import NoActiveEpisode from "components/NoActiveEpisode";
import {
  LandingPageHealthRecordsHotLinks,
  LandingPagePendingPaymentsBalance,
  LandingPageTertiaryCTA,
} from "pages/LandingPage/Common";
import InsuranceOnboardingTool from "../../components/InsuranceOnboardingTool";
import InsuranceCTALandingPage from "./InsuranceCTALandingPage";
import AfterHoursWidget from "components/AfterHoursWidget";
import { useStyles } from "pages/LandingPage/styles";

interface INoActiveConversationProps {
  title: string;
  message: string;
  hasArchivedConversations: boolean;
  assetName?: string;
}

const LandingPageEmptyComponent = ({
  title,
  hasArchivedConversations,
  message,
  assetName,
}: INoActiveConversationProps) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.episodeContainer}>
        <Grid item lg={8} md={12}>
          <div className={classes.mobileView}>
            <AfterHoursWidget />
            <div className={classes.healthRecordsLink}>
              <LandingPageHealthRecordsHotLinks />
            </div>
          </div>
          <InsuranceOnboardingTool />
          <Typography
            appearance="smallBody"
            className={classes.emptyComponentTitle}
          >
            {title}
          </Typography>
          <NoActiveEpisode
            message={message}
            hasArchivedConversations={hasArchivedConversations}
            assetName={assetName}
          />
        </Grid>
        <Grid item lg={4} md={12}>
          <div className={classes.desktopView}>
            <AfterHoursWidget />
            <LandingPageHealthRecordsHotLinks />
          </div>
          <LandingPageTertiaryCTA />
          <LandingPagePendingPaymentsBalance />
          <InsuranceCTALandingPage />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LandingPageEmptyComponent;
