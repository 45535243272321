// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListPreventiveMarketingMessagesResponse,
    ListPreventiveMarketingMessagesResponseFromJSON,
    ListPreventiveMarketingMessagesResponseToJSON,
} from '../models/ListPreventiveMarketingMessagesResponse'
import {
    MarketingMessageResponse,
    MarketingMessageResponseFromJSON,
    MarketingMessageResponseToJSON,
} from '../models/MarketingMessageResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMarketingMessageRequest {
    id: string;
}


/**
 * Fetch marketing message for the given ID
 * Fetch marketing message for the given ID
 */
function fetchMarketingMessageRaw<T>(requestParameters: FetchMarketingMessageRequest, requestConfig: runtime.TypedQueryConfig<T, MarketingMessageResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMarketingMessage.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/marketing_messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MarketingMessageResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch marketing message for the given ID
* Fetch marketing message for the given ID
*/
export function fetchMarketingMessage<T>(requestParameters: FetchMarketingMessageRequest, requestConfig?: runtime.TypedQueryConfig<T, MarketingMessageResponse>): QueryConfig<T> {
    return fetchMarketingMessageRaw(requestParameters, requestConfig);
}

/**
 * Fetch Marketing Messages associated with preventive health details
 * Fetch Marketing Messages associated with preventive health details
 */
function listPreventiveMarketingMessagesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, ListPreventiveMarketingMessagesResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["marketing_messages.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/preventive_health_details`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ListPreventiveMarketingMessagesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch Marketing Messages associated with preventive health details
* Fetch Marketing Messages associated with preventive health details
*/
export function listPreventiveMarketingMessages<T>( requestConfig?: runtime.TypedQueryConfig<T, ListPreventiveMarketingMessagesResponse>): QueryConfig<T> {
    return listPreventiveMarketingMessagesRaw( requestConfig);
}

