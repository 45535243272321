// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClinicConfigSchema,
    ClinicConfigSchemaFromJSON,
    ClinicConfigSchemaToJSON,
} from './ClinicConfigSchema';


/**
 * 
 * @export
 * @interface ClinicConfigFetchResponseBase
 */
export interface ClinicConfigFetchResponseBase  {
    /**
     * 
     * @type {Array<ClinicConfigSchema>}
     * @memberof ClinicConfigFetchResponseBase
     */
    results: Array<ClinicConfigSchema>;
}

export function ClinicConfigFetchResponseBaseFromJSON(json: any): ClinicConfigFetchResponseBase {
    return {
        'results': (json['results'] as Array<any>).map(ClinicConfigSchemaFromJSON),
    };
}

export function ClinicConfigFetchResponseBaseToJSON(value?: ClinicConfigFetchResponseBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(ClinicConfigSchemaToJSON),
    };
}


