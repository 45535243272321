import React from "react";
import { useMediaQuery, useTheme, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "65vw",
      [theme.breakpoints.down("lg")]: {
        width: "90vw",
      },
    },
  })
);

const CentersVisitsSkeleton: React.FunctionComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileAndIpadScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className={classes.root}>
      {isMobileAndIpadScreen ? (
        <>
          <Grid container>
            <Skeleton
              height="15px"
              width="80%"
              style={{ marginLeft: "3px", marginTop: "15px" }}
            />
          </Grid>
          <Grid container spacing={2} style={{ height: "150px" }}>
            <Skeleton
              height="200px"
              width="95%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
          </Grid>
          <Grid container spacing={2} style={{ height: "150px" }}>
            <Skeleton
              height="200px"
              width="95%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
          </Grid>
          <Grid container spacing={2} style={{ height: "150px" }}>
            <Skeleton
              height="200px"
              width="95%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid container>
            <Skeleton
              height="15px"
              width="80%"
              style={{ marginLeft: "3px", marginTop: "15px" }}
            />
          </Grid>
          <Grid container spacing={2} style={{ height: "150px" }}>
            <Skeleton
              height="200px"
              width="46%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
            <Skeleton
              height="200px"
              width="46%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
          </Grid>
          <Grid container spacing={2} style={{ height: "150px" }}>
            <Skeleton
              height="200px"
              width="46%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
            <Skeleton
              height="200px"
              width="46%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
          </Grid>
          <Grid container spacing={2} style={{ height: "150px" }}>
            <Skeleton
              height="200px"
              width="46%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
            <Skeleton
              height="200px"
              width="46%"
              style={{ margin: "10px 10px", borderRadius: "10px" }}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

export default CentersVisitsSkeleton;
