import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Info from "@mui/icons-material/Info";
import { State } from "@types";
import { setPhoneNumber } from "shared/state/ui/careAccess/actions";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import TextField from "components/TextField";
import Typography from "components/Typography";
import ButtonBase from "components/Button/ButtonBase";
import Tooltip from "components/Tooltip";
import { isValidPhoneNumber } from "utils/validate";
import formatPhoneNumber from "utils/formatPhoneNumber";

const useStyles = makeStyles(({ palette }) => ({
  phoneSection: {
    display: "flex",
    marginTop: "8px",
  },
  phoneNumber: {
    marginRight: "6px",
    "& input": {
      width: "304px",
      backgroundColor: palette.white,
    },
    "& fieldset": {
      borderRadius: "8px",
      border: `1px solid ${palette.text.tertiary} !important`,
    },
    "& .Mui-error": {
      "& input": {
        width: "260px",
      },
    },
  },
  tooltip: {
    width: 250,
    backgroundColor: palette?.appBackground?.main,
    border: "1px solid rgba(43, 57, 73, 0.15)",
    boxShadow: `2px 2px 6px ${palette.boxShadowLightGray}`,
    borderRadius: 4,
    color: palette.text.secondary,
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    fill: palette.text.secondary,
  },
}));

const CareAccessPhone = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { savedMemberId, finalize } = useSelector(
    (state: State) => state.ui.careAccess
  );
  const { accountInfo } = useGetAccountInfo({ id: savedMemberId });

  const [phone, setPhone] = useState(finalize.phone.phoneNumber);
  const [touched, setTouched] = useState(false);
  const isPhoneNumberValid = isValidPhoneNumber(phone);
  const hasError = touched && !isPhoneNumberValid;

  const handlePhoneChange = (e: any) => {
    setPhone(formatPhoneNumber(e.target.value));
  };
  const handlePhoneBlur = () => {
    setTouched(true);
  };
  useEffect(() => {
    dispatch(
      setPhoneNumber({ phoneNumber: phone, isValid: isPhoneNumberValid })
    );
  }, [phone]);

  useEffect(() => {
    if (!finalize.phone.phoneNumber && !!accountInfo?.cellPhone) {
      setPhone(accountInfo?.cellPhone);
    }
  }, [accountInfo?.cellPhone]);

  const phoneNumberTooltip =
    "Your phone number will never be sold to third parties. We will use this only for Crossover communications.";
  return (
    <>
      <Typography appearance="body" color="textSecondary">
        Confirm your phone number
      </Typography>
      <div className={classes.phoneSection}>
        <TextField
          id="mobile-phone-number"
          label="Mobile phone number"
          margin="dense"
          variant="outlined"
          className={classes.phoneNumber}
          value={phone}
          error={hasError}
          helperText={hasError ? "Please enter a valid phone number" : ""}
          onChange={handlePhoneChange}
          onBlur={handlePhoneBlur}
        />
        <Tooltip
          title={phoneNumberTooltip}
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <ButtonBase>
            <Info aria-label="More info" className={classes.infoIcon} />
          </ButtonBase>
        </Tooltip>
      </div>
    </>
  );
};

export default CareAccessPhone;
