import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { State } from "@types";
import { setTextReminder } from "shared/state/ui/careAccess/actions";
import Checkbox from "components/Checkbox";
import Typography from "components/Typography";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    "& .MuiButtonBase-root": {
      paddingLeft: 0,
    },
    "& p": {
      alignSelf: "center",
    },
  },
}));

const CareAccessSMSCheck = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { finalize } = useSelector((state: State) => state.ui.careAccess);

  const [stateTextReminder, setStateTextReminder] = useState(
    finalize.textReminder
  );
  const handleTextReminder = (_e: any) => {
    setStateTextReminder(!stateTextReminder);
  };

  useEffect(() => {
    dispatch(setTextReminder(stateTextReminder));
  }, [stateTextReminder]);

  return (
    <div className={classes.wrapper}>
      <Checkbox
        inputProps={{
          "aria-label": "Send SMS for text notifications",
        }}
        color="secondary"
        checked={stateTextReminder}
        onChange={handleTextReminder}
      />
      <Typography appearance="smallBody" color="textSecondary">
        Send SMS for text notifications
      </Typography>
    </div>
  );
};

export default CareAccessSMSCheck;
