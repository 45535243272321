import React from "react";
import { useOutletContext } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "components/Typography";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  footer: {
    width: "100%",
    position: "fixed",
    backgroundColor: palette.white,
    bottom: 0,
    padding: "15px 0px",
  },
  text: {
    color: palette.text.primary,
    width: "493px",
    [breakpoints.down("sm")]: {
      width: "325px",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { clinicContact } = useOutletContext<IOutletContext>();
  return (
    <div className={classes.footer}>
      <Typography appearance="caption" className={classes.text}>
        {`*Options may vary depending on access to services, location and
        eligibility. If you have any questions about your availability please
        call ${clinicContact} for assistance.`}
      </Typography>
    </div>
  );
};

export default Footer;
