// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CtmPodAssignmentFiltersResponseResultsPods,
    CtmPodAssignmentFiltersResponseResultsPodsFromJSON,
    CtmPodAssignmentFiltersResponseResultsPodsToJSON,
} from './CtmPodAssignmentFiltersResponseResultsPods';
import {
    CtmPodAssignmentFiltersResponseResultsProviderNames,
    CtmPodAssignmentFiltersResponseResultsProviderNamesFromJSON,
    CtmPodAssignmentFiltersResponseResultsProviderNamesToJSON,
} from './CtmPodAssignmentFiltersResponseResultsProviderNames';


/**
 * 
 * @export
 * @interface CtmPodAssignmentFiltersResponseResults
 */
export interface CtmPodAssignmentFiltersResponseResults  {
    /**
     * 
     * @type {Array<CtmPodAssignmentFiltersResponseResultsPods>}
     * @memberof CtmPodAssignmentFiltersResponseResults
     */
    pods: Array<CtmPodAssignmentFiltersResponseResultsPods>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmPodAssignmentFiltersResponseResults
     */
    clinicIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmPodAssignmentFiltersResponseResults
     */
    providerEmails: Array<string>;
    /**
     * 
     * @type {Array<CtmPodAssignmentFiltersResponseResultsProviderNames>}
     * @memberof CtmPodAssignmentFiltersResponseResults
     */
    providerNames: Array<CtmPodAssignmentFiltersResponseResultsProviderNames>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmPodAssignmentFiltersResponseResults
     */
    clinicalRoles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmPodAssignmentFiltersResponseResults
     */
    isPublic: Array<string>;
}

export function CtmPodAssignmentFiltersResponseResultsFromJSON(json: any): CtmPodAssignmentFiltersResponseResults {
    return {
        'pods': (json['pods'] as Array<any>).map(CtmPodAssignmentFiltersResponseResultsPodsFromJSON),
        'clinicIds': json['clinic_ids'],
        'providerEmails': json['provider_emails'],
        'providerNames': (json['provider_names'] as Array<any>).map(CtmPodAssignmentFiltersResponseResultsProviderNamesFromJSON),
        'clinicalRoles': json['clinical_roles'],
        'isPublic': json['is_public'],
    };
}

export function CtmPodAssignmentFiltersResponseResultsToJSON(value?: CtmPodAssignmentFiltersResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pods': (value.pods as Array<any>).map(CtmPodAssignmentFiltersResponseResultsPodsToJSON),
        'clinic_ids': value.clinicIds,
        'provider_emails': value.providerEmails,
        'provider_names': (value.providerNames as Array<any>).map(CtmPodAssignmentFiltersResponseResultsProviderNamesToJSON),
        'clinical_roles': value.clinicalRoles,
        'is_public': value.isPublic,
    };
}


