import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { State } from "@types";
import { setLateCancelationCheck } from "shared/state/ui/careAccess/actions";
import AttachmentViewerModal, {
  AttachmentViewerModeEnum,
} from "components/AttachmentViewerModal";
import { Consent } from "shared/fetch/src/models/Consent";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import Typography from "components/Typography";

interface ICareAccessConsentCheck {
  consent: Consent;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    "& .MuiButtonBase-root": {
      paddingLeft: 0,
    },
    "& p": {
      alignSelf: "center",
    },
  },
  button: {
    fontSize: "13.3px",
  },
}));

const CareAccessConsentCheck = ({ consent }: ICareAccessConsentCheck) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showConsentModal, setShowConsentModal] = useState(false);
  const { finalize } = useSelector((state: State) => state.ui.careAccess);

  const handleConsentAgreement = (_e: any) => {
    dispatch(setLateCancelationCheck(!finalize.iAgreeToLateCancelationConsent));
  };

  const handleConsent = () => {
    setShowConsentModal(!showConsentModal);
  };

  return (
    <div className={classes.wrapper}>
      <Checkbox
        inputProps={{
          "aria-label":
            "I agree to the Late Cancelation Consent (effective 2023) consent required",
        }}
        color="secondary"
        checked={finalize.iAgreeToLateCancelationConsent}
        onChange={handleConsentAgreement}
      />
      <Typography appearance="smallBody" color="textSecondary">
        <span>
          <span>I agree to the </span>
          <Button
            color="link-primary"
            className={classes.button}
            onClick={handleConsent}
          >
            Late Cancelation Consent
          </Button>
          <span> (effective 2023) consent.</span>
        </span>
        <br />
        <span>Required*</span>
      </Typography>

      <AttachmentViewerModal
        viewer={AttachmentViewerModeEnum.HTML}
        fileUrl={consent.htmlUrl || ""}
        open={showConsentModal}
        title="Late Cancelation Consent (effective 2023)"
        toggle={handleConsent}
      />
    </div>
  );
};

export default CareAccessConsentCheck;
