import { scrollPosition$ } from "containers/Routing";
import { pluckFirst, useObservable, useSubscription } from "observable-hooks";
import { useEffect, useState, useRef } from "react";
import { filter, map } from "rxjs";
import { withLatestFrom } from "rxjs/operators";

export default ({
  fetchNextPage,
  parentDiv,
  nextFetchScrollHeight = 350,
  resultsLength,
}: {
  fetchNextPage: any;
  parentDiv: any;
  nextFetchScrollHeight?: number;
  resultsLength?: number;
}) => {
  const [allowFetch, setAllowFetch] = useState(false);
  const bottomRef = useRef<number | null>(null);
  const heightRef = useRef<number | null>(null);
  const previousScrollPositionRef = useRef<number>(0);

  const bottom$ = useObservable(pluckFirst, [bottomRef]);
  const allowFetch$ = useObservable(pluckFirst, [allowFetch]);
  const fetchNextPage$ = useObservable(pluckFirst, [fetchNextPage]);
  const heightRef$ = useObservable(pluckFirst, [heightRef]);
  const [scrollPos, setScrollPos] = useState(0);

  const scrollUp$ = useObservable<[boolean, boolean]>(() =>
    scrollPosition$.pipe(
      withLatestFrom(bottom$, heightRef$),
      map(
        ([scrollPosition, bottom, divHeight]: [
          scrollPosition: number,
          bottom: any,
          divHeight: any
        ]) => {
          setScrollPos(scrollPosition);
          const isScrollingUp =
            scrollPosition < previousScrollPositionRef.current;
          previousScrollPositionRef.current = scrollPosition;
          return (
            isScrollingUp &&
            scrollPosition <
              bottom.current + divHeight.current - nextFetchScrollHeight
          );
        }
      ),
      filter((scrollUp: boolean) => scrollUp),
      withLatestFrom(allowFetch$, fetchNextPage$)
    )
  );

  useSubscription(
    scrollUp$,
    ([_, allowFetching, fetchNext]: [
      _: any,
      allowFetching: boolean,
      fetchNext: any
    ]) => {
      if (allowFetching) {
        fetchNext();
        setAllowFetch(false);
      }
    }
  );

  useEffect(() => {
    const rect = parentDiv.current?.getBoundingClientRect();
    const bottom = rect?.bottom ?? null;
    const height = rect?.height ?? null;

    setAllowFetch(true);
    bottomRef.current = bottom;
    heightRef.current = height;
  }, [resultsLength, scrollPos]);
};
