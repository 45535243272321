/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberMessageRecipientsResponse,
    MemberMessageRecipientsResponseFromJSON,
    MemberMessageRecipientsResponseToJSON,
} from '../models/MemberMessageRecipientsResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    RecipientID,
    RecipientIDFromJSON,
    RecipientIDToJSON,
} from '../models/RecipientID'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface DeleteRecipientRequest {
    id: string;
    recipientID: RecipientID;
}
export interface FetchMemberMessageRecipientsRequest {
    id: string;
    serviceSubtype?: string;
    serviceLineId?: string;
    state?: string;
}
export interface GetEpisodeMessageRecipientsRequest {
    id: string;
}
export interface GetTimelineEventMessageRecipientsRequest {
    id: string;
}


export interface IMessageRecipientsApi {
      deleteRecipient: (requestParameters: DeleteRecipientRequest) => Promise<void>
      fetchMemberMessageRecipients: (requestParameters: FetchMemberMessageRecipientsRequest) => Promise<MemberMessageRecipientsResponse>
      getEpisodeMessageRecipients: (requestParameters: GetEpisodeMessageRecipientsRequest) => Promise<MemberMessageRecipientsResponse>
      getTimelineEventMessageRecipients: (requestParameters: GetTimelineEventMessageRecipientsRequest) => Promise<MemberMessageRecipientsResponse>
      deleteRecipientWithResponse: (requestParameters: DeleteRecipientRequest) => Promise<{ value: void, response: any}>
      fetchMemberMessageRecipientsWithResponse: (requestParameters: FetchMemberMessageRecipientsRequest) => Promise<{ value: MemberMessageRecipientsResponse, response: any}>
      getEpisodeMessageRecipientsWithResponse: (requestParameters: GetEpisodeMessageRecipientsRequest) => Promise<{ value: MemberMessageRecipientsResponse, response: any}>
      getTimelineEventMessageRecipientsWithResponse: (requestParameters: GetTimelineEventMessageRecipientsRequest) => Promise<{ value: MemberMessageRecipientsResponse, response: any}>
}

/**
 * no description
 */
export class MessageRecipientsApi extends runtime.BaseAPI {

    /**
     * Remove Message Recipient
     * Remove Message Recipient
     */
    deleteRecipientRaw = async (requestParameters: DeleteRecipientRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRecipient.');
        }

                        if (requestParameters.recipientID === null || requestParameters.recipientID === undefined) {
            throw new runtime.RequiredError('recipientID','Required parameter requestParameters.recipientID was null or undefined when calling deleteRecipient.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/member_message_recipients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RecipientIDToJSON(requestParameters.recipientID),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Remove Message Recipient
     * Remove Message Recipient
     */
    deleteRecipient = async (requestParameters: DeleteRecipientRequest): Promise<void> => {
        const { runtime } = await this.deleteRecipientRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Remove Message Recipient
     * Remove Message Recipient
     */
    deleteRecipientWithResponse = async (requestParameters: DeleteRecipientRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteRecipientRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * View the list of recipients for the member direct message
     */
    fetchMemberMessageRecipientsRaw = async (requestParameters: FetchMemberMessageRecipientsRequest): Promise<{ runtime: runtime.ApiResponse<MemberMessageRecipientsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberMessageRecipients.');
        }

                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.serviceSubtype !== undefined) {
            queryParameters['service_subtype'] = requestParameters.serviceSubtype;
        }

        if (requestParameters.serviceLineId !== undefined) {
            queryParameters['service_line_id'] = requestParameters.serviceLineId;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/message_recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberMessageRecipientsResponseFromJSON(jsonValue)), response };
    }

    /**
     * View the list of recipients for the member direct message
     */
    fetchMemberMessageRecipients = async (requestParameters: FetchMemberMessageRecipientsRequest): Promise<MemberMessageRecipientsResponse> => {
        const { runtime } = await this.fetchMemberMessageRecipientsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View the list of recipients for the member direct message
     */
    fetchMemberMessageRecipientsWithResponse = async (requestParameters: FetchMemberMessageRecipientsRequest): Promise<{ value: MemberMessageRecipientsResponse, response: any}> => {
        const { runtime, response } = await this.fetchMemberMessageRecipientsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch message recipients for a given episode
     * Fetch message recipients for a given episode
     */
    getEpisodeMessageRecipientsRaw = async (requestParameters: GetEpisodeMessageRecipientsRequest): Promise<{ runtime: runtime.ApiResponse<MemberMessageRecipientsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEpisodeMessageRecipients.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/message_recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberMessageRecipientsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch message recipients for a given episode
     * Fetch message recipients for a given episode
     */
    getEpisodeMessageRecipients = async (requestParameters: GetEpisodeMessageRecipientsRequest): Promise<MemberMessageRecipientsResponse> => {
        const { runtime } = await this.getEpisodeMessageRecipientsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch message recipients for a given episode
     * Fetch message recipients for a given episode
     */
    getEpisodeMessageRecipientsWithResponse = async (requestParameters: GetEpisodeMessageRecipientsRequest): Promise<{ value: MemberMessageRecipientsResponse, response: any}> => {
        const { runtime, response } = await this.getEpisodeMessageRecipientsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch the possible message recipients for a timeline event
     * Fetch message recipients for the current timeline event/conversation
     */
    getTimelineEventMessageRecipientsRaw = async (requestParameters: GetTimelineEventMessageRecipientsRequest): Promise<{ runtime: runtime.ApiResponse<MemberMessageRecipientsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTimelineEventMessageRecipients.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/timeline_events/{id}/message_recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberMessageRecipientsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch the possible message recipients for a timeline event
     * Fetch message recipients for the current timeline event/conversation
     */
    getTimelineEventMessageRecipients = async (requestParameters: GetTimelineEventMessageRecipientsRequest): Promise<MemberMessageRecipientsResponse> => {
        const { runtime } = await this.getTimelineEventMessageRecipientsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch the possible message recipients for a timeline event
     * Fetch message recipients for the current timeline event/conversation
     */
    getTimelineEventMessageRecipientsWithResponse = async (requestParameters: GetTimelineEventMessageRecipientsRequest): Promise<{ value: MemberMessageRecipientsResponse, response: any}> => {
        const { runtime, response } = await this.getTimelineEventMessageRecipientsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMessageRecipientsApi: IMessageRecipientsApi  = new MessageRecipientsApi();
