import React, { useState, FunctionComponent } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getUser } from "shared/features/user";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import Heading from "components/DynamicHeadings";
import ButtonWithAccess from "components/Button/ButtonWithAccess";
import Asset from "components/Asset";
import { getGreeting } from "components/LandingPage/WelcomeMessage/utils";
import HeroNavyBlue from "styles/images/hero/bg-navy-blue.jpg";
import HeroLightBlue from "styles/images/hero/bg-light-blue.png";
import theme from "styles/mui";
import CurrentLocationModal from "features/careAccess/components/CurrentLocationModal";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import User from "models/User";

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  headerBg: {
    backgroundImage: `url(${HeroNavyBlue})`,
    backgroundSize: "cover",
  },
  headerMinor: {
    backgroundImage: `url(${HeroLightBlue})`,
    "& h1": {
      color: palette?.appBackground?.darkBlue,
    },
  },
  header: {
    color: palette.white,
    height: "120px",
    display: "flex",
    justifyContent: "space-between",
    padding: "61px 0px 23px 0px",
    "& h1": {
      fontSize: "27.7px",
      lineHeight: "36px",
    },
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 56,
    paddingRight: 56,
    [breakpoints.up("xl")]: {
      paddingLeft: 100,
      paddingRight: 100,
      maxWidth: 1200,
    },
    [breakpoints.down("md")]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(1),
    },
  },
  headerMobile: {
    height: "80px",
    padding: "16px 27px",
    "& h1": {
      fontSize: "20px",
      lineHeight: "26px",
      alignSelf: "center",
    },
  },
  getCareBtn: {
    display: "flex",
    padding: "10px 24px",
    height: "44px",
    gap: "8px",
    "& span": {
      fontSize: "13.3px",
      lineHeight: "17.3px",
      alignSelf: "center",
      marginTop: "2px",
    },
  },
}));

const CareAccessHeader: FunctionComponent<{}> = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { accountInfo } =
    useGetAccountInfo({
      // @ts-ignore: Object is possibly 'null'.
      id: user?.id?.toString(),
    }) || {};

  const [isCurrentLocationModalOpen, setCurrentLocationModalOpen] =
    useState(false);
  const isUserMinor = user?.minor;

  const handleClick = (accountState: string | undefined) => {
    if (!accountState) {
      setCurrentLocationModalOpen(true);
    } else {
      navigate(`/members/${user?.id}/care-access/services`);
    }
  };

  const toggle = () => {
    setCurrentLocationModalOpen(false);
  };

  const isIneligible = User.isUserIneligible({ status: user?.status });

  return (
    <div className={clsx(classes.headerBg, isUserMinor && classes.headerMinor)}>
      <div
        className={clsx(classes.header, isMobileScreen && classes.headerMobile)}
      >
        <Heading.H appearance="h1">
          {isUserMinor
            ? `${user?.preferredName || user?.firstName}'s Dashboard`
            : `${getGreeting()} ${user?.preferredName || user?.firstName}`}
        </Heading.H>

        {!isMobileScreen && !isIneligible && (
          <ButtonWithAccess
            access={PermissionsEnum.SelfScheduleCreate}
            color="primary"
            onClick={() => handleClick(accountInfo?.state)}
            className={classes.getCareBtn}
          >
            <Asset hiddenFromScreenReader name="care-brief-case" />
            <span>Get Care Now</span>
          </ButtonWithAccess>
        )}
        <CurrentLocationModal
          openModal={isCurrentLocationModalOpen}
          toggle={toggle}
        />
      </div>
    </div>
  );
};

export default CareAccessHeader;
