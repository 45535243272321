import React from "react";
import ProviderAvatar from "components/VisitHistory/Visit/ProviderAvatar";
import Box from "components/Box";
import Typography from "components/Typography";
import { ClassNameMap } from "@mui/styles";
import palette from "styles/mui/palette";
import EocBadge from "components/EocBadge";
interface IProps {
  providerName: string;
  visitType?: string;
  serviceLine?: string;
  avatarUrl: string;
  clinic?: string;
  classNames: ClassNameMap;
  step: string;
  isPcp: boolean;
  providerId: string;
}
const ProviderDetail = ({
  providerName,
  visitType,
  serviceLine,
  clinic,
  avatarUrl,
  classNames,
  step,
  isPcp,
  providerId,
}: IProps) => {
  const displayFullInfo = clinic && visitType;
  return (
    <div>
      <Box className={classNames.rowContainer}>
        <Box className={classNames.avatarContainer}>
          <ProviderAvatar
            altName={providerName}
            avatarUrl={avatarUrl}
            hasBorder
            canClick={step === "providerDatePicker" ? true : undefined}
            providerId={providerId}
          />
        </Box>
        <Box>
          <Box className={classNames.headline}>
            <Typography
              className={classNames.bodyText}
              appearance="bodyEmphasized"
            >
              {" "}
              {providerName}
            </Typography>
            <Box className={classNames.smallBodyText}>
              <Typography appearance="smallBody">{serviceLine}</Typography>
              {isPcp && (
                <EocBadge
                  badge={{
                    abbreviation: "PCP",
                    color: palette?.white,
                    backgroundColor: palette?.appBackground?.navy,
                  }}
                />
              )}
            </Box>
          </Box>

          {(displayFullInfo || step === "finalizeDetails") && (
            <Box>
              <Typography
                className={classNames.smallBodyText}
                appearance="smallBody"
              >
                {" "}
                {clinic ? `${visitType}, ${clinic}` : `${visitType}`}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ProviderDetail;
