// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationProps
 */
export interface PaginationProps  {
    /**
     * 
     * @type {string}
     * @memberof PaginationProps
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationProps
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginationProps
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationProps
     */
    last?: number;
}

export function PaginationPropsFromJSON(json: any): PaginationProps {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
    };
}

export function PaginationPropsToJSON(value?: PaginationProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
    };
}


