// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberAppointmentTypesSchema,
    MemberAppointmentTypesSchemaFromJSON,
    MemberAppointmentTypesSchemaToJSON,
} from './MemberAppointmentTypesSchema';


/**
 * 
 * @export
 * @interface MemberAppointmentTypesResponse
 */
export interface MemberAppointmentTypesResponse  {
    /**
     * 
     * @type {Array<MemberAppointmentTypesSchema>}
     * @memberof MemberAppointmentTypesResponse
     */
    results?: Array<MemberAppointmentTypesSchema>;
}

export function MemberAppointmentTypesResponseFromJSON(json: any): MemberAppointmentTypesResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberAppointmentTypesSchemaFromJSON),
    };
}

export function MemberAppointmentTypesResponseToJSON(value?: MemberAppointmentTypesResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberAppointmentTypesSchemaToJSON),
    };
}


