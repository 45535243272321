import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import theme from "styles/mui";
import Grid from "components/Grid";
import Typography from "components/Typography";

const useStyles = makeStyles(() => ({
  arrowIcon: {
    height: "16px",
    width: "16px",
    color: theme.palette?.text?.primary,
    "&.MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
  },
  grayText: {
    color: theme.palette?.text?.mediumDarkGray,
  },
  dateRange: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  selectedDates: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

interface ICalendarNavigationProps {
  onClickNext: () => void;
  onClickPrevious: () => void;
  dateLabelText: string;
  startIsToday: boolean;
}

const SelectedDateRange = ({
  dateLabelText,
  onClickNext,
  onClickPrevious,
  startIsToday,
}: ICalendarNavigationProps) => {
  const classes = useStyles();
  const backArrowClass = `${classes.arrowIcon} ${
    startIsToday ? classes.grayText : ""
  }`;

  return (
    <Grid item xs={12} md={6}>
      <Typography className={classes.dateRange}>
        <IconButton
          onClick={onClickPrevious}
          aria-label="View slots for previous week"
          disabled={startIsToday}
        >
          <ArrowBackIos name="icon-arrow-back" className={backArrowClass} />
        </IconButton>
        <span className={classes.selectedDates}>{dateLabelText}</span>
        <IconButton onClick={onClickNext} aria-label="View slots for next week">
          <ArrowForwardIos
            name="icon-arrow-forward"
            className={classes.arrowIcon}
          />
        </IconButton>
      </Typography>
    </Grid>
  );
};

export default SelectedDateRange;
