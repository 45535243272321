// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    ServiceTypesEligibleArrayResponse,
    ServiceTypesEligibleArrayResponseFromJSON,
    ServiceTypesEligibleArrayResponseToJSON,
} from '../models/ServiceTypesEligibleArrayResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMemberServiceTypesRequest {
    id: string;
    schedulingType?: FetchMemberServiceTypesSchedulingTypeEnum;
}


/**
 * View the list of service types eligible for a member
 */
function fetchMemberServiceTypesRaw<T>(requestParameters: FetchMemberServiceTypesRequest, requestConfig: runtime.TypedQueryConfig<T, ServiceTypesEligibleArrayResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberServiceTypes.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["member"]];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/service_types`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ServiceTypesEligibleArrayResponseFromJSON(body), text);
    }

    return config;
}

/**
* View the list of service types eligible for a member
*/
export function fetchMemberServiceTypes<T>(requestParameters: FetchMemberServiceTypesRequest, requestConfig?: runtime.TypedQueryConfig<T, ServiceTypesEligibleArrayResponse>): QueryConfig<T> {
    return fetchMemberServiceTypesRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum FetchMemberServiceTypesSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
