// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClinicProviderConfigSchema,
    ClinicProviderConfigSchemaFromJSON,
    ClinicProviderConfigSchemaToJSON,
} from './ClinicProviderConfigSchema';


/**
 * 
 * @export
 * @interface ClinicProviderConfigFetchResponseBase
 */
export interface ClinicProviderConfigFetchResponseBase  {
    /**
     * 
     * @type {Array<ClinicProviderConfigSchema>}
     * @memberof ClinicProviderConfigFetchResponseBase
     */
    results: Array<ClinicProviderConfigSchema>;
}

export function ClinicProviderConfigFetchResponseBaseFromJSON(json: any): ClinicProviderConfigFetchResponseBase {
    return {
        'results': (json['results'] as Array<any>).map(ClinicProviderConfigSchemaFromJSON),
    };
}

export function ClinicProviderConfigFetchResponseBaseToJSON(value?: ClinicProviderConfigFetchResponseBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(ClinicProviderConfigSchemaToJSON),
    };
}


