import React, { useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import { State } from "@types";
import { useGetLanguagesList } from "shared/features/languagesList";
import { setPreferredLanguage } from "shared/state/ui/careAccess/actions";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import Autocomplete from "components/Autocomplete";
import TextField from "components/TextField";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    width: "100%",
    [breakpoints.up("sm")]: {
      width: "328px",
    },
  },
  autocomplete: {
    marginTop: "16px",
    marginLeft: "20px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      backgroundColor: palette.white,
      borderRadius: "8px",
      height: "40px",
      padding: "0px 12px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.text.tertiary}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.text.tertiary}`,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.secondary.main}`,
    },
  },
  autocompleteError: {
    "& .MuiInputBase-root": {
      backgroundColor: palette.pinkPastel,
    },
    "& .MuiInputBase-input": {
      backgroundColor: `${palette.pinkPastel} !important`,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${palette.redHue}`,
    },
  },
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "&[aria-selected='true']": {
          backgroundColor: palette?.appBackground?.blue,
          color: palette.white,
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
      },
    },
  },
}));

const LanguageDropdown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId } = useOutletContext<IOutletContext>();
  const { languagesList } = useGetLanguagesList();
  const { accountInfo } = useGetAccountInfo({ id: memberId || "" });
  const {
    finalize: { preferredLanguage },
  } = useSelector((state: State) => state.ui.careAccess);

  useEffect(() => {
    if (!preferredLanguage?.code && !!accountInfo?.preferredLanguage) {
      dispatch(setPreferredLanguage(accountInfo?.preferredLanguage));
    }
  }, [accountInfo?.preferredLanguage]);

  const handleSetLanguage = (newValue: any) => {
    dispatch(setPreferredLanguage(newValue));
  };

  const getOptionSelected = (option: any, value: any) => {
    if (value === null) {
      return false;
    } else {
      return value.code === option.code;
    }
  };

  const hasError = !preferredLanguage?.code;

  return (
    <div className={classes.container}>
      <Autocomplete
        className={clsx(
          classes.autocomplete,
          hasError && classes.autocompleteError
        )}
        options={
          languagesList?.map((l) => {
            return {
              code: l.isoCode,
              label: l.language,
            };
          }) || []
        }
        onChange={(_event, newValue) => handleSetLanguage(newValue)}
        value={preferredLanguage?.code ? preferredLanguage : null}
        isOptionEqualToValue={getOptionSelected}
        ListboxProps={{
          className: classes.listboxItem,
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            id="select-language"
            placeholder="Select language"
            helperText={hasError ? "Please select a language" : ""}
            error={hasError}
          />
        )}
      />
    </div>
  );
};

export default LanguageDropdown;
