// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClinicProviderConfigSchemaDays,
    ClinicProviderConfigSchemaDaysFromJSON,
    ClinicProviderConfigSchemaDaysToJSON,
} from './ClinicProviderConfigSchemaDays';


/**
 * 
 * @export
 * @interface ClinicProviderConfigSchema
 */
export interface ClinicProviderConfigSchema  {
    /**
     * 
     * @type {number}
     * @memberof ClinicProviderConfigSchema
     */
    readonly id?: number;
    /**
     * Name of the provider
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    clinicConfigId: string;
    /**
     * Name to be sent in the HL7 message
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    flat: string;
    /**
     * Provider image
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    shot: string;
    /**
     * Name shown on the provider page in the portal
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    show: string;
    /**
     * Defines the provider type
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    typeConfig: string;
    /**
     * Unique username (value of \"provider_id\" in appointments table)
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    user: string;
    /**
     * Must match the \"staff_global_id\" in the users table
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    globalId: string;
    /**
     * Option to block last slot on calendar if all 6 are already full (only used for Therapists)
     * @type {number}
     * @memberof ClinicProviderConfigSchema
     */
    dailyVisitLimit?: number;
    /**
     * Zoom link or Teams ID for providers who do virtual visits
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    videoConferenceLink?: string;
    /**
     * Is the provider a PCP at this clinic
     * @type {boolean}
     * @memberof ClinicProviderConfigSchema
     */
    pcpEligible?: boolean;
    /**
     * Elation Provider ID (not Elation account ID) for the Elation Practice the clinic is in.
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    elationId: string;
    /**
     * The appointment IDs that the provider \"uses\".
     * @type {Array<string>}
     * @memberof ClinicProviderConfigSchema
     */
    uses: Array<string>;
    /**
     * Portal feature that shows the hours a provider works each day of the week.
     * @type {Array<ClinicProviderConfigSchemaDays>}
     * @memberof ClinicProviderConfigSchema
     */
    days: Array<ClinicProviderConfigSchemaDays>;
    /**
     * Array of US state abbreviations
     * @type {Array<string>}
     * @memberof ClinicProviderConfigSchema
     */
    usStateLicenses?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    microsoftTeamsUserId?: string;
    /**
     * Marked true for any provider who has termed or no longer works at the clinic
     * @type {boolean}
     * @memberof ClinicProviderConfigSchema
     */
    gone?: boolean;
    /**
     * Used for RNs and other staff who don\'t see appointments but needed to order labs, etc for mirth processing.
     * @type {boolean}
     * @memberof ClinicProviderConfigSchema
     */
    mirthProvider?: boolean;
    /**
     * Used for providers who have left
     * @type {boolean}
     * @memberof ClinicProviderConfigSchema
     */
    genericProvider?: boolean;
    /**
     * Sort order for the provider in XOcal
     * @type {string}
     * @memberof ClinicProviderConfigSchema
     */
    xocalSort?: string;
}

export function ClinicProviderConfigSchemaFromJSON(json: any): ClinicProviderConfigSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'clinicConfigId': json['clinic_config_id'],
        'flat': json['flat'],
        'shot': json['shot'],
        'show': json['show'],
        'typeConfig': json['type_config'],
        'user': json['user'],
        'globalId': json['global_id'],
        'dailyVisitLimit': !exists(json, 'daily_visit_limit') ? undefined : json['daily_visit_limit'],
        'videoConferenceLink': !exists(json, 'video_conference_link') ? undefined : json['video_conference_link'],
        'pcpEligible': !exists(json, 'pcp_eligible') ? undefined : json['pcp_eligible'],
        'elationId': json['elation_id'],
        'uses': json['uses'],
        'days': (json['days'] as Array<any>).map(ClinicProviderConfigSchemaDaysFromJSON),
        'usStateLicenses': !exists(json, 'us_state_licenses') ? undefined : json['us_state_licenses'],
        'microsoftTeamsUserId': !exists(json, 'microsoft_teams_user_id') ? undefined : json['microsoft_teams_user_id'],
        'gone': !exists(json, 'gone') ? undefined : json['gone'],
        'mirthProvider': !exists(json, 'mirth_provider') ? undefined : json['mirth_provider'],
        'genericProvider': !exists(json, 'generic_provider') ? undefined : json['generic_provider'],
        'xocalSort': !exists(json, 'xocal_sort') ? undefined : json['xocal_sort'],
    };
}

export function ClinicProviderConfigSchemaToJSON(value?: ClinicProviderConfigSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'clinic_config_id': value.clinicConfigId,
        'flat': value.flat,
        'shot': value.shot,
        'show': value.show,
        'type_config': value.typeConfig,
        'user': value.user,
        'global_id': value.globalId,
        'daily_visit_limit': value.dailyVisitLimit,
        'video_conference_link': value.videoConferenceLink,
        'pcp_eligible': value.pcpEligible,
        'elation_id': value.elationId,
        'uses': value.uses,
        'days': (value.days as Array<any>).map(ClinicProviderConfigSchemaDaysToJSON),
        'us_state_licenses': value.usStateLicenses,
        'microsoft_teams_user_id': value.microsoftTeamsUserId,
        'gone': value.gone,
        'mirth_provider': value.mirthProvider,
        'generic_provider': value.genericProvider,
        'xocal_sort': value.xocalSort,
    };
}


