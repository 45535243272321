import React from "react";
import ErrorBoundaryPage from "components/Access/ErrorBoundaryPage";

const ErrorsBoundary: React.FunctionComponent = ({
  error,
  resetError,
}: any) => {
  React.useEffect(() => {
    if (process.env.REACT_APP_ROLLBAR_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  return (
    <ErrorBoundaryPage
      imageName="noEpisodeResults"
      message="There seems to be an issue loading this screen right now. You can try to view it again later."
      resetError={resetError}
    />
  );
};

export default ErrorsBoundary;
