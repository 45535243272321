import React, { useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import format from "date-fns/format";
import { isEqual } from "date-fns";
import { State } from "@types";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";
import { Steps } from "shared/state/ui/careAccess/steps";
import { GetNextAvailableSlotsSchedulingTypeEnum } from "shared/fetch/src/apis/SlotsApi";
import { GetCapacitySchedulingTypeEnum } from "shared/api/src/apis";
import {
  setSelectedProvider,
  setSelectedSlotProvider,
  setSelectedSlot,
  resetReasonForVisit,
} from "shared/state/ui/careAccess/actions";
import { ISelectedProvider } from "shared/state/ui/careAccess";
import useGetProviderCapacity from "shared/features/selfSchedule/useGetProviderCapacity";
import useGetNextAvailableSlots from "shared/features/selfSchedule/useGetNextAvailableSlots";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";
import CareAccessContent from "features/careAccess/components/CareAccessContent";
import PickerEmptyState from "features/careAccess/components/EmptyStateComponents/PickerEmptyState";
import SelectedDateRange from "features/selfSchedule/enhancements/PickerPage/SelectedDateRange";
import AppointmentCalendar from "features/selfSchedule/enhancements/PickerPage/AppointmentCalendar";
import NextAvailableDrawer from "features/selfSchedule/enhancements/PickerPage/NextAvailableDrawer";
import DatePickerSkeleton from "features/careAccess/pages/CareAccessSkeletons/DatePickerSkeleton";
import ProviderAutocomplete from "components/ProviderDropDown/SearchInput";
import Typography from "components/Typography";
import Button from "components/Button";
import addDays from "utils/addDays";
import useFormatDate from "shared/utils/useFormatDate";
import useMobileCheck from "hooks/useMobileCheck";

const DAYS_TO_DISPLAY_DESKTOP = 14;
const DAYS_TO_DISPLAY_MOBILE = 7;
const DEFAULT_NEW_START_DATE = 0;

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  wrapper: {
    marginTop: "10px",
    maxWidth: "840px",
    [breakpoints.down("md")]: {
      width: "unset",
    },
  },
  content: {
    marginTop: "30px",
    [breakpoints.down("md")]: {
      marginTop: "12px",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    [breakpoints.down("md")]: {
      flexDirection: "column",
    },
    "& p": {
      alignSelf: "center",
    },
    "& button": {
      border: `1px solid ${palette.secondary.main}`,
      backgroundColor: palette.bluePastelAlt,
      borderRadius: "8px",
      [breakpoints.down("md")]: {
        marginTop: "16px",
      },
    },
  },
  dateRangeAndProvider: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiFormControl-root": {
      width: "100% !important",
    },
    [breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      marginTop: "16px",
    },
  },
  dateRange: {
    [breakpoints.down("md")]: {
      marginTop: "16px",
    },
  },
}));

const CareAccessPicker = () => {
  const classes = useStyles();
  const formatDate = useFormatDate();
  const dispatch = useDispatch();
  const parentDiv = useRef(null);
  const { goToNextPage, memberId } = useOutletContext<IOutletContext>();

  const {
    visits,
    centers,
    locationState,
    datePicker,
    services: { serviceLineId },
  } = useSelector((state: State) => state.ui.careAccess);
  const selectedProvider = datePicker.provider;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const onDrawerClose = () => setIsDrawerOpen(false);
  const onDrawerOpen = () => setIsDrawerOpen(true);

  const isMobileOrNativeApp = useMobileCheck();
  const DAYS_TO_DISPLAY = useMemo(
    () =>
      isMobileOrNativeApp ? DAYS_TO_DISPLAY_MOBILE : DAYS_TO_DISPLAY_DESKTOP,
    [isMobileOrNativeApp]
  );

  const { accountInfo = {} } = useGetAccountInfo({ id: memberId || "" });
  const memberTimezone =
    accountInfo?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { slots: nextAvailableSlots, nextAvailableLabel } =
    useGetNextAvailableSlots({
      id: memberId || "",
      appointmentType: visits.appointmentType,
      timezone: memberTimezone,
      usState: locationState.code,
      clinicId: centers.centerId !== "0" ? centers.centerId : undefined,
      schedulingType: GetNextAvailableSlotsSchedulingTypeEnum.SelfSchedule,
      serviceLineId,
    });

  const [newStartDateCount, setNewStartDateCount] = useState(
    DEFAULT_NEW_START_DATE
  );
  const [daysCount, setDaysCount] = useState(DAYS_TO_DISPLAY - 1);
  const [startDateParam, setStartDateParam] = useState("");

  const today = new Date();
  const startDate = addDays(today, newStartDateCount);
  const endDate = addDays(today, daysCount);
  const startIsToday = isEqual(startDate, today);

  const dateLabel = useMemo(() => {
    let startDateLabel = startIsToday
      ? `Today, ${formatDate(startDate, "MMM, d")}`
      : `${formatDate(startDate, "eee, MMM, d")}`;
    startDateLabel += ` - ${formatDate(endDate, "eee, MMM, d")}`;
    return startDateLabel;
  }, [startDate, endDate]);

  useEffect(() => {
    const formattedDate = format(new Date(startDate), "yyyy-MM-dd");
    if (formattedDate !== startDateParam) {
      setStartDateParam(formattedDate);
    }
  }, [newStartDateCount, startDate]);

  const handleClickNext = () => {
    setNewStartDateCount(newStartDateCount + DAYS_TO_DISPLAY);
    setDaysCount(daysCount + DAYS_TO_DISPLAY);
  };

  const handleClickPrevious = () => {
    setNewStartDateCount(newStartDateCount - DAYS_TO_DISPLAY);
    setDaysCount(daysCount - DAYS_TO_DISPLAY);
  };

  const {
    providers,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetProviderCapacity({
    id: memberId || "",
    startDate: startDateParam,
    daysCount: isMobileOrNativeApp ? "7" : "14",
    itemCount: "4",
    timezone: memberTimezone,
    appointmentType: visits.appointmentType,
    schedulingType: GetCapacitySchedulingTypeEnum.SelfSchedule,
    clinicId: centers.centerId !== "0" ? centers.centerId : undefined,
    usState: locationState.code,
    staffGlobalId: !!selectedProvider?.code
      ? selectedProvider?.code
      : undefined,
    serviceLineId,
  });

  const onSelectedProvider = (provider: ISelectedProvider) => {
    dispatch(setSelectedProvider(provider));
  };

  const onAppointmentSlotSelected = (_slotId: string, slot: SlotOutput) => {
    const slotProvider = providers?.find(
      (prov: any) => prov?.provider?.staffGlobalId === slot.providerGlobalId
    )?.provider;
    dispatch(setSelectedSlotProvider(slotProvider));
    dispatch(setSelectedSlot(slot));
    dispatch(resetReasonForVisit());
    goToNextPage(Steps.FINALIZE)();
  };

  const formattedProviders =
    providers?.map((prov: any) => {
      return { code: prov.provider.staffGlobalId, name: prov.provider.name };
    }) || [];

  if (isFetching) {
    return (
      <CareAccessContent>
        <div className={classes.wrapper}>
          <Typography appearance="body">{visits.appointmentLabel}</Typography>
          <div className={classes.content}>
            <SelectedDateRange
              onClickNext={handleClickNext}
              onClickPrevious={handleClickPrevious}
              dateLabelText={dateLabel}
              startIsToday={startIsToday}
            />
            <DatePickerSkeleton />
          </div>
        </div>
      </CareAccessContent>
    );
  }

  return (
    <CareAccessContent>
      <div ref={parentDiv} className={classes.wrapper}>
        {!providers?.length ? (
          <>
            <Typography appearance="body">{visits.appointmentLabel}</Typography>
            <div className={classes.content}>
              <SelectedDateRange
                onClickNext={handleClickNext}
                onClickPrevious={handleClickPrevious}
                dateLabelText={dateLabel}
                startIsToday={startIsToday}
              />
              <PickerEmptyState />
            </div>
          </>
        ) : (
          <>
            <div className={classes.header}>
              <Typography appearance="body">
                {visits.appointmentLabel}
              </Typography>
              <Button size="medium" color="tertiary" onClick={onDrawerOpen}>
                {nextAvailableLabel}
              </Button>
            </div>

            <div className={classes.content}>
              <div className={classes.dateRangeAndProvider}>
                <div className={classes.dateRange}>
                  <SelectedDateRange
                    onClickNext={handleClickNext}
                    onClickPrevious={handleClickPrevious}
                    dateLabelText={dateLabel}
                    startIsToday={startIsToday}
                  />
                </div>
                <ProviderAutocomplete
                  providers={formattedProviders}
                  isPending={isFetching}
                  onSelectedProvider={onSelectedProvider}
                  selectedProvider={selectedProvider}
                  fullWidth={isMobileOrNativeApp}
                />
              </div>

              <AppointmentCalendar
                data={providers}
                isFetching={isFetching}
                onAppointmentSlotSelected={onAppointmentSlotSelected}
                parentDiv={parentDiv}
                visitType={{ appointmentId: visits.appointmentType }}
                appointmentName={visits.appointmentLabel}
                isVirtualVisit={visits.visitFilter === "virtual"}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage || false}
                isFetchingNextPage={isFetchingNextPage}
                careAccessState={locationState?.code}
              />
            </div>
          </>
        )}

        {!isEmpty(nextAvailableSlots) && (
          <NextAvailableDrawer
            isDrawerOpen={isDrawerOpen}
            onDrawerClose={onDrawerClose}
            nextAvailableSlots={nextAvailableSlots}
            onAppointmentSlotSelected={onAppointmentSlotSelected}
            selectedTimezone={memberTimezone}
            appointmentName={visits.appointmentLabel}
          />
        )}
      </div>
    </CareAccessContent>
  );
};

export default CareAccessPicker;
