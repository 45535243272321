import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import BuildingIcon from "@mui/icons-material/Apartment";
import CloudIcon from "@mui/icons-material/Cloud";
import { State } from "@types";
import { MessageRecipient } from "shared/fetch/src/models/MessageRecipient";
import { XopPodOutputSchema } from "shared/fetch/src/models/XopPodOutputSchema";
import { MessageRecipientPropsTypeEnum } from "shared/fetch/src/models/MessageRecipientProps";
import {
  resetIsPaymentsRedirect,
  setMessageRecipient,
} from "shared/state/ui/careAccess/actions";
import {
  isXopPodOutputSchema,
  trimName,
  trimRole,
  trimSubtitle,
} from "components/ProviderDropDown/utils";
import Avatar from "components/Avatar";
import Box from "components/Box";
import Typography from "components/Typography";
import TextField from "components/TextField";
import EocBadge from "components/EocBadge";
import palette from "styles/mui/palette";

interface IProps {
  recipients?: MessageRecipient[];
  careTeam?: XopPodOutputSchema;
  isPending?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  groupIconMenuItem: {
    marginRight: "9px",
    marginLeft: "4px",
    width: "1.2em",
    height: "1.2em",
    color: theme.palette.text.secondary,
    marginTop: "auto",
    marginBottom: "auto",
  },
  wrapNameAndRole: {
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  clinics: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "200px",
  },
  avatar: {
    marginRight: "9px",
    marginLeft: "-10px",
  },
  option: {
    padding: 0,
    '&[aria-disabled="true"]': {
      opacity: 1,
    },
  },
  customOptionContainer: {
    display: "flex",
    padding: 6,
    paddingLeft: 16,
    paddingRight: 16,
    width: "100%",
  },
  icons: {
    minWidth: 30,
    margin: "auto 0",
  },
  cloudIcon: {
    width: 30,
    height: 26,
  },
  badge: {
    marginLeft: 2,
    paddingLeft: 0,
    paddingBottom: 2,
    borderRadius: 4,
    justifyContent: "center",
  },
  autocomplete: {
    borderRadius: "8px",
    border: `1px solid ${palette?.text?.tertiary}`,
    "&.Mui-focused": {
      border: `1px solid ${palette?.appBackground?.blue}`,
    },
    "@media (min-width: 576px)": {
      width: "277px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderRadius: 8,
      paddingTop: 4.5,
      paddingBottom: 4.5,
    },
    "& .MuiAutocomplete-input": {
      paddingTop: "0px !important",
      paddingBottom: "0px !important",
    },
  },
  label: {
    color: palette?.links?.secondaryHover,
    marginBottom: 5,
  },
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "&[aria-selected='true']": {
          backgroundColor: palette?.appBackground?.blue,
          color: palette.white,
        },
        "& *": {
          color: palette.white, // Apply the color to all nested elements
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: palette?.appBackground?.blue,
        color: palette.white,
        "& *": {
          color: palette.white, // Apply the color to all nested elements
        },
      },
    },
  },
}));

const ComboBoxLabel = ({ label }: { label: string }) => {
  return (
    <Typography color="textSecondary" appearance="caption" mt={1} mb={2} ml={2}>
      {label}
    </Typography>
  );
};

const RecipientComboBox: FunctionComponent<IProps> = ({
  recipients = [],
  careTeam,
  isPending,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    message: { messageRecipient, isPaymentsRedirect },
  } = useSelector((state: State) => state.ui.careAccess);

  const [selectedProvider, setSelectedProvider] = useState<
    MessageRecipient | XopPodOutputSchema | null
  >(null);

  useEffect(() => {
    if (selectedProvider?.id !== messageRecipient?.id) {
      setSelectedProvider(messageRecipient);
    }
  }, [messageRecipient]);

  useEffect(() => {
    if (isPaymentsRedirect && careTeam?.coreCareTeam) {
      handleSelectedProvider(careTeam);
      dispatch(resetIsPaymentsRedirect());
    }
  }, [isPaymentsRedirect, careTeam]);

  const handleSelectedProvider = (
    provider: MessageRecipient | XopPodOutputSchema | null
  ) => {
    setSelectedProvider(provider);
    dispatch(
      setMessageRecipient({
        id: provider?.id || "",
        name: provider?.name || "",
        type: provider?.type || "",
        clinics: (provider as MessageRecipient)?.clinics || [],
        oooNotifications:
          (provider as MessageRecipient)?.oooNotifications || [],
      })
    );
  };

  useEffect(() => {
    if (recipients?.length) {
      const myContacts: MessageRecipient = {
        name: "My Contacts",
        guilds: ["--"],
        type: MessageRecipientPropsTypeEnum.Ctm,
        id: "",
      };
      recipients?.unshift(myContacts);
    }
  }, [recipients]);

  const generalInbox: MessageRecipient = {
    name: "General Inbox",
    guilds: ["--"],
    type: MessageRecipientPropsTypeEnum.Ctm,
    id: "",
  };

  const careTeams: (MessageRecipient | XopPodOutputSchema)[] = [];
  if (typeof careTeam !== "undefined") {
    careTeams.push(generalInbox, careTeam);
  }

  const options: any[] = [...recipients, ...careTeams];

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => {
      let finder = option?.name || option?.careTeamName;
      if (!isXopPodOutputSchema(option)) {
        if (option?.clinics?.length) {
          finder += " " + option.clinics.join();
        }
        if (option?.guilds?.[0]) {
          finder += " " + option.guilds[0];
        }
      }
      return finder;
    },
  });

  const getCareTeamTypeIcon = (
    provider: MessageRecipient | XopPodOutputSchema
  ) => {
    if ((provider as XopPodOutputSchema)?.careTeamType === "virtual") {
      return (
        <CloudIcon
          id={"cloud-icon"}
          className={clsx(classes.groupIconMenuItem, classes.cloudIcon)}
        />
      );
    } else if ((provider as XopPodOutputSchema)?.careTeamType === "in_person") {
      return (
        <BuildingIcon
          id={"building-icon"}
          className={classes.groupIconMenuItem}
        />
      );
    } else {
      return null;
    }
  };

  const getIcon = (provider: MessageRecipient | XopPodOutputSchema) => {
    return (
      <Avatar
        id={"avatar-icon"}
        size={"small"}
        alt={provider.name}
        src={(provider as MessageRecipient)?.avatarUrl}
        data-testid="default-avatar"
        className={classes.avatar}
        isPresentational
      />
    );
  };

  const renderOption = (
    props: any,
    option: MessageRecipient | XopPodOutputSchema
  ) => {
    if (option.name === "My Contacts") {
      return <ComboBoxLabel key={option.name} label="My Contacts" />;
    }
    if (option.name === "General Inbox") {
      return <ComboBoxLabel key={option.name} label="General Inbox" />;
    }
    return (
      <li {...props} key={option.id} style={{ width: "100%" }}>
        <div className={classes.customOptionContainer}>
          <div className={classes.icons}>
            {option.name !== "Care Team" ? (
              <>
                {isXopPodOutputSchema(option)
                  ? getCareTeamTypeIcon(option)
                  : getIcon(option)}
              </>
            ) : (
              <BuildingIcon
                id={"building-icon"}
                className={classes.groupIconMenuItem}
              />
            )}
          </div>
          <div>
            <Box style={{ maxWidth: "200px" }}>
              <Typography
                appearance="smallBody"
                color="textPrimary"
                className={classes.wrapNameAndRole}
              >
                {trimName(option, true)}
              </Typography>
            </Box>
            <Box style={{ width: "100%", display: "flex" }}>
              <Typography
                className={classes.wrapNameAndRole}
                appearance="smallBody"
                color="textSecondary"
                mr={1 / 2}
              >
                {trimRole(option, true)}
              </Typography>
              {(option as MessageRecipient)?.isPcp ? (
                <EocBadge
                  badge={{
                    abbreviation: "PCP",
                    color: palette.text.primary,
                    backgroundColor: palette.appBackground.bluePastel,
                  }}
                  className={classes.badge}
                />
              ) : null}
            </Box>

            {isXopPodOutputSchema(option) ? (
              <Typography
                color="textSecondary"
                appearance="smallBody"
                className={classes.wrapNameAndRole}
              >
                {trimSubtitle(
                  (option as XopPodOutputSchema)?.displaySubtitle,
                  true
                )}
              </Typography>
            ) : null}
          </div>
        </div>
      </li>
    );
  };

  return (
    <div
      data-testid="care-access-recipient-combobox"
      className={classes.container}
    >
      <Typography className={classes.label} appearance="smallBody">
        Care team member
      </Typography>
      <Autocomplete
        className={classes.autocomplete}
        openOnFocus
        disableClearable={!selectedProvider}
        onOpen={() => setIsExpanded(true)}
        onClose={() => setIsExpanded(false)}
        loading={isPending}
        classes={{
          option: classes.option,
        }}
        ListboxProps={{
          className: classes.listboxItem,
        }}
        id="care-access-recipient-dropdown"
        getOptionLabel={(option: MessageRecipient | XopPodOutputSchema) =>
          option.name
        }
        options={options}
        renderInput={(params) => (
          <TextField
            role="combobox"
            ariaExpanded={isExpanded}
            {...params}
            placeholder="Select or type"
            fullWidth
          />
        )}
        value={selectedProvider}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id &&
          option.name === value.name &&
          option.type === value.type
        }
        onChange={(_e: any, value: any) => {
          if (value) {
            handleSelectedProvider(value);
          } else {
            handleSelectedProvider(null);
          }
        }}
        renderOption={renderOption as any}
        getOptionDisabled={(option) =>
          option.name === "My Contacts" || option.name === "General Inbox"
        }
      />
    </div>
  );
};

export default RecipientComboBox;
