import React from "react";
import useFetchMoreOnScroll from "hooks/useFetchMoreOnScroll";
import CircularProgress from "../Progress/CircularProgress";
import styles from "./index.module.css";
import useFetchMoreOnScrollUp from "hooks/useFetchMoreOnScrollUp";

interface IProps {
  fetchNextPage: any;
  hasNextPage?: any;
  spinnerContainerClass?: any;
  spinnerClass?: any;
  parentDiv: any;
  resultsLength: number | undefined;
  isFetchingNextPage: boolean;
  nextFetchScrollHeight?: number;
  direction?: string;
}

const FetchMoreOnScroll = ({
  fetchNextPage,
  hasNextPage,
  spinnerContainerClass,
  spinnerClass,
  parentDiv,
  resultsLength,
  isFetchingNextPage,
  nextFetchScrollHeight,
  direction,
}: IProps) => {
  if (direction === "ASC") {
    useFetchMoreOnScrollUp({
      fetchNextPage,
      parentDiv,
      resultsLength,
      nextFetchScrollHeight,
    });
  } else {
    useFetchMoreOnScroll({
      fetchNextPage,
      hasNextPage,
      parentDiv,
      resultsLength,
      nextFetchScrollHeight,
    });
  }

  if (isFetchingNextPage) {
    return (
      <div
        className={
          spinnerContainerClass
            ? spinnerContainerClass
            : styles.loadingSpinnerContainer
        }
      >
        <CircularProgress
          className={spinnerClass ? spinnerClass : styles.spinner}
        />
      </div>
    );
  }

  return null;
};

export default FetchMoreOnScroll;
