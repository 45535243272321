// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateAppointment,
    UpdateAppointmentFromJSON,
    UpdateAppointmentToJSON,
} from '../models/UpdateAppointment'
import {
    VirtualVisitReferralComponentDetailsOutput,
    VirtualVisitReferralComponentDetailsOutputFromJSON,
    VirtualVisitReferralComponentDetailsOutputToJSON,
} from '../models/VirtualVisitReferralComponentDetailsOutput'


export interface CancelAppointmentRequest {
    id: string;
    updateAppointment: UpdateAppointment;
}


/**
 * Cancel Appointment by id
 * Cancel Appointment by id
 */
function cancelAppointmentRaw<T>(requestParameters: CancelAppointmentRequest, requestConfig: runtime.TypedQueryConfig<T, VirtualVisitReferralComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelAppointment.');
    }

    if (requestParameters.updateAppointment === null || requestParameters.updateAppointment === undefined) {
        throw new runtime.RequiredError('updateAppointment','Required parameter requestParameters.updateAppointment was null or undefined when calling cancelAppointment.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/appointments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UpdateAppointmentToJSON(requestParameters.updateAppointment),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(VirtualVisitReferralComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Cancel Appointment by id
* Cancel Appointment by id
*/
export function cancelAppointment<T>(requestParameters: CancelAppointmentRequest, requestConfig?: runtime.TypedQueryConfig<T, VirtualVisitReferralComponentDetailsOutput>): QueryConfig<T> {
    return cancelAppointmentRaw(requestParameters, requestConfig);
}

