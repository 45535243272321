// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberClinics,
    MemberClinicsFromJSON,
    MemberClinicsToJSON,
} from '../models/MemberClinics'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetMemberClinicsRequest {
    id: string;
    appointmentType?: string;
    screenLocation?: string;
    selfSchedule?: boolean;
    schedulingType?: GetMemberClinicsSchedulingTypeEnum;
    usState?: string;
    serviceLineId?: string;
}


/**
 * Fetch eligible and closest clinics for a member
 * Fetch eligible clinics for a member
 */
function getMemberClinicsRaw<T>(requestParameters: GetMemberClinicsRequest, requestConfig: runtime.TypedQueryConfig<T, MemberClinics> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemberClinics.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.appointmentType !== undefined) {
        queryParameters['appointment_type'] = requestParameters.appointmentType;
    }


    if (requestParameters.screenLocation !== undefined) {
        queryParameters['screen_location'] = requestParameters.screenLocation;
    }


    if (requestParameters.selfSchedule !== undefined) {
        queryParameters['self_schedule'] = requestParameters.selfSchedule;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }


    if (requestParameters.usState !== undefined) {
        queryParameters['us_state'] = requestParameters.usState;
    }


    if (requestParameters.serviceLineId !== undefined) {
        queryParameters['service_line_id'] = requestParameters.serviceLineId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/clinics`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MemberClinicsFromJSON(body), text);
    }

    return config;
}

/**
* Fetch eligible and closest clinics for a member
* Fetch eligible clinics for a member
*/
export function getMemberClinics<T>(requestParameters: GetMemberClinicsRequest, requestConfig?: runtime.TypedQueryConfig<T, MemberClinics>): QueryConfig<T> {
    return getMemberClinicsRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetMemberClinicsSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
