import React, { FunctionComponent } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Heading from "components/DynamicHeadings";
import { Steps } from "shared/state/ui/careAccess/steps";

interface IHeaderProps {
  currentStep: string;
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  header: {
    color: palette.text.primary,
    [breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "21px",
      marginLeft: "8px",
    },
  },
}));

const Header: FunctionComponent<IHeaderProps> = ({ currentStep }) => {
  const classes = useStyles();
  const {
    SERVICES,
    DECISIONS,
    MESSAGE,
    VISITS,
    CENTERS,
    DATE_PICKER,
    FINALIZE,
  } = Steps;

  const headerByStep = (step: string) => {
    let title;
    switch (step) {
      case SERVICES:
        title = "How can we help you?";
        break;
      case DECISIONS:
        title = "How do you want to get care?";
        break;
      case MESSAGE:
        title = "Compose message";
        break;
      case CENTERS:
        title = "Choose a center";
        break;
      case VISITS:
        title = "Select a visit";
        break;
      case DATE_PICKER:
        title = "Choose a provider, date and time";
        break;
      case FINALIZE:
        title = "Reason for visit";
        break;
      default:
        title = "";
    }
    return title;
  };

  return (
    <Heading.LevelBoundary levelOverride={1}>
      <Heading.H appearance="h5" className={classes.header}>
        {headerByStep(currentStep)}
      </Heading.H>
    </Heading.LevelBoundary>
  );
};

export default Header;
