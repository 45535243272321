import React, { FunctionComponent } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { mutateAsync } from "redux-query";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Icons from "../../styles/icons/icons.svg";
import Button from "components/Button";
import Typography from "components/Typography";
import { getUser } from "shared/features/user";
import { getMemberId } from "shared/state/ui/member/selectors";
import ButtonBase from "components/Button/ButtonBase";
import CircularProgress from "components/Progress/CircularProgress";
import { replaceSpecialCharacters } from "features/healthRecords/utils";
import useMobileCheck from "hooks/useMobileCheck";
import { screenReaderSpeak } from "shared/utils/screenReaderSpeak";
import Badge from "components/Badge";
import {
  DocumentObjectDetails,
  DocumentObjectDetailsFromJSON,
} from "shared/fetch/src/models/DocumentObjectDetails";
import { uploadUserDocument } from "shared/api/src/apis/DocumentsApi";

interface IProps {
  onUpload?: (file: File, userId: number) => Promise<void>;
  onAttachFile: (file: File, result: DocumentObjectDetails) => void;
  onAttachingFile?: (loading: { loading: boolean }) => void;
  ariaDescriptionId?: string;
  ariaLabel?: string;
  onUploadError?: Function;
  multiple?: boolean;
  isInlineMessaging?: boolean;
  hasCtmInlineMessaging?: boolean;
  count?: number;
  isInsuranceCard?: boolean;
  buttonText?: string;
}
const acceptedMimeTypes = [
  "image/gif",
  "image/png",
  "image/jpeg",
  "image/svg+xml",
  "application/zip",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.apple.pages",
  "application/vnd.apple.keynote",
  "application/vnd.apple.numbers",
  "application/x-iwork-keynote-sffkey",
  "application/x-iwork-numbers-sffnumbers",
  "application/x-iwork-pages-sffpages",
];

const insuranceAccepedMimeTypes = [
  "image/png",
  "image/jpeg",
  "application/pdf",
];

export const acceptedMimeTypesString = acceptedMimeTypes.toString();
const insuranceAcceptedMimeTypesString = insuranceAccepedMimeTypes.toString();

export const uploadAttachment = (file: File, userId: number) => {
  const upload = uploadUserDocument({
    id: userId.toString(),
    file,
  });

  return mutateAsync({ ...upload, queryKey: "attachFile" });
};

const useStyles = makeStyles(() =>
  createStyles({
    badgeContent: {
      height: "14px",
      width: "14px",
      transform: "scale(1) translate(50%, -140%)",
      minWidth: "14px",
      fontSize: "11px",
      padding: "6px 6px",
    },
    arrowIcon: {
      height: "17px",
    },
  })
);

const AttachFile: FunctionComponent<IProps> = ({
  onUpload,
  onAttachingFile,
  ariaDescriptionId,
  ariaLabel,
  multiple = true,
  isInlineMessaging = false,
  hasCtmInlineMessaging = false,
  count,
  isInsuranceCard,
  buttonText,
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const user = useSelector(getUser);
  const userId = user?.id;
  const memberId = useSelector(getMemberId);
  const isMobile = useMobileCheck();

  let text: string = "";

  if (multiple) {
    text = "files";
  } else {
    text = "file";
  }

  const buttonAriaLabel =
    ariaLabel || loading ? `Attaching ${text}...` : `Attach ${text}`;

  const classes = useStyles();

  const handleClickInput = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleRename = (document: File) => {
    const originalFile = new File([document as BlobPart], document.name, {
      type: document.type,
    });
    const ext = document.name.split(".").pop();
    const fileName = document.name.slice(0, document.name.lastIndexOf("."));
    const cleanFileName = replaceSpecialCharacters(fileName);
    const fullFileName = `${cleanFileName}.${ext?.toLowerCase()}`;
    return new File([originalFile as BlobPart], fullFileName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  };

  const handleAttachFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    screenReaderSpeak("Button unavailable attaching files", "polite");
    const numOfFiles = e.target.files?.length || 0;
    if (numOfFiles > 0 && typeof onUpload === "function") {
      setLoading(true);
      onAttachingFile && onAttachingFile({ loading: true });
      Array.from(e.target.files!).forEach((file, index) => {
        if (isMobile) {
          handleUpload(file, index, numOfFiles);
        } else {
          onUpload(file, Number(memberId || userId))
            .then(() => {
              if (index + 1 === numOfFiles && fileInputRef.current) {
                setLoading(false);
                buttonRef.current?.focus();
                onAttachingFile && onAttachingFile({ loading: false });
                fileInputRef.current.value = "";
              }
              screenReaderSpeak("File Attached", "polite");
            })
            .catch(() => {
              setLoading(false);
              buttonRef.current?.focus();
              onAttachingFile && onAttachingFile({ loading: false });
            });
        }
      });
    }
  };

  const handleUpload = async (
    file: File,
    index: number,
    numOfFiles: number
  ) => {
    const renamedFile = await handleRename(file);
    if (typeof onUpload === "function") {
      onUpload(renamedFile, Number(memberId || userId))
        .then(() => {
          if (index + 1 === numOfFiles && fileInputRef.current) {
            setLoading(false);
            buttonRef.current?.focus();
            onAttachingFile && onAttachingFile({ loading: false });
            fileInputRef.current.value = "";
          }
        })
        .catch(() => {
          setLoading(false);
          buttonRef.current?.focus();
          onAttachingFile && onAttachingFile({ loading: false });
        });
    }
  };

  const getButtonText = () => {
    let buttonLabel;

    if (isInsuranceCard) {
      if (loading) {
        buttonLabel = `Attaching ${text}....`;
      } else {
        buttonLabel = `${buttonText} `;
      }
    } else {
      if (loading) {
        buttonLabel = `Attaching ${text}....`;
      } else {
        buttonLabel = `Attach ${text}`;
      }
    }
    return buttonLabel;
  };

  return (
    <>
      <input
        id="attach-file-input"
        ref={fileInputRef}
        style={{ display: "none" }}
        type="file"
        onChange={handleAttachFile}
        data-e2e="attach-file-button"
        accept={
          isInsuranceCard
            ? insuranceAcceptedMimeTypesString
            : acceptedMimeTypesString
        }
        disabled={loading}
        multiple={multiple}
      />

      <label htmlFor="attach-file-input">
        {isInlineMessaging ? (
          <>
            <ButtonBase
              disabled={loading}
              title="Attach files"
              aria-describedby={ariaDescriptionId}
              style={{ padding: "0px" }}
              onClick={handleClickInput}
              aria-label={buttonAriaLabel}
            >
              <svg
                style={{
                  height: "22px",
                  width: "18px",
                  display: "inline-block",
                  fill: "#606A76",
                }}
              >
                <use xlinkHref={`${Icons}#icon-paperclip`} />
              </svg>
              {hasCtmInlineMessaging && !!count && (
                <Badge
                  color="primary"
                  badgeContent={count}
                  data-e2e={`attachments-count-badge`}
                  classes={{ badge: classes.badgeContent }}
                />
              )}
            </ButtonBase>

            <span style={{ paddingLeft: "8px " }}>
              {loading && <CircularProgress size={16} />}
            </span>
          </>
        ) : (
          <Button
            ref={buttonRef}
            disabled={loading}
            color="link-primary"
            title="Attach files"
            aria-describedby={ariaDescriptionId}
            style={{ padding: "0px" }}
            onClick={handleClickInput}
            aria-label={buttonAriaLabel}
            aria-live="polite"
          >
            <Typography appearance="smallBody">{getButtonText()}</Typography>
            {!isInsuranceCard ? (
              <svg
                style={{
                  height: 44,
                  width: 22,
                  display: "inline-block",
                  fill: "currentColor",
                  textAlign: "center",
                }}
              >
                <use xlinkHref={`${Icons}#icon-paperclip`} />
              </svg>
            ) : null}
          </Button>
        )}
      </label>
    </>
  );
};

const mapDispatchToProps = (dispatch: any, props: IProps) => ({
  onUpload: (file: File, userId: number) => {
    // makes the API call to attach the file
    // the "attachFile" query key is associated with the request by uploadAttachment()
    return dispatch(uploadAttachment(file, userId)).then(
      (result: { status: number; body: any }) => {
        const responseBody = DocumentObjectDetailsFromJSON(result.body);
        if ("error" in result.body) {
          props.onUploadError?.("attachFile");
        }
        if (result.status === 200) {
          // if API call succeeds the onAttachFile prop from parent component is called
          props.onAttachFile(file, responseBody);
        }
      }
    );
  },
});

// @ts-ignore
export default compose<React.FunctionComponent<IProps>>(
  connect(null, mapDispatchToProps)
)(AttachFile);
