// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     EpisodeOutputCoreAllOf,
    EpisodeOutputCoreAllOfFromJSON,
    EpisodeOutputCoreAllOfToJSON,
} from './EpisodeOutputCoreAllOf';
import {
     EpisodeSummaryCoreOutputMarketingMessage,
    EpisodeSummaryCoreOutputMarketingMessageFromJSON,
    EpisodeSummaryCoreOutputMarketingMessageToJSON,
} from './EpisodeSummaryCoreOutputMarketingMessage';
import {
     EpisodeSummaryCoreOutputXopPod,
    EpisodeSummaryCoreOutputXopPodFromJSON,
    EpisodeSummaryCoreOutputXopPodToJSON,
} from './EpisodeSummaryCoreOutputXopPod';
import {
     EpisodeWithActionableItemsObjectOutput,
    EpisodeWithActionableItemsObjectOutputFromJSON,
    EpisodeWithActionableItemsObjectOutputToJSON,
} from './EpisodeWithActionableItemsObjectOutput';
import {
     HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';
/**
 * @type EpisodeOutputCore
 * @export
 */
export interface EpisodeOutputCore extends EpisodeOutputCoreAllOf, EpisodeWithActionableItemsObjectOutput {
}

export function EpisodeOutputCoreFromJSON(json: any): EpisodeOutputCore {
    return {
        ...EpisodeOutputCoreAllOfFromJSON(json),
        ...EpisodeWithActionableItemsObjectOutputFromJSON(json),
    };
}

export function EpisodeOutputCoreToJSON(value?: EpisodeOutputCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...EpisodeOutputCoreAllOfToJSON(value),
        ...EpisodeWithActionableItemsObjectOutputToJSON(value),
    };
}

