import React from "react";
import { useOutletContext } from "react-router";
import { useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { setSelectedCenter } from "shared/state/ui/careAccess/actions";
import { MemberClinic } from "shared/fetch/src/models/MemberClinic";
import useFetchCenters from "features/careAccess/utils/useFetchCenters";

import CareAccessContent from "features/careAccess/components/CareAccessContent";
import CareAccessCareTile from "features/careAccess/components/CareAccessCareTile";
import CentersVisitsSkeleton from "features/careAccess/pages/CareAccessSkeletons/CentersVisitsSkeleton";
import Heading from "components/DynamicHeadings";

import IOutletContext from "features/careAccess/utils/OutletContextInterface";
import useMobileCheck from "hooks/useMobileCheck";

const useStyles = makeStyles(({ palette }) => ({
  coreTeamContainer: {
    marginTop: 16,
  },
  extendedTeamsContainer: {
    marginTop: 32,
  },
  extendedTeamsTiles: {
    display: "flex",
    gap: 12,
    flexWrap: "wrap",
  },
  flexColumn: {
    flexDirection: "column",
  },
  teamHeading: {
    color: palette.text.secondary,
  },
}));

const CentersPage: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMobileCheck();
  const dispatch = useDispatch();
  const { goToNextPage } = useOutletContext<IOutletContext>();
  const { allClinics, isPending } = useFetchCenters();
  const coreClinic = allClinics?.find((clinic) => clinic.coreCareTeam);
  const otherClinics = allClinics.filter((clinic) => !clinic.coreCareTeam);

  const setCenterAndGoToNextPage = (clinic: MemberClinic) => () => {
    dispatch(
      setSelectedCenter({
        centerId: clinic.clinicId || "",
        centerName: clinic.name || "",
      })
    );
    goToNextPage("visits")();
  };

  if (isPending) {
    return (
      <CareAccessContent>
        <CentersVisitsSkeleton />
      </CareAccessContent>
    );
  }

  return (
    <CareAccessContent>
      {!!coreClinic && (
        <div className={classes.coreTeamContainer}>
          <Heading.LevelBoundary levelOverride={2}>
            <Heading.H
              color="textSecondary"
              appearance="smallBody"
              style={{ marginBottom: "5px" }}
            >
              YOUR CARE TEAM
            </Heading.H>
          </Heading.LevelBoundary>
          <CareAccessCareTile
            assetName={
              coreClinic.virtual ? "care-nvt-center" : "care-in-person-center"
            }
            title={coreClinic.name || ""}
            description={
              coreClinic.virtual ? "Virtual visits only" : coreClinic.address
            }
            handleClick={setCenterAndGoToNextPage(coreClinic)}
          />
        </div>
      )}

      {!!otherClinics.length && (
        <div className={classes.extendedTeamsContainer}>
          <Heading.LevelBoundary levelOverride={2}>
            <Heading.H
              color="textSecondary"
              appearance="smallBody"
              style={{ marginBottom: "5px" }}
            >
              EXTENDED TEAMS
            </Heading.H>
          </Heading.LevelBoundary>
          <div
            className={clsx(
              classes.extendedTeamsTiles,
              isMobile && classes.flexColumn
            )}
          >
            {otherClinics.map((clinic, i) => {
              return (
                <CareAccessCareTile
                  key={i}
                  assetName={
                    clinic.virtual ? "care-nvt-center" : "care-in-person-center"
                  }
                  title={clinic.name || ""}
                  description={
                    clinic.virtual ? "Virtual visits only" : clinic.address
                  }
                  handleClick={setCenterAndGoToNextPage(clinic)}
                />
              );
            })}
          </div>
        </div>
      )}
    </CareAccessContent>
  );
};

export default CentersPage;
