// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ClinicConfigFetchResponseBase,
    ClinicConfigFetchResponseBaseFromJSON,
    ClinicConfigFetchResponseBaseToJSON,
} from './ClinicConfigFetchResponseBase';
import {
     ClinicConfigSchema,
    ClinicConfigSchemaFromJSON,
    ClinicConfigSchemaToJSON,
} from './ClinicConfigSchema';
import {
     PaginationProps,
    PaginationPropsFromJSON,
    PaginationPropsToJSON,
} from './PaginationProps';
/**
 * @type ClinicConfigFetchResponse
 * @export
 */
export interface ClinicConfigFetchResponse extends ClinicConfigFetchResponseBase, PaginationProps {
}

export function ClinicConfigFetchResponseFromJSON(json: any): ClinicConfigFetchResponse {
    return {
        ...ClinicConfigFetchResponseBaseFromJSON(json),
        ...PaginationPropsFromJSON(json),
    };
}

export function ClinicConfigFetchResponseToJSON(value?: ClinicConfigFetchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ClinicConfigFetchResponseBaseToJSON(value),
        ...PaginationPropsToJSON(value),
    };
}

