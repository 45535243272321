import { useQuery } from "react-query";
import {
  GetNextAvailableSlotsRequest,
  fetchSlotsApi,
} from "shared/fetch/src/apis/SlotsApi";
import useFormatDate from "shared/utils/useFormatDate";

export const nextAvailableSlotsQueryKey = (
  requestParameters: GetNextAvailableSlotsRequest
) => ["memberNextAvailableSlots", requestParameters];

const useGetNextAvailableSlots = (
  requestParameters: GetNextAvailableSlotsRequest
) => {
  const { data } = useQuery(
    nextAvailableSlotsQueryKey(requestParameters),
    () => {
      return fetchSlotsApi.getNextAvailableSlots(requestParameters);
    },
    {
      enabled: !!requestParameters.timezone,
    }
  );

  const formatDate = useFormatDate();
  const formattedNextDateWithSlots =
    data?.results[0]?.startAt &&
    formatDate(data.results[0].startAt, "EEE MMM d", {
      tz: requestParameters.timezone,
    });
  const nextAvailableLabel = `Next available: ${formattedNextDateWithSlots}`;
  return { slots: data, nextAvailableLabel };
};

export default useGetNextAvailableSlots;
