// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClinicConfigSchemaReexaminationWindows
 */
export interface ClinicConfigSchemaReexaminationWindows  {
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchemaReexaminationWindows
     */
    appointmentType?: string;
    /**
     * 
     * @type {number}
     * @memberof ClinicConfigSchemaReexaminationWindows
     */
    weeks?: number;
}

export function ClinicConfigSchemaReexaminationWindowsFromJSON(json: any): ClinicConfigSchemaReexaminationWindows {
    return {
        'appointmentType': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'weeks': !exists(json, 'weeks') ? undefined : json['weeks'],
    };
}

export function ClinicConfigSchemaReexaminationWindowsToJSON(value?: ClinicConfigSchemaReexaminationWindows): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointment_type': value.appointmentType,
        'weeks': value.weeks,
    };
}


