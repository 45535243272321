import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

import { getUser } from "shared/features/user";
import { useGetActiveEpisodes } from "shared/state/activeEpisodes/useGetActiveEpisodes";
import { getPreventiveEpisode } from "shared/state/activeEpisodes";
import { useFetchConversations } from "shared/state/conversations/useFetchConversations";
import useFetchVisits from "shared/features/visits/useFetchVisits";
import { getFeatures } from "shared/features/featureFlags/selectors";
import { MemberConversationListSummariesStateEnum } from "shared/fetch/src/apis/EpisodeListsApi";
import { MemberAppointmentListSummariesStatusEnum } from "shared/fetch/src/apis/MemberAppointmentsApi";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

import Skeleton from "components/Skeleton";
import PreventiveEoc from "components/PreventiveEoc";
import Container from "components/Container";

import { useStyles } from "pages/LandingPage/styles";
import DependentAccessDashboard from "pages/LandingPage/DependentAccessDashboard";
import Header from "pages/LandingPage/Header";
import ActiveConversations from "pages/LandingPage/ActiveConversations";
import EmptyEOC from "pages/LandingPage/EmptyEOC";
import LandingPageEmptyComponent from "pages/LandingPage/LandingPageEmptyComponent";
import Visits from "pages/LandingPage/Visits";
import CareAccessHeader from "pages/LandingPage/CareAccessHeader";

import { useNativeRouteUpdate } from "components/NativeBackButton";
import theme from "styles/mui";
import useAccess from "hooks/useAccess";
import ConversationsLayout from "./ConversationsLayout";
import InsuranceOnboardingTool from "../../components/InsuranceOnboardingTool";

export const LandingPage: FunctionComponent<{}> = (): JSX.Element => {
  const classes = useStyles();
  const user = useSelector(getUser);
  const isScreenSmallerOrWider = useMediaQuery(theme.breakpoints.up("sm"));
  useNativeRouteUpdate({ pathName: "dashboard" });
  const hasDashboardEpisodes = useAccess({
    to: PermissionsEnum.DashboardEpisodesRead,
  });

  const { conversations, isPending: isConversationPending } =
    useFetchConversations({
      id: String(user?.id),
      state: MemberConversationListSummariesStateEnum.Active,
      pageSize: "5",
    });

  const { visitCount, isPending: isVisitsPending }: any = useFetchVisits(
    String(user?.id),
    {
      pageSize: 5,
      status: MemberAppointmentListSummariesStatusEnum.Upcoming,
      relevant: false,
    }
  );

  useGetActiveEpisodes();

  const preventiveEpisode = useSelector(getPreventiveEpisode);
  const featureFlags = useSelector(getFeatures);

  const isPending = isConversationPending || isVisitsPending;

  const activeConversations = conversations?.totals?.active ?? 0;
  const archivedConversations = conversations?.totals?.archived ?? 0;

  // @ts-ignore: Object is possibly 'null'.
  const hasCreatedEoc = conversations?.totals?.all > 0;
  const hasAffinity = featureFlags.hasAffinity();

  const hasNoVisits =
    featureFlags.hasVisitSeparation() && (visitCount?.upcoming ?? 0) === 0;

  const preventiveCare = (
    <div className={classes.preventiveCare}>
      <PreventiveEoc
        preventiveEpisode={preventiveEpisode}
        hasCreateEpisodeLink={false}
      />
    </div>
  );

  const renderInsuranceOnboardingTool = (): JSX.Element | null => {
    return <InsuranceOnboardingTool />;
  };

  const renderConversations = (): JSX.Element | null => {
    if (activeConversations > 0) {
      return (
        <ActiveConversations
          totalActiveConversations={activeConversations}
          conversations={conversations}
          user={user}
          upcomingVisits={visitCount?.upcoming}
        />
      );
    }
    return null;
  };

  const renderEmptyActivity = (): JSX.Element | null => {
    if (activeConversations === 0 && hasNoVisits) {
      return (
        <LandingPageEmptyComponent
          title="ACTIVITY"
          assetName="NoConvosOrVisits"
          hasArchivedConversations={false}
          message="You have no active Conversations or upcoming visits."
        />
      );
    }

    return null;
  };

  /**
   * The 'There are no active conversations' banner should only be displayed when:
   * 1. There are no active conversations
   * 2. There are no upcoming visits
   * 3. The hasVisitSeparation feature flag is off
   */
  const renderNoActiveEpisodes = (): JSX.Element | null => {
    if (
      activeConversations === 0 &&
      (visitCount?.upcoming ?? 0) === 0 &&
      !featureFlags.hasVisitSeparation()
    ) {
      return (
        <LandingPageEmptyComponent
          title={`ACTIVE CONVERSATIONS (${activeConversations})`}
          hasArchivedConversations={!!archivedConversations}
          message="There are no active Conversations."
        />
      );
    }

    return null;
  };

  const renderVisits = (): JSX.Element | null => {
    if ((visitCount?.upcoming ?? 0) > 0) {
      return (
        <Visits
          // @ts-ignore: Object is possibly 'null'.
          id={user?.id}
        />
      );
    }
    return null;
  };

  const renderDashboardLayout = () => {
    return (
      <Container maxWidth="xl" className={classes.container}>
        {hasVisitsOrActiveConversations && (
          <ConversationsLayout>
            {renderInsuranceOnboardingTool()}
            {renderVisits()}
            {renderConversations()}
          </ConversationsLayout>
        )}
        {renderEmptyActivity()}
        {renderNoActiveEpisodes()}
        {preventiveCare}
      </Container>
    );
  };

  const hasVisitsOrActiveConversations =
    visitCount?.upcoming > 0 || activeConversations > 0;

  if (isPending) {
    return <Skeleton appearance="page" />;
  }

  if (featureFlags.hasCareAccessMenus()) {
    return (
      <section data-testid="dashboard-with-conversations">
        <CareAccessHeader />

        {!user?.minor ? (
          <>{renderDashboardLayout()}</>
        ) : (
          <Container maxWidth="xl" className={classes.container}>
            <ConversationsLayout isMinorLayout>
              <Visits
                // @ts-ignore: Object is possibly 'null'.
                id={user?.id}
              />
            </ConversationsLayout>
          </Container>
        )}
      </section>
    );
  }

  if (!hasDashboardEpisodes) {
    return <DependentAccessDashboard />;
  }

  return (
    <section data-testid="dashboard-with-conversations">
      {featureFlags.hasSelfSchedule() || hasCreatedEoc ? (
        <>
          <Header
            hasAffinity={hasAffinity}
            isScreenSmallerOrWider={isScreenSmallerOrWider}
            totalActiveConversations={activeConversations}
            hasCreatedEoc={hasCreatedEoc}
            user={user}
          />
          {renderDashboardLayout()}
        </>
      ) : (
        <EmptyEOC
          totalActiveConversations={activeConversations}
          preventiveCare={preventiveCare}
        />
      )}
    </section>
  );
};

export default LandingPage;
