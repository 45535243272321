import React from "react";
import { useOutletContext } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { State } from "@types";
import {
  setService,
  setCareType,
  setHasSkippedDecisionsPage,
  resetMessageData,
} from "shared/state/ui/careAccess/actions";
import { Steps } from "shared/state/ui/careAccess/steps";
import { MemberServiceLinesSchema } from "shared/fetch/src/models/MemberServiceLinesSchema";
import useGetMemberServiceLines from "shared/features/careAccess/useGetMemberServiceLines";
import ServicesLocation from "features/careAccess/components/ServicesLocation";
import CareAccessContent from "features/careAccess/components/CareAccessContent";
import GeneralQuestions from "features/careAccess/components/GeneralQuestions";
import CareAccessCareTile from "features/careAccess/components/CareAccessCareTile";
import ServicesEmptyState from "features/careAccess/components/EmptyStateComponents/ServicesEmptyState";
import ServicesSkeleton from "features/careAccess/pages/CareAccessSkeletons/ServicesSkeleton";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";
import { selectLoggedInUser } from "shared/features/user/selectors";

const useStyles = makeStyles({
  serviceTilesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    marginTop: 16,
    maxWidth: "900px",
    "@media (max-width: 767px)": {
      gap: "10px 2%",
      marginTop: 8,
      maxWidth: "100%",
    },
  },
  wrapper: {
    maxWidth: "785px",
  },
});

const ServicesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = selectLoggedInUser();
  const { goToNextPage, memberId, clinicContact } =
    useOutletContext<IOutletContext>();
  const { locationState } = useSelector((state: State) => state.ui.careAccess);
  const { memberServiceLines, isLoading } = useGetMemberServiceLines({
    id: memberId || "",
    usState: locationState.code,
  });

  const setServiceLineAndNavigate = (service: MemberServiceLinesSchema) => {
    if (
      user?.minor ||
      (!service?.hasMessaging && !!service?.visitMethods?.length)
    ) {
      dispatch(setService(service?.id || ""));
      dispatch(setCareType("by_visit"));
      goToNextPage(Steps.CENTERS)();
    } else if (service?.hasMessaging && !!service?.visitMethods?.length) {
      dispatch(setService(service?.id || ""));
      goToNextPage(Steps.DECISIONS)();
    } else if (service?.hasMessaging && !service?.visitMethods?.length) {
      dispatch(setService(service?.id || ""));
      dispatch(setCareType("by_message"));
      dispatch(resetMessageData());
      dispatch(setHasSkippedDecisionsPage(true));
      goToNextPage(Steps.MESSAGE)();
    }
  };

  if (isLoading) {
    return (
      <CareAccessContent>
        <div className={classes.wrapper}>
          <ServicesSkeleton />
        </div>
      </CareAccessContent>
    );
  }

  return (
    <CareAccessContent>
      <ServicesLocation />
      {!memberServiceLines.length ? (
        <div className={classes.wrapper}>
          <ServicesEmptyState />
        </div>
      ) : (
        <>
          <div className={classes.serviceTilesContainer}>
            {memberServiceLines?.map((service: MemberServiceLinesSchema) => (
              <CareAccessCareTile
                key={service.id}
                assetName={service.assetName!}
                title={service.serviceType!}
                description={service.careDescription!}
                handleClick={() => setServiceLineAndNavigate(service || {})}
                isService
              />
            ))}
          </div>
          <GeneralQuestions
            isMinor={user?.minor || false}
            memberId={memberId!}
            clinicContact={clinicContact || ""}
          />
        </>
      )}
    </CareAccessContent>
  );
};

export default ServicesPage;
