// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OnlineAppointmentSchema,
    OnlineAppointmentSchemaFromJSON,
    OnlineAppointmentSchemaToJSON,
} from '../models/OnlineAppointmentSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetAppointmentTypesRequest {
    memberId?: number;
    method?: GetAppointmentTypesMethodEnum;
    schedulingType?: GetAppointmentTypesSchedulingTypeEnum;
    clinicId?: string;
}


/**
 * Retrieve all online appointment types
 * Retrieve all online appointment types
 */
function getAppointmentTypesRaw<T>(requestParameters: GetAppointmentTypesRequest, requestConfig: runtime.TypedQueryConfig<T, OnlineAppointmentSchema> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.memberId !== undefined) {
        queryParameters['member_id'] = requestParameters.memberId;
    }


    if (requestParameters.method !== undefined) {
        queryParameters['method'] = requestParameters.method;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/appointment_types`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OnlineAppointmentSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve all online appointment types
* Retrieve all online appointment types
*/
export function getAppointmentTypes<T>(requestParameters: GetAppointmentTypesRequest, requestConfig?: runtime.TypedQueryConfig<T, OnlineAppointmentSchema>): QueryConfig<T> {
    return getAppointmentTypesRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetAppointmentTypesMethodEnum {
    InPerson = 'in_person',
    Virtual = 'virtual'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAppointmentTypesSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
