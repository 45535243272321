import React, { FunctionComponent, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme, Drawer, IconButton, useTheme } from "@mui/material";
import Typography from "components/Typography";
import Box from "components/Box";
import Button from "components/Button";
import Divider from "components/Divider";
import CloseIcon from "@mui/icons-material/Close";
import useFormatDate from "shared/utils/useFormatDate";
import { NextAvailableSlotsFetchResponse } from "shared/fetch/src/models/NextAvailableSlotsFetchResponse";
import CircularProgress from "components/Progress/CircularProgress";
import Avatar from "components/Avatar";
import RadioGroup from "components/Radio/RadioGroup";
import Radio from "components/Radio";
import FormControlLabel from "components/Form/FormControlLabel";
import useMobileCheck from "hooks/useMobileCheck";
import EocBadge from "components/EocBadge";
interface INextAvailableDrawerProps {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
  nextAvailableSlots: NextAvailableSlotsFetchResponse | undefined;
  selectedTimezone: string;
  appointmentName: string;
  onAppointmentSlotSelected: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  drawerSlot: {
    marginLeft: "-10px",
    marginTop: "10px",
    background: "#F2F6F8",
    padding: "10px 5px 10px 5px",
    borderRadius: "5px",
  },
  drawerPaper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  groupIconMenuItem: {
    marginRight: "9px",
    marginLeft: "5px",
    width: "1.2em",
    height: "1.2em",
    color: theme.palette.text.secondary,
    marginTop: "0",
    marginBottom: "auto",
  },
  avatar: {
    marginRight: "9px",
  },
  clinics: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "250px",
    marginTop: "8px",
  },
  wrapNameAndRole: {
    whiteSpace: "normal",
    maxWidth: "250px",
    color: "707A84",
  },
  divider: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: "5px",
    marginRight: "5px",
  },
  visitDate: {
    display: "flex",
    marginLeft: "5px",
    alignItems: "center",
  },
  visitDateIcon: {
    borderRadius: "3px",
    textAlign: "center",
    background: "#4C5A73",
    color: "white",
    paddingTop: "2px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingBottom: "3px",
    display: "flex",
    flexDirection: "column",
  },
  cancel: {
    textDecoration: "underline",
    color: theme.palette.appBackground?.blue,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  radio: {
    marginRight: "-5px",
    marginTop: "-10px",
  },
  close: { marginRight: theme.spacing(-2) },
  title: { marginLeft: theme.spacing(-2) },
  spinner: {
    color: theme.palette.appBackground?.blue,
  },
  hidden: {
    visibility: "hidden",
  },
  chooseButton: {
    width: "100%",
  },
  badge: {
    marginTop: "4px",
  },
}));

const NextAvailableDrawer: FunctionComponent<INextAvailableDrawerProps> = ({
  isDrawerOpen,
  onDrawerClose,
  nextAvailableSlots,
  selectedTimezone,
  appointmentName,
  onAppointmentSlotSelected,
}) => {
  const classes = useStyles();
  const formatDate = useFormatDate();
  const [selectedSlotId, setSelectedSlotId] = useState<number | null>(null);
  const isMobile = useMobileCheck();
  const anchor = isMobile ? "bottom" : "right";
  const drawerWidth = isMobile ? "100%" : "425px";
  const { palette } = useTheme<Theme>();

  const oneNextAvailableSlot = nextAvailableSlots?.results.length === 1;
  const singleNextAvailableId = nextAvailableSlots?.results[0]?.id;

  useEffect(() => {
    if (!selectedSlotId && oneNextAvailableSlot) {
      setSelectedSlotId(singleNextAvailableId ?? null);
    }
  }, [selectedSlotId, oneNextAvailableSlot, singleNextAvailableId]);

  const handleChooseVisit = () => {
    if (selectedSlotId !== null) {
      const selectedSlot = nextAvailableSlots?.results.find(
        (slot) => slot.id === selectedSlotId
      );
      if (selectedSlot) {
        onAppointmentSlotSelected(selectedSlotId, selectedSlot);
      }
    }
  };

  const disableChooseVisit = () => {
    return oneNextAvailableSlot ? false : !selectedSlotId;
  };

  const getBorderColor = (id: number) => {
    const { secondary, strokes } = palette;
    return id === selectedSlotId ? secondary.main : strokes?.divider;
  };

  return (
    <>
      <Drawer
        data-e2e="get-care-drawer"
        anchor={anchor}
        open={isDrawerOpen}
        onClose={onDrawerClose}
        PaperProps={{
          style: { width: drawerWidth, height: isMobile ? "100%" : undefined },
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={1}
        >
          <Typography
            className={classes.title}
            appearance="body"
            fontWeightBold
            id="preferred-slot-radio-group-label"
          >
            Next available visit
          </Typography>
          <IconButton className={classes.close} onClick={onDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {nextAvailableSlots && (
          <RadioGroup
            name="preferredSlot"
            aria-labelledby="preferred-slot-radio-group-label"
            value={selectedSlotId ? selectedSlotId : ""}
          >
            {nextAvailableSlots?.results.map((slot: any, index: any) => (
              <div
                key={index}
                style={{ border: `1px solid ${getBorderColor(slot.id)}` }}
                className={classes.drawerSlot}
              >
                <div style={{ display: "flex" }}>
                  <Avatar
                    containerStyle={{ margin: 0 }}
                    displayText={slot.provider?.name}
                    src={slot.provider?.avatarUrl}
                    size="medium"
                    isPresentational
                  />
                  <div style={{ marginLeft: "10px", flex: 1 }}>
                    <Box style={{ maxWidth: "200px" }}>
                      <Typography
                        appearance="smallBody"
                        color="textPrimary"
                        className={classes.wrapNameAndRole}
                        fontWeightBold
                      >
                        {slot.provider?.name}
                      </Typography>
                    </Box>
                    <Box style={{ width: "100%", display: "flex" }}>
                      <Typography
                        className={classes.wrapNameAndRole}
                        appearance="smallBody"
                        color="textSecondary"
                        style={{ marginRight: 4 }}
                      >
                        {slot.provider?.guilds?.[0]}
                      </Typography>
                      {slot.provider?.isPcp && (
                        <EocBadge
                          className={classes.badge}
                          badge={{
                            abbreviation: "PCP",
                            color: palette?.white,
                            backgroundColor: palette?.appBackground?.navy || "",
                          }}
                        />
                      )}
                    </Box>
                    <Typography
                      color="textSecondary"
                      appearance="caption"
                      className={classes.clinics}
                    >
                      {appointmentName}, {slot?.clinicName}
                    </Typography>
                  </div>
                  <div>
                    <FormControlLabel
                      className={classes.radio}
                      onChange={() => setSelectedSlotId(slot.id)}
                      checked={selectedSlotId === slot.id}
                      value={slot.id}
                      control={<Radio />}
                      role={"radio"}
                      aria-label={`Visit with ${
                        slot.provider?.name
                      } at ${formatDate(
                        slot?.startAt,
                        "EEEE, MMM d, @ h:mmaaa zzz",
                        {
                          tz: selectedTimezone,
                        }
                      )}`}
                      label={""}
                    />
                  </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.visitDate}>
                  <div className={classes.visitDateIcon}>
                    <Typography color="white" style={{ fontSize: "x-small" }}>
                      {formatDate(slot?.startAt, "MMM", {
                        tz: selectedTimezone,
                      })
                        .toString()
                        .toUpperCase()}
                    </Typography>
                    <Typography appearance="smallBody" color="white">
                      {formatDate(slot?.startAt, "d", { tz: selectedTimezone })}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      appearance="smallBody"
                      color="textPrimary"
                      style={{ marginLeft: "10px", fontWeight: "bold" }}
                    >
                      {" "}
                      {formatDate(slot?.startAt, "EEEE, MMM d, @ h:mmaaa zzz", {
                        tz: selectedTimezone,
                      })}{" "}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </RadioGroup>
        )}
        {!nextAvailableSlots && (
          <CircularProgress className={classes.spinner} />
        )}
        <div>
          <Box marginTop={4} marginBottom={7} marginLeft={-2}>
            <Button
              size="medium"
              color="primary"
              onClick={handleChooseVisit}
              disabled={disableChooseVisit()}
              className={classes.chooseButton}
            >
              Choose Visit
            </Button>

            <Button onClick={onDrawerClose} className={classes.cancel}>
              Cancel
            </Button>
          </Box>
        </div>
      </Drawer>
    </>
  );
};

export default NextAvailableDrawer;
