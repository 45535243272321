import React, { FunctionComponent } from "react";
import RadioGroupComponent, {
  RadioGroupProps,
} from "@mui/material/RadioGroup";
import { RequireAriaLabelOrAriaLabelledBy } from "components/types"


const RadioGroup: FunctionComponent<RadioGroupProps & RequireAriaLabelOrAriaLabelledBy> = (props) => {
  return <RadioGroupComponent {...props} />;
};

export default RadioGroup;
