import React from "react";
import Box from "components/Box";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import Typography from "components/Typography";
import CalendarWarning from "components/Asset";
import { ClassNameMap } from "@mui/styles";
interface IProps {
  nextAvailableText?: string;
  classes?: ClassNameMap;
  onDateSelected: any;
}

const NoAvailableSlots = ({
  nextAvailableText,
  classes,
  onDateSelected,
}: IProps) => {
  return (
    <Box className={classes?.noAvailableSlotsContainer}>
      <CalendarWarning
        className={classes?.calendarIcon}
        name="calendar-warning"
        alt="no-slots-available"
      />
      <Typography className={classes?.linkText}>
        Next available visit with provider is
      </Typography>
      <ButtonStyledAsLink
        ariaLabel={`schedule an appointment on ${nextAvailableText}`}
        color="#2B3949"
        onClick={onDateSelected}
      >
        {nextAvailableText}{" "}
      </ButtonStyledAsLink>
    </Box>
  );
};

export default NoAvailableSlots;
