import React from "react";
import { useSelector } from "react-redux";

import HealthRecordsHotLinks from "components/HealthRecordsHotLinks";
import PendingPaymentsBalance from "components/LandingPage/PendingPaymentsBalance";
import QuickLinks from "components/QuickLinks";

import { getFeatures } from "shared/features/featureFlags/selectors";
import { getUser } from "shared/features/user";
import { useGetPaymentsHistory } from "shared/features/payments/paymentsHistory";
import { GetPaymentHistoryStatusEnum } from "shared/fetch/src/apis/MemberPaymentsApi";
import TertiaryCTA from "components/LandingPage/TertiaryCTA/TertiaryCTA";

export const LandingPageHealthRecordsHotLinks = () => {
  const featureFlags = useSelector(getFeatures);
  const hasDocuments = featureFlags.hasHealthProfileDocs();
  const hasConsents = featureFlags.hasHealthProfileConsents();
  const hasCareAccess = featureFlags.hasCareAccessMenus();

  return (
    <>
      {hasCareAccess ? (
        <QuickLinks />
      ) : (
        <HealthRecordsHotLinks
          hasTestResults
          hasDocs={hasDocuments}
          hasConsents={hasConsents}
        />
      )}
    </>
  );
};

export const LandingPagePendingPaymentsBalance = () => {
  const user = useSelector(getUser);
  const { unpaidItemsCount } = useGetPaymentsHistory({
    id: String(user?.id),
    status: GetPaymentHistoryStatusEnum.Unpaid,
  });
  const hasPendingBalance = unpaidItemsCount && unpaidItemsCount > 0;

  return (
    <React.Fragment>
      {hasPendingBalance ? (
        <PendingPaymentsBalance count={unpaidItemsCount ?? 0} />
      ) : null}
    </React.Fragment>
  );
};

export const LandingPageTertiaryCTA = () => {
  const user = useSelector(getUser);
  const featureFlags = useSelector(getFeatures);
  const isUserEligible = user?.status !== "Ineligible";
  const hasAffinity = featureFlags.hasAffinity();

  if (
    !isUserEligible ||
    !hasAffinity ||
    !user?.affinity ||
    user?.affinity === "nearsite"
  ) {
    return null;
  }

  return <TertiaryCTA user={user} />;
};
