/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GreenPlusPatientRequestBody,
    GreenPlusPatientRequestBodyFromJSON,
    GreenPlusPatientRequestBodyToJSON,
} from '../models/GreenPlusPatientRequestBody'
import {
    GreenPlusResponseBody,
    GreenPlusResponseBodyFromJSON,
    GreenPlusResponseBodyToJSON,
} from '../models/GreenPlusResponseBody'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserToJSON,
} from '../models/JsonUser'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OutboundScreeningObjectDetail,
    OutboundScreeningObjectDetailFromJSON,
    OutboundScreeningObjectDetailToJSON,
} from '../models/OutboundScreeningObjectDetail'
import {
    OutboundScreeningsDetailResponse,
    OutboundScreeningsDetailResponseFromJSON,
    OutboundScreeningsDetailResponseToJSON,
} from '../models/OutboundScreeningsDetailResponse'
import {
    OutboundScreeningsListResponse,
    OutboundScreeningsListResponseFromJSON,
    OutboundScreeningsListResponseToJSON,
} from '../models/OutboundScreeningsListResponse'
import {
    PatientAdminRequestBody,
    PatientAdminRequestBodyFromJSON,
    PatientAdminRequestBodyToJSON,
} from '../models/PatientAdminRequestBody'
import {
    PatientInsuranceObject,
    PatientInsuranceObjectFromJSON,
    PatientInsuranceObjectToJSON,
} from '../models/PatientInsuranceObject'
import {
    PatientProfileInput,
    PatientProfileInputFromJSON,
    PatientProfileInputToJSON,
} from '../models/PatientProfileInput'
import {
    PatientProfileOutput,
    PatientProfileOutputFromJSON,
    PatientProfileOutputToJSON,
} from '../models/PatientProfileOutput'
import {
    PatientRequestBody,
    PatientRequestBodyFromJSON,
    PatientRequestBodyToJSON,
} from '../models/PatientRequestBody'
import {
    PatientScreening,
    PatientScreeningFromJSON,
    PatientScreeningToJSON,
} from '../models/PatientScreening'


export interface AddNewPatientRequest {
    patientRequestBody?: PatientRequestBody;
}
export interface CompleteOutboundScreeningRequest {
    id: string;
    outboundScreeningId: string;
}
export interface ContinueOutboundScreeningRequest {
    id: string;
    outboundScreeningId: string;
}
export interface FetchPatientAllergiesRequest {
    id: string;
}
export interface FetchPatientBenefitInfoRequest {
    id: string;
}
export interface FetchUserOutboundScreeningsRequest {
    id: string;
}
export interface FetchUserOutboundScreeningsByIdRequest {
    id: string;
    outboundScreeningId: string;
}
export interface UpdatePatientProfileByIdRequest {
    id: string;
    patientProfileInput?: PatientProfileInput;
}
export interface V1PatientAdminPostRequest {
    patientAdminRequestBody?: PatientAdminRequestBody;
}
export interface V1PatientIdUpdateCoveragePostRequest {
    id: string;
}
export interface V1PatientPostRequest {
    greenPlusPatientRequestBody?: GreenPlusPatientRequestBody;
}
export interface V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest {
    id: string;
    outboundScreeningId: string;
    patientScreeningId: string;
}


export interface IPatientAccountApi {
      addNewPatient: (requestParameters: AddNewPatientRequest) => Promise<JsonUser>
      completeOutboundScreening: (requestParameters: CompleteOutboundScreeningRequest) => Promise<OutboundScreeningsDetailResponse>
      continueOutboundScreening: (requestParameters: ContinueOutboundScreeningRequest) => Promise<OutboundScreeningsDetailResponse>
      fetchPatientAllergies: (requestParameters: FetchPatientAllergiesRequest) => Promise<Array<object>>
      fetchPatientBenefitInfo: (requestParameters: FetchPatientBenefitInfoRequest) => Promise<PatientInsuranceObject>
      fetchUserOutboundScreenings: (requestParameters: FetchUserOutboundScreeningsRequest) => Promise<OutboundScreeningsListResponse>
      fetchUserOutboundScreeningsById: (requestParameters: FetchUserOutboundScreeningsByIdRequest) => Promise<OutboundScreeningObjectDetail>
      updatePatientProfileById: (requestParameters: UpdatePatientProfileByIdRequest) => Promise<PatientProfileOutput>
      v1PatientAdminPost: (requestParameters: V1PatientAdminPostRequest) => Promise<void>
      v1PatientIdUpdateCoveragePost: (requestParameters: V1PatientIdUpdateCoveragePostRequest) => Promise<void>
      v1PatientPost: (requestParameters: V1PatientPostRequest) => Promise<GreenPlusResponseBody>
      v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet: (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest) => Promise<PatientScreening>
      addNewPatientWithResponse: (requestParameters: AddNewPatientRequest) => Promise<{ value: JsonUser, response: any}>
      completeOutboundScreeningWithResponse: (requestParameters: CompleteOutboundScreeningRequest) => Promise<{ value: OutboundScreeningsDetailResponse, response: any}>
      continueOutboundScreeningWithResponse: (requestParameters: ContinueOutboundScreeningRequest) => Promise<{ value: OutboundScreeningsDetailResponse, response: any}>
      fetchPatientAllergiesWithResponse: (requestParameters: FetchPatientAllergiesRequest) => Promise<{ value: Array<object>, response: any}>
      fetchPatientBenefitInfoWithResponse: (requestParameters: FetchPatientBenefitInfoRequest) => Promise<{ value: PatientInsuranceObject, response: any}>
      fetchUserOutboundScreeningsWithResponse: (requestParameters: FetchUserOutboundScreeningsRequest) => Promise<{ value: OutboundScreeningsListResponse, response: any}>
      fetchUserOutboundScreeningsByIdWithResponse: (requestParameters: FetchUserOutboundScreeningsByIdRequest) => Promise<{ value: OutboundScreeningObjectDetail, response: any}>
      updatePatientProfileByIdWithResponse: (requestParameters: UpdatePatientProfileByIdRequest) => Promise<{ value: PatientProfileOutput, response: any}>
      v1PatientAdminPostWithResponse: (requestParameters: V1PatientAdminPostRequest) => Promise<{ value: void, response: any}>
      v1PatientIdUpdateCoveragePostWithResponse: (requestParameters: V1PatientIdUpdateCoveragePostRequest) => Promise<{ value: void, response: any}>
      v1PatientPostWithResponse: (requestParameters: V1PatientPostRequest) => Promise<{ value: GreenPlusResponseBody, response: any}>
      v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetWithResponse: (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest) => Promise<{ value: PatientScreening, response: any}>
}

/**
 * no description
 */
export class PatientAccountApi extends runtime.BaseAPI {

    /**
     * Forcefully add a new patient to the system
     */
    addNewPatientRaw = async (requestParameters: AddNewPatientRequest): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/force`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientRequestBodyToJSON(requestParameters.patientRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Forcefully add a new patient to the system
     */
    addNewPatient = async (requestParameters: AddNewPatientRequest): Promise<JsonUser> => {
        const { runtime } = await this.addNewPatientRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Forcefully add a new patient to the system
     */
    addNewPatientWithResponse = async (requestParameters: AddNewPatientRequest): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.addNewPatientRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Completes OutboundScreening for a user ID
     */
    completeOutboundScreeningRaw = async (requestParameters: CompleteOutboundScreeningRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningsDetailResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeOutboundScreening.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling completeOutboundScreening.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningsDetailResponseFromJSON(jsonValue)), response };
    }

    /**
     * Completes OutboundScreening for a user ID
     */
    completeOutboundScreening = async (requestParameters: CompleteOutboundScreeningRequest): Promise<OutboundScreeningsDetailResponse> => {
        const { runtime } = await this.completeOutboundScreeningRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Completes OutboundScreening for a user ID
     */
    completeOutboundScreeningWithResponse = async (requestParameters: CompleteOutboundScreeningRequest): Promise<{ value: OutboundScreeningsDetailResponse, response: any}> => {
        const { runtime, response } = await this.completeOutboundScreeningRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Updates a patient screening  for a user ID and outbound_screening_id
     */
    continueOutboundScreeningRaw = async (requestParameters: ContinueOutboundScreeningRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningsDetailResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling continueOutboundScreening.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling continueOutboundScreening.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}/screen`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningsDetailResponseFromJSON(jsonValue)), response };
    }

    /**
     * Updates a patient screening  for a user ID and outbound_screening_id
     */
    continueOutboundScreening = async (requestParameters: ContinueOutboundScreeningRequest): Promise<OutboundScreeningsDetailResponse> => {
        const { runtime } = await this.continueOutboundScreeningRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Updates a patient screening  for a user ID and outbound_screening_id
     */
    continueOutboundScreeningWithResponse = async (requestParameters: ContinueOutboundScreeningRequest): Promise<{ value: OutboundScreeningsDetailResponse, response: any}> => {
        const { runtime, response } = await this.continueOutboundScreeningRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch patient\'s list of allergies
     */
    fetchPatientAllergiesRaw = async (requestParameters: FetchPatientAllergiesRequest): Promise<{ runtime: runtime.ApiResponse<Array<object>>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchPatientAllergies.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient/{id}/allergies`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse<any>(response), response };
    }

    /**
     * Fetch patient\'s list of allergies
     */
    fetchPatientAllergies = async (requestParameters: FetchPatientAllergiesRequest): Promise<Array<object>> => {
        const { runtime } = await this.fetchPatientAllergiesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch patient\'s list of allergies
     */
    fetchPatientAllergiesWithResponse = async (requestParameters: FetchPatientAllergiesRequest): Promise<{ value: Array<object>, response: any}> => {
        const { runtime, response } = await this.fetchPatientAllergiesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch patient benefit information
     */
    fetchPatientBenefitInfoRaw = async (requestParameters: FetchPatientBenefitInfoRequest): Promise<{ runtime: runtime.ApiResponse<PatientInsuranceObject>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchPatientBenefitInfo.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/benefits`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PatientInsuranceObjectFromJSON(jsonValue)), response };
    }

    /**
     * Fetch patient benefit information
     */
    fetchPatientBenefitInfo = async (requestParameters: FetchPatientBenefitInfoRequest): Promise<PatientInsuranceObject> => {
        const { runtime } = await this.fetchPatientBenefitInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch patient benefit information
     */
    fetchPatientBenefitInfoWithResponse = async (requestParameters: FetchPatientBenefitInfoRequest): Promise<{ value: PatientInsuranceObject, response: any}> => {
        const { runtime, response } = await this.fetchPatientBenefitInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsRaw = async (requestParameters: FetchUserOutboundScreeningsRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningsListResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserOutboundScreenings.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningsListResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreenings = async (requestParameters: FetchUserOutboundScreeningsRequest): Promise<OutboundScreeningsListResponse> => {
        const { runtime } = await this.fetchUserOutboundScreeningsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsWithResponse = async (requestParameters: FetchUserOutboundScreeningsRequest): Promise<{ value: OutboundScreeningsListResponse, response: any}> => {
        const { runtime, response } = await this.fetchUserOutboundScreeningsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsByIdRaw = async (requestParameters: FetchUserOutboundScreeningsByIdRequest): Promise<{ runtime: runtime.ApiResponse<OutboundScreeningObjectDetail>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserOutboundScreeningsById.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling fetchUserOutboundScreeningsById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutboundScreeningObjectDetailFromJSON(jsonValue)), response };
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsById = async (requestParameters: FetchUserOutboundScreeningsByIdRequest): Promise<OutboundScreeningObjectDetail> => {
        const { runtime } = await this.fetchUserOutboundScreeningsByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch OutboundScreenings for a user ID
     */
    fetchUserOutboundScreeningsByIdWithResponse = async (requestParameters: FetchUserOutboundScreeningsByIdRequest): Promise<{ value: OutboundScreeningObjectDetail, response: any}> => {
        const { runtime, response } = await this.fetchUserOutboundScreeningsByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update patient profile by id
     */
    updatePatientProfileByIdRaw = async (requestParameters: UpdatePatientProfileByIdRequest): Promise<{ runtime: runtime.ApiResponse<PatientProfileOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePatientProfileById.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatientProfileInputToJSON(requestParameters.patientProfileInput),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PatientProfileOutputFromJSON(jsonValue)), response };
    }

    /**
     * Update patient profile by id
     */
    updatePatientProfileById = async (requestParameters: UpdatePatientProfileByIdRequest): Promise<PatientProfileOutput> => {
        const { runtime } = await this.updatePatientProfileByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update patient profile by id
     */
    updatePatientProfileByIdWithResponse = async (requestParameters: UpdatePatientProfileByIdRequest): Promise<{ value: PatientProfileOutput, response: any}> => {
        const { runtime, response } = await this.updatePatientProfileByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Trigger mail actions for a patient as an admin
     */
    v1PatientAdminPostRaw = async (requestParameters: V1PatientAdminPostRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientAdminRequestBodyToJSON(requestParameters.patientAdminRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Trigger mail actions for a patient as an admin
     */
    v1PatientAdminPost = async (requestParameters: V1PatientAdminPostRequest): Promise<void> => {
        const { runtime } = await this.v1PatientAdminPostRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Trigger mail actions for a patient as an admin
     */
    v1PatientAdminPostWithResponse = async (requestParameters: V1PatientAdminPostRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.v1PatientAdminPostRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Refresh patient\'s insurance coverage
     */
    v1PatientIdUpdateCoveragePostRaw = async (requestParameters: V1PatientIdUpdateCoveragePostRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1PatientIdUpdateCoveragePost.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient/{id}/update-coverage`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Refresh patient\'s insurance coverage
     */
    v1PatientIdUpdateCoveragePost = async (requestParameters: V1PatientIdUpdateCoveragePostRequest): Promise<void> => {
        const { runtime } = await this.v1PatientIdUpdateCoveragePostRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Refresh patient\'s insurance coverage
     */
    v1PatientIdUpdateCoveragePostWithResponse = async (requestParameters: V1PatientIdUpdateCoveragePostRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.v1PatientIdUpdateCoveragePostRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Add new patient through green + workflow
     */
    v1PatientPostRaw = async (requestParameters: V1PatientPostRequest): Promise<{ runtime: runtime.ApiResponse<GreenPlusResponseBody>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GreenPlusPatientRequestBodyToJSON(requestParameters.greenPlusPatientRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => GreenPlusResponseBodyFromJSON(jsonValue)), response };
    }

    /**
     * Add new patient through green + workflow
     */
    v1PatientPost = async (requestParameters: V1PatientPostRequest): Promise<GreenPlusResponseBody> => {
        const { runtime } = await this.v1PatientPostRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Add new patient through green + workflow
     */
    v1PatientPostWithResponse = async (requestParameters: V1PatientPostRequest): Promise<{ value: GreenPlusResponseBody, response: any}> => {
        const { runtime, response } = await this.v1PatientPostRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch a patient_screening by id
     */
    v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRaw = async (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest): Promise<{ runtime: runtime.ApiResponse<PatientScreening>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet.');
        }

                        if (requestParameters.outboundScreeningId === null || requestParameters.outboundScreeningId === undefined) {
            throw new runtime.RequiredError('outboundScreeningId','Required parameter requestParameters.outboundScreeningId was null or undefined when calling v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet.');
        }

                        if (requestParameters.patientScreeningId === null || requestParameters.patientScreeningId === undefined) {
            throw new runtime.RequiredError('patientScreeningId','Required parameter requestParameters.patientScreeningId was null or undefined when calling v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/patients/{id}/outbound_screenings/{outbound_screening_id}/screenings/{patient_screening_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"outbound_screening_id"}}`, encodeURIComponent(String(requestParameters.outboundScreeningId))).replace(`{${"patient_screening_id"}}`, encodeURIComponent(String(requestParameters.patientScreeningId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PatientScreeningFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a patient_screening by id
     */
    v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGet = async (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest): Promise<PatientScreening> => {
        const { runtime } = await this.v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a patient_screening by id
     */
    v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetWithResponse = async (requestParameters: V1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRequest): Promise<{ value: PatientScreening, response: any}> => {
        const { runtime, response } = await this.v1PatientsIdOutboundScreeningsOutboundScreeningIdScreeningsPatientScreeningIdGetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPatientAccountApi: IPatientAccountApi  = new PatientAccountApi();
