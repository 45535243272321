import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { State } from "shared/types/state";
import { getEpisodeMessageRecipientRequest } from "./queryConfigs";
import { GetEpisodeMessageRecipientsRequest } from "shared/api/src/apis/MessageRecipientsApi";

export default (requestParameters: GetEpisodeMessageRecipientsRequest) => {
  const memberRecipients = useSelector(
    (state: State) =>
      state.entities.memberMessageRecipients?.[requestParameters.id]
  );
  const config = requestParameters.id
    ? getEpisodeMessageRecipientRequest(requestParameters)
    : null;
  const [queryState] = useRequest(config);

  const hasDefaultRecipientIncluded = memberRecipients?.recipients?.find(
    (recipient) => recipient.id === memberRecipients?.defaultRecipient?.id
  );
  const hasDefaultCareTeamIncluded =
    memberRecipients?.careTeam?.id === memberRecipients?.defaultCareTeam?.id;

  if (memberRecipients?.defaultRecipient && !hasDefaultRecipientIncluded) {
    memberRecipients?.recipients?.push(memberRecipients?.defaultRecipient);
  }
  if (memberRecipients?.defaultCareTeam && !hasDefaultCareTeamIncluded) {
    memberRecipients.careTeam = memberRecipients?.defaultCareTeam;
  }

  return {
    memberRecipients,
    queryState,
  };
};
