import { ScheduleAppointmentOnSlotRequest, scheduleAppointmentOnSlot } from "shared/api/src/apis/AppointmentsApi";
import { GetBookableSlotsForComponentRequest, getBookableSlotsForComponent } from "shared/api/src/apis/ComponentsApi";
import { RescheduleAppointmentOfComponentByIDRequest, rescheduleAppointmentOfComponentByID } from "shared/api/src/apis/RescheduleComponentAppointmentApi";
import { ComponentDetailsOutput } from "shared/fetch/src/models/ComponentDetailsOutput";
import { TimeSlotsFetchResponse } from "shared/fetch/src/models/TimeSlotsFetchResponse";


export const getBookableSlotsForComponentRequest = (
  searchQuery: GetBookableSlotsForComponentRequest
) => {
  return getBookableSlotsForComponent(searchQuery, {
    transform: (body: TimeSlotsFetchResponse) => ({
      slots: body,
    }),
    update: {
      slots: (prev: TimeSlotsFetchResponse, next: TimeSlotsFetchResponse) => ({
        ...prev,
        ...next,
        ...{
          results:
            searchQuery.page === "1"
              ? [...next.results]
              : [
                  ...prev.results.map((prevSlot, index) => {
                    prevSlot.slots = prevSlot?.slots?.concat(
                      next?.results[index].slots!
                    );
                    return prevSlot;
                  }),
                ],
        },
      }),
    },
    force: true,
  });
};
export const scheduleAppointmentOnSlotMutation = (
  appointmentInfo: ScheduleAppointmentOnSlotRequest
) =>
  scheduleAppointmentOnSlot(appointmentInfo, {
    transform: (body: ComponentDetailsOutput) => ({
      component: { [body.id]: body },
    }),
    update: {
      component: (
        prev: { [key: string]: ComponentDetailsOutput },
        next: { [key: string]: ComponentDetailsOutput }
      ) => ({ ...prev, ...next }),
    },
    force: true,
  });

export const reScheduleAppointmentMutation = (
  request: RescheduleAppointmentOfComponentByIDRequest
) =>
  rescheduleAppointmentOfComponentByID(request, {
    transform: (body: ComponentDetailsOutput) => ({
      component: { [body.id]: body },
    }),
    update: {
      component: (
        prev: { [key: string]: ComponentDetailsOutput },
        next: { [key: string]: ComponentDetailsOutput }
      ) => ({ ...prev, ...next }),
    },
  });
