// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AdHocQuestionDetails,
    AdHocQuestionDetailsFromJSON,
    AdHocQuestionDetailsToJSON,
} from '../models/AdHocQuestionDetails'
import {
    AdHocQuestionRequestBody,
    AdHocQuestionRequestBodyFromJSON,
    AdHocQuestionRequestBodyToJSON,
} from '../models/AdHocQuestionRequestBody'
import {
    CMSDetailsItemSchema,
    CMSDetailsItemSchemaFromJSON,
    CMSDetailsItemSchemaToJSON,
} from '../models/CMSDetailsItemSchema'
import {
    CMSListSchema,
    CMSListSchemaFromJSON,
    CMSListSchemaToJSON,
} from '../models/CMSListSchema'
import {
    CmsNavigationSchema,
    CmsNavigationSchemaFromJSON,
    CmsNavigationSchemaToJSON,
} from '../models/CmsNavigationSchema'
import {
    CmsServiceLineItem,
    CmsServiceLineItemFromJSON,
    CmsServiceLineItemToJSON,
} from '../models/CmsServiceLineItem'
import {
    CommonQuestionDetailsItem,
    CommonQuestionDetailsItemFromJSON,
    CommonQuestionDetailsItemToJSON,
} from '../models/CommonQuestionDetailsItem'
import {
    CommonQuestionEditRequestBody,
    CommonQuestionEditRequestBodyFromJSON,
    CommonQuestionEditRequestBodyToJSON,
} from '../models/CommonQuestionEditRequestBody'
import {
    CommonQuestionsSchema,
    CommonQuestionsSchemaFromJSON,
    CommonQuestionsSchemaToJSON,
} from '../models/CommonQuestionsSchema'
import {
    ContentTypeSchema,
    ContentTypeSchemaFromJSON,
    ContentTypeSchemaToJSON,
} from '../models/ContentTypeSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    QuestionSetCategoriesSchema,
    QuestionSetCategoriesSchemaFromJSON,
    QuestionSetCategoriesSchemaToJSON,
} from '../models/QuestionSetCategoriesSchema'
import {
    QuestionSetDetailsSchema,
    QuestionSetDetailsSchemaFromJSON,
    QuestionSetDetailsSchemaToJSON,
} from '../models/QuestionSetDetailsSchema'
import {
    QuestionSetRequestBody,
    QuestionSetRequestBodyFromJSON,
    QuestionSetRequestBodyToJSON,
} from '../models/QuestionSetRequestBody'
import {
    RecentToolsList,
    RecentToolsListFromJSON,
    RecentToolsListToJSON,
} from '../models/RecentToolsList'
import {
    ServiceLinesSchema,
    ServiceLinesSchemaFromJSON,
    ServiceLinesSchemaToJSON,
} from '../models/ServiceLinesSchema'
import {
    ServiceUnavailableResponse,
    ServiceUnavailableResponseFromJSON,
    ServiceUnavailableResponseToJSON,
} from '../models/ServiceUnavailableResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface AddNewQuestionSetRequest {
    questionSetRequestBody?: QuestionSetRequestBody;
}

export interface CreateAdHocQuestionsRequest {
    adHocQuestionRequestBody?: AdHocQuestionRequestBody;
}

export interface EditCommonQuestionRequest {
    id: string;
    commonQuestionEditRequestBody?: CommonQuestionEditRequestBody;
}

export interface FetchCmsItemRequest {
    cmsContentType: string;
    cmsItemId: string;
}

export interface GetCMSListRequest {
    cmsContentType: string;
    q?: string;
    offset?: number;
    count?: number;
}

export interface GetCommonQuestionsRequest {
    q?: string;
}

export interface GetContentTypeListRequest {
    contentType: string;
    q?: string;
}

export interface GetQuestionSetRequest {
    id: string;
}

export interface GetServiceLineRequest {
    serviceLine: string;
}


/**
 * add a new unique question set
 */
function addNewQuestionSetRaw<T>(requestParameters: AddNewQuestionSetRequest, requestConfig: runtime.TypedQueryConfig<T, QuestionSetDetailsSchema> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/question_sets`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || QuestionSetRequestBodyToJSON(requestParameters.questionSetRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(QuestionSetDetailsSchemaFromJSON(body), text);
    }

    return config;
}

/**
* add a new unique question set
*/
export function addNewQuestionSet<T>(requestParameters: AddNewQuestionSetRequest, requestConfig?: runtime.TypedQueryConfig<T, QuestionSetDetailsSchema>): QueryConfig<T> {
    return addNewQuestionSetRaw(requestParameters, requestConfig);
}

/**
 * Create Ad-Hoc questions
 */
function createAdHocQuestionsRaw<T>(requestParameters: CreateAdHocQuestionsRequest, requestConfig: runtime.TypedQueryConfig<T, AdHocQuestionDetails> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/questions`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AdHocQuestionRequestBodyToJSON(requestParameters.adHocQuestionRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AdHocQuestionDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Create Ad-Hoc questions
*/
export function createAdHocQuestions<T>(requestParameters: CreateAdHocQuestionsRequest, requestConfig?: runtime.TypedQueryConfig<T, AdHocQuestionDetails>): QueryConfig<T> {
    return createAdHocQuestionsRaw(requestParameters, requestConfig);
}

/**
 * update the common question using id
 * update the common question using id
 */
function editCommonQuestionRaw<T>(requestParameters: EditCommonQuestionRequest, requestConfig: runtime.TypedQueryConfig<T, CommonQuestionDetailsItem> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editCommonQuestion.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/common_questions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || CommonQuestionEditRequestBodyToJSON(requestParameters.commonQuestionEditRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CommonQuestionDetailsItemFromJSON(body), text);
    }

    return config;
}

/**
* update the common question using id
* update the common question using id
*/
export function editCommonQuestion<T>(requestParameters: EditCommonQuestionRequest, requestConfig?: runtime.TypedQueryConfig<T, CommonQuestionDetailsItem>): QueryConfig<T> {
    return editCommonQuestionRaw(requestParameters, requestConfig);
}

/**
 * Fetch cms item by ID
 * Fetch cms item by ID
 */
function fetchCmsItemRaw<T>(requestParameters: FetchCmsItemRequest, requestConfig: runtime.TypedQueryConfig<T, CMSDetailsItemSchema> = {}): QueryConfig<T> {
    if (requestParameters.cmsContentType === null || requestParameters.cmsContentType === undefined) {
        throw new runtime.RequiredError('cmsContentType','Required parameter requestParameters.cmsContentType was null or undefined when calling fetchCmsItem.');
    }

    if (requestParameters.cmsItemId === null || requestParameters.cmsItemId === undefined) {
        throw new runtime.RequiredError('cmsItemId','Required parameter requestParameters.cmsItemId was null or undefined when calling fetchCmsItem.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/cms/{cms_content_type}/{cms_item_id}`.replace(`{${"cms_content_type"}}`, encodeURIComponent(String(requestParameters.cmsContentType))).replace(`{${"cms_item_id"}}`, encodeURIComponent(String(requestParameters.cmsItemId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CMSDetailsItemSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch cms item by ID
* Fetch cms item by ID
*/
export function fetchCmsItem<T>(requestParameters: FetchCmsItemRequest, requestConfig?: runtime.TypedQueryConfig<T, CMSDetailsItemSchema>): QueryConfig<T> {
    return fetchCmsItemRaw(requestParameters, requestConfig);
}

/**
 * Retrieves a list of a specified cms content type
 * Fetch a list of cms content type items
 */
function getCMSListRaw<T>(requestParameters: GetCMSListRequest, requestConfig: runtime.TypedQueryConfig<T, CMSListSchema> = {}): QueryConfig<T> {
    if (requestParameters.cmsContentType === null || requestParameters.cmsContentType === undefined) {
        throw new runtime.RequiredError('cmsContentType','Required parameter requestParameters.cmsContentType was null or undefined when calling getCMSList.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }


    if (requestParameters.offset !== undefined) {
        queryParameters['offset'] = requestParameters.offset;
    }


    if (requestParameters.count !== undefined) {
        queryParameters['count'] = requestParameters.count;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/{cms_content_type}`.replace(`{${"cms_content_type"}}`, encodeURIComponent(String(requestParameters.cmsContentType))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CMSListSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieves a list of a specified cms content type
* Fetch a list of cms content type items
*/
export function getCMSList<T>(requestParameters: GetCMSListRequest, requestConfig?: runtime.TypedQueryConfig<T, CMSListSchema>): QueryConfig<T> {
    return getCMSListRaw(requestParameters, requestConfig);
}

/**
 * Retrieve all navigation content
 * Fetch all navigation contents
 */
function getCmsMenuHierarchyRaw<T>( requestConfig: runtime.TypedQueryConfig<T, CmsNavigationSchema> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/menu`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CmsNavigationSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve all navigation content
* Fetch all navigation contents
*/
export function getCmsMenuHierarchy<T>( requestConfig?: runtime.TypedQueryConfig<T, CmsNavigationSchema>): QueryConfig<T> {
    return getCmsMenuHierarchyRaw( requestConfig);
}

/**
 * Retrieve all common questions
 * Fetch all common questions
 */
function getCommonQuestionsRaw<T>(requestParameters: GetCommonQuestionsRequest, requestConfig: runtime.TypedQueryConfig<T, CommonQuestionsSchema> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/common_questions`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CommonQuestionsSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve all common questions
* Fetch all common questions
*/
export function getCommonQuestions<T>(requestParameters: GetCommonQuestionsRequest, requestConfig?: runtime.TypedQueryConfig<T, CommonQuestionsSchema>): QueryConfig<T> {
    return getCommonQuestionsRaw(requestParameters, requestConfig);
}

/**
 * Retrieves a list of a specified content type
 * Fetch a list of content type items
 */
function getContentTypeListRaw<T>(requestParameters: GetContentTypeListRequest, requestConfig: runtime.TypedQueryConfig<T, ContentTypeSchema> = {}): QueryConfig<T> {
    if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
        throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling getContentTypeList.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.contentType !== undefined) {
        queryParameters['contentType'] = requestParameters.contentType;
    }


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/tools`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ContentTypeSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieves a list of a specified content type
* Fetch a list of content type items
*/
export function getContentTypeList<T>(requestParameters: GetContentTypeListRequest, requestConfig?: runtime.TypedQueryConfig<T, ContentTypeSchema>): QueryConfig<T> {
    return getContentTypeListRaw(requestParameters, requestConfig);
}

/**
 * Retrieve a single question set
 * Fetch specific question set
 */
function getQuestionSetRaw<T>(requestParameters: GetQuestionSetRequest, requestConfig: runtime.TypedQueryConfig<T, QuestionSetDetailsSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getQuestionSet.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(QuestionSetDetailsSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve a single question set
* Fetch specific question set
*/
export function getQuestionSet<T>(requestParameters: GetQuestionSetRequest, requestConfig?: runtime.TypedQueryConfig<T, QuestionSetDetailsSchema>): QueryConfig<T> {
    return getQuestionSetRaw(requestParameters, requestConfig);
}

/**
 * Retrieve the list of qs categories
 * Fetch the available qs categories
 */
function getQuestionSetCategoriesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, QuestionSetCategoriesSchema> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/qs_categories`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(QuestionSetCategoriesSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve the list of qs categories
* Fetch the available qs categories
*/
export function getQuestionSetCategories<T>( requestConfig?: runtime.TypedQueryConfig<T, QuestionSetCategoriesSchema>): QueryConfig<T> {
    return getQuestionSetCategoriesRaw( requestConfig);
}

/**
 * Get service line item by ID
 * Get service line item by ID
 */
function getServiceLineRaw<T>(requestParameters: GetServiceLineRequest, requestConfig: runtime.TypedQueryConfig<T, CmsServiceLineItem> = {}): QueryConfig<T> {
    if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
        throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling getServiceLine.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CmsServiceLineItemFromJSON(body), text);
    }

    return config;
}

/**
* Get service line item by ID
* Get service line item by ID
*/
export function getServiceLine<T>(requestParameters: GetServiceLineRequest, requestConfig?: runtime.TypedQueryConfig<T, CmsServiceLineItem>): QueryConfig<T> {
    return getServiceLineRaw(requestParameters, requestConfig);
}

/**
 * Retrieve the list of service lines
 * Fetch all the service lines
 */
function getServiceLinesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, ServiceLinesSchema> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/service_lines`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ServiceLinesSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve the list of service lines
* Fetch all the service lines
*/
export function getServiceLines<T>( requestConfig?: runtime.TypedQueryConfig<T, ServiceLinesSchema>): QueryConfig<T> {
    return getServiceLinesRaw( requestConfig);
}

/**
 * Retrieve all recent tools
 * Fetch all recent tools
 */
function listRecentToolsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, RecentToolsList> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/recents`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(RecentToolsListFromJSON(body), text);
    }

    return config;
}

/**
* Retrieve all recent tools
* Fetch all recent tools
*/
export function listRecentTools<T>( requestConfig?: runtime.TypedQueryConfig<T, RecentToolsList>): QueryConfig<T> {
    return listRecentToolsRaw( requestConfig);
}

