import React, { FunctionComponent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { State } from "@types";
import {
  setCurrentStep,
  setSelectedCenter,
  setVisitFilter,
} from "shared/state/ui/careAccess/actions";
import Asset from "components/Asset";
import Typography from "components/Typography";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    maxWidth: "313px",
    margin: "46px auto auto auto",
    textAlign: "center",
  },
  text: {
    color: palette?.links?.secondaryHover,
    marginTop: "19px",
  },
}));

interface IVisitEmptyStateProps {
  hasMultipleCenters: boolean;
}

const VisitEmptyState: FunctionComponent<IVisitEmptyStateProps> = ({
  hasMultipleCenters,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { memberId, isMinor, clinicContact } =
    useOutletContext<IOutletContext>();
  const { centers } = useSelector((state: State) => state.ui.careAccess);
  const centerLocation = centers?.centerName || "";
  const title =
    centers?.centerId === "0"
      ? `We currently do not have visits available`
      : `We currently do not have visits available at ${centerLocation}`;

  const handleClearFilters = () => {
    dispatch(
      setSelectedCenter({
        centerId: "0",
        centerName: "All Centers",
      })
    );
    dispatch(setVisitFilter("in_person"));
  };

  if (isMinor && hasMultipleCenters) {
    return (
      <div className={classes.wrapper}>
        <Asset name="care-empty-state" hiddenFromScreenReader />
        <Typography appearance="h5" color="textSecondary">
          {title}
        </Typography>
        <Typography appearance="body" className={classes.text}>
          <span>Change or </span>
          <ButtonStyledAsLink onClick={handleClearFilters}>
            clear your filters
          </ButtonStyledAsLink>
          <span>
            {" "}
            {`to see availability in all centers. You can also call your Care Team
            at ${clinicContact} to get assistance.`}
          </span>
        </Typography>
        <Typography appearance="body" className={classes.text}>
          If this is an emergency call 911.
        </Typography>
      </div>
    );
  }

  if (isMinor && !hasMultipleCenters) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.wrapper}>
          <Asset name="care-empty-state" hiddenFromScreenReader />
          <Typography appearance="h5" color="textSecondary">
            {title}
          </Typography>
          <Typography appearance="body" className={classes.text}>
            {`Call your Care Team at ${clinicContact} to get assistance.`}
          </Typography>
          <Typography appearance="body" className={classes.text}>
            If this is an emergency call 911.
          </Typography>
        </div>
      </div>
    );
  }

  if (!isMinor && hasMultipleCenters) {
    return (
      <div className={classes.wrapper}>
        <Asset name="care-empty-state" hiddenFromScreenReader />
        <Typography appearance="h5" color="textSecondary">
          {title}
        </Typography>
        <Typography appearance="body" className={classes.text}>
          <span>Change or </span>
          <ButtonStyledAsLink onClick={handleClearFilters}>
            clear your filters
          </ButtonStyledAsLink>
          <span> to see availability in all centers. You can also </span>
          <Link
            to={`/members/${memberId}/care-access/messages`}
            onClick={() => dispatch(setCurrentStep({ step: "messages" }))}
            data-testid="message-care-team"
          >
            message your Care Team
          </Link>
          <span> {`or call them at ${clinicContact} to get assistance.`}</span>
        </Typography>
        <Typography appearance="body" className={classes.text}>
          If this is an emergency call 911.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Asset name="care-empty-state" hiddenFromScreenReader />
      <Typography appearance="h5" color="textSecondary">
        {title}
      </Typography>
      <Typography appearance="body" className={classes.text}>
        <Link
          to={`/members/${memberId}/care-access/messages`}
          onClick={() => dispatch(setCurrentStep({ step: "messages" }))}
          data-testid="message-care-team"
        >
          Message your Care Team
        </Link>
        <span>{`, or call them at ${clinicContact} to get assistance.`}</span>
      </Typography>
      <Typography appearance="body" className={classes.text}>
        If this is an emergency call 911.
      </Typography>
    </div>
  );
};

export default VisitEmptyState;
