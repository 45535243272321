import React from "react";
import clsx from "clsx";
import DateCard from "./DateCard";
import Box from "components/Box";
import Typography from "components/Typography";
import { ClassNameMap } from "@mui/styles";
interface IProps {
  timeAndDate: string;
  labelWithWeekday?: string;
  classNames: ClassNameMap;
}
const VisitTimeAndDate = ({
  timeAndDate,
  labelWithWeekday,
  classNames,
}: IProps) => {
  const yearPosition = timeAndDate?.indexOf(",");
  const monthAndDay = timeAndDate?.slice(0, yearPosition)?.split(" ");
  const month = monthAndDay[0];
  const day = monthAndDay[1];

  return (
    <Box className={clsx(classNames.rowContainer, classNames.dateAndTimeRow)}>
      <Box className={classNames.avatarContainer}>
        <DateCard day={day} month={month} />
      </Box>
      <Box>
        <Typography className={classNames.bodyText} appearance="bodyEmphasized">
          {labelWithWeekday ? labelWithWeekday : timeAndDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default VisitTimeAndDate;
