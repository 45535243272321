// Returns phone number in format (111) 222-3333
const formatPhoneNumber = (input: string) => {
  const phoneNumber = input.replace(/-|\s|\.|\(|\)/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength <= 3) {
    return phoneNumber;
  }
  if (phoneNumberLength <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};

export default formatPhoneNumber;
