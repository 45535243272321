// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AnnotatedInputServiceMenuDocument,
    AnnotatedInputServiceMenuDocumentFromJSON,
    AnnotatedInputServiceMenuDocumentToJSON,
} from '../models/AnnotatedInputServiceMenuDocument'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InputServiceMenuDocumentSchema,
    InputServiceMenuDocumentSchemaFromJSON,
    InputServiceMenuDocumentSchemaToJSON,
} from '../models/InputServiceMenuDocumentSchema'
import {
    OutputServiceMenuDocumentSchema,
    OutputServiceMenuDocumentSchemaFromJSON,
    OutputServiceMenuDocumentSchemaToJSON,
} from '../models/OutputServiceMenuDocumentSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetServiceMenuRequest {
    id: string;
}

export interface PostServiceMenuRequest {
    inputServiceMenuDocumentSchema?: InputServiceMenuDocumentSchema;
}


/**
 * View service menu for member
 */
function getServiceMenuRaw<T>(requestParameters: GetServiceMenuRequest, requestConfig: runtime.TypedQueryConfig<T, OutputServiceMenuDocumentSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServiceMenu.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/user/{id}/service_menu`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OutputServiceMenuDocumentSchemaFromJSON(body), text);
    }

    return config;
}

/**
* View service menu for member
*/
export function getServiceMenu<T>(requestParameters: GetServiceMenuRequest, requestConfig?: runtime.TypedQueryConfig<T, OutputServiceMenuDocumentSchema>): QueryConfig<T> {
    return getServiceMenuRaw(requestParameters, requestConfig);
}

/**
 * View available service menus
 */
function getServiceMenusRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<AnnotatedInputServiceMenuDocument>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["service_menus.admin"]];
    const config: QueryConfig<T> = {
        url: `/v1/service_menus`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(AnnotatedInputServiceMenuDocumentFromJSON), text);
    }

    return config;
}

/**
* View available service menus
*/
export function getServiceMenus<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<AnnotatedInputServiceMenuDocument>>): QueryConfig<T> {
    return getServiceMenusRaw( requestConfig);
}

/**
 * Create a new service menu
 */
function postServiceMenuRaw<T>(requestParameters: PostServiceMenuRequest, requestConfig: runtime.TypedQueryConfig<T, InputServiceMenuDocumentSchema> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json;charset=utf8';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["service_menus.admin"]];
    const config: QueryConfig<T> = {
        url: `/v1/service_menus`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || InputServiceMenuDocumentSchemaToJSON(requestParameters.inputServiceMenuDocumentSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(InputServiceMenuDocumentSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Create a new service menu
*/
export function postServiceMenu<T>(requestParameters: PostServiceMenuRequest, requestConfig?: runtime.TypedQueryConfig<T, InputServiceMenuDocumentSchema>): QueryConfig<T> {
    return postServiceMenuRaw(requestParameters, requestConfig);
}

