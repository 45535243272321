import { fetchMemberMessageRecipients, getTimelineEventMessageRecipients, getEpisodeMessageRecipients, FetchMemberMessageRecipientsRequest, GetTimelineEventMessageRecipientsRequest, GetEpisodeMessageRecipientsRequest } from "shared/api/src/apis/MessageRecipientsApi";
import { MemberMessageRecipientsResponse } from "shared/fetch/src/models/MemberMessageRecipientsResponse";

export const fetchMemberRecipientsRequest = (
  requestParameters: FetchMemberMessageRecipientsRequest
) =>
  fetchMemberMessageRecipients(requestParameters, {
    transform: (body: MemberMessageRecipientsResponse) => ({
      memberMessageRecipients: {
        [requestParameters.id]: body,
      },
    }),
    update: {
      memberMessageRecipients: (
        prev: { [key: string]: MemberMessageRecipientsResponse },
        next: { [key: string]: MemberMessageRecipientsResponse }
      ) => next,
    },
    force: true,
    queryKey: "fetchMemberMessageRecipients",
  });

export const getTimelineEventMessageRequest = (
  requestParameters: GetTimelineEventMessageRecipientsRequest
) => {
  return getTimelineEventMessageRecipients(requestParameters, {
    transform: (body: MemberMessageRecipientsResponse) => ({
      memberMessageRecipients: {
        [requestParameters.id]: body,
      },
    }),
    update: {
      memberMessageRecipients: (
        prev: { [key: string]: MemberMessageRecipientsResponse },
        next: { [key: string]: MemberMessageRecipientsResponse }
      ) => {
        if (next) {
          return next;
        }
        return prev;
      },
    },
    force: true,
    queryKey: "timelineEventsMessageRecipients",
  });
};

export const getEpisodeMessageRecipientRequest = (
  requestParameters: GetEpisodeMessageRecipientsRequest
) =>
  getEpisodeMessageRecipients(requestParameters, {
    transform: (body: MemberMessageRecipientsResponse) => ({
      memberMessageRecipients: {
        [requestParameters.id]: body,
      },
    }),
    update: {
      memberMessageRecipients: (
        prev: { [key: string]: MemberMessageRecipientsResponse },
        next: { [key: string]: MemberMessageRecipientsResponse }
      ) => next,
    },
    force: true,
    queryKey: "getEpisodeMessageRecipients",
  });
