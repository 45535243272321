// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentTypeConfigSchema,
    AppointmentTypeConfigSchemaFromJSON,
    AppointmentTypeConfigSchemaToJSON,
} from './AppointmentTypeConfigSchema';


/**
 * 
 * @export
 * @interface AppointmentTypeConfigsResponseBase
 */
export interface AppointmentTypeConfigsResponseBase  {
    /**
     * 
     * @type {Array<AppointmentTypeConfigSchema>}
     * @memberof AppointmentTypeConfigsResponseBase
     */
    results: Array<AppointmentTypeConfigSchema>;
}

export function AppointmentTypeConfigsResponseBaseFromJSON(json: any): AppointmentTypeConfigsResponseBase {
    return {
        'results': (json['results'] as Array<any>).map(AppointmentTypeConfigSchemaFromJSON),
    };
}

export function AppointmentTypeConfigsResponseBaseToJSON(value?: AppointmentTypeConfigsResponseBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(AppointmentTypeConfigSchemaToJSON),
    };
}


