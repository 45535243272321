// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClinicConfigSchemaAnys
 */
export interface ClinicConfigSchemaAnys  {
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchemaAnys
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicConfigSchemaAnys
     */
    appointmentTypes?: Array<string>;
}

export function ClinicConfigSchemaAnysFromJSON(json: any): ClinicConfigSchemaAnys {
    return {
        'description': !exists(json, 'description') ? undefined : json['description'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
    };
}

export function ClinicConfigSchemaAnysToJSON(value?: ClinicConfigSchemaAnys): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'description': value.description,
        'appointment_types': value.appointmentTypes,
    };
}


