import React from "react";
import { useOutletContext } from "react-router";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {
  setCareType,
  resetMessageData,
} from "shared/state/ui/careAccess/actions";
import { Steps } from "shared/state/ui/careAccess/steps";
import CareAccessContent from "features/careAccess/components/CareAccessContent";
import CareAccessCareTile from "features/careAccess/components/CareAccessCareTile";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";
import useMobileCheck from "hooks/useMobileCheck";

const useStyles = makeStyles({
  careTilesContainer: {
    display: "flex",
    gap: 12,
    marginTop: 16,
  },
  flexColumn: {
    flexDirection: "column",
  },
});

const CareTypePage: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMobileCheck();
  const dispatch = useDispatch();
  const { goToNextPage } = useOutletContext<IOutletContext>();
  const visitTileDesc = `Schedule an appointment to see your provider at your convenience.`;
  const messageTileDesc = `Message your care team and they will get you started on the best action plan.`;

  const handleByVisitCareType = () => {
    dispatch(setCareType("by_visit"));
    goToNextPage(Steps.CENTERS)();
  };
  const handleByMessageCareType = () => {
    dispatch(setCareType("by_message"));
    dispatch(resetMessageData());
    goToNextPage(Steps.MESSAGE)();
  };

  return (
    <CareAccessContent>
      <div
        className={clsx(
          classes.careTilesContainer,
          isMobile && classes.flexColumn
        )}
      >
        <CareAccessCareTile
          assetName="care-calendar-check"
          title="By Visit"
          description={visitTileDesc}
          handleClick={handleByVisitCareType}
        />
        <CareAccessCareTile
          assetName="care-conversation-chat"
          title="By Message"
          description={messageTileDesc}
          handleClick={handleByMessageCareType}
        />
      </div>
    </CareAccessContent>
  );
};

export default CareTypePage;
