import React, { FunctionComponent, useMemo } from "react";
import clsx from "clsx";
import Box from "components/Box";
import Typography from "components/Typography";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import ErrorIcon from "@mui/icons-material/Error";

interface IProps {
  name: string;
  endDate?: string;
  clinic?: string;
  isCareAccess?: boolean;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      padding: 15,
      paddingLeft: 12,
      paddingRight: 27,
      backgroundColor: palette.yellowPastel,
      color: `${palette.text.primary}`,
      borderRadius: 4,
      alignItems: "flex-start",
      maxWidth: "60ch",
    },
    icon: {
      fill: palette.text.secondary,
    },
    careAccessContainer: {
      border: `1px solid ${palette.yellowHoverLight}`,
      padding: "12px 16px",
      width: "471px",
      marginBottom: "15px",
      "& svg": {
        fill: palette.yellowHoverLight,
      },
      "& p": {
        color: palette.yellowHoverLight,
        fontSize: "13.3px",
      },
    },
  })
);

export const OOONotification: FunctionComponent<IProps> = ({
  name,
  clinic,
  endDate,
  isCareAccess = false,
}) => {
  const classes = useStyles();
  const endDateFormat = useMemo(() => {
    if (endDate) {
      const splitEndDate = endDate.split("-");
      return !!splitEndDate
        ? [splitEndDate[1], splitEndDate[2], splitEndDate[0]]
        : [];
    }
    return [];
  }, [endDate]);

  if (!endDate) {
    return null;
  }

  return (
    <Box
      className={clsx(
        classes.container,
        isCareAccess && classes.careAccessContainer
      )}
      data-testId="ooo-notification-box"
    >
      <div>
        <ErrorIcon className={classes.icon} />
      </div>
      <Box marginLeft={1.5}>
        <Typography mb={2} appearance="smallBody">
          {name} will be out of office through {`${endDateFormat.join("/")}`}.
        </Typography>
        {isCareAccess ? (
          <Typography appearance="smallBody">
            If you need assistance before then, send your request to the team
            and someone from our staff will follow-up.
          </Typography>
        ) : (
          <Typography appearance="smallBody">
            If you need assistance before then, send your request to the{" "}
            {clinic && clinic} team and someone from our staff will follow-up.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default OOONotification;
