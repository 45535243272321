// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ClinicProviderConfigFetchResponseBase,
    ClinicProviderConfigFetchResponseBaseFromJSON,
    ClinicProviderConfigFetchResponseBaseToJSON,
} from './ClinicProviderConfigFetchResponseBase';
import {
     ClinicProviderConfigSchema,
    ClinicProviderConfigSchemaFromJSON,
    ClinicProviderConfigSchemaToJSON,
} from './ClinicProviderConfigSchema';
import {
     PaginationProps,
    PaginationPropsFromJSON,
    PaginationPropsToJSON,
} from './PaginationProps';
/**
 * @type ClinicProviderConfigFetchResponse
 * @export
 */
export interface ClinicProviderConfigFetchResponse extends ClinicProviderConfigFetchResponseBase, PaginationProps {
}

export function ClinicProviderConfigFetchResponseFromJSON(json: any): ClinicProviderConfigFetchResponse {
    return {
        ...ClinicProviderConfigFetchResponseBaseFromJSON(json),
        ...PaginationPropsFromJSON(json),
    };
}

export function ClinicProviderConfigFetchResponseToJSON(value?: ClinicProviderConfigFetchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ClinicProviderConfigFetchResponseBaseToJSON(value),
        ...PaginationPropsToJSON(value),
    };
}

