import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router";
import makeStyles from "@mui/styles/makeStyles";
import { State } from "@types";
import { Steps } from "shared/state/ui/careAccess/steps";
import {
  setAppointmentType,
  setVisitFilter,
} from "shared/state/ui/careAccess/actions";
import useGetMemberAppointmentTypes from "shared/features/careAccess/useGetMemberAppointmentTypes";
import useFetchCenters from "features/careAccess/utils/useFetchCenters";
import CareAccessContent from "features/careAccess/components/CareAccessContent";
import CareAccessCareTile from "features/careAccess/components/CareAccessCareTile";
import LookingForAnotherVisitType from "features/careAccess/components/LookingForAnotherVisitType";
import VisitEmptyState from "features/careAccess/components/EmptyStateComponents/VisitsEmptyState";
import VisitsFilterSection from "features/careAccess/components/VisitsFilterSection";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";
import CentersVisitsSkeleton from "features/careAccess/pages/CareAccessSkeletons/CentersVisitsSkeleton";

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    maxWidth: "785px",
    [breakpoints.down("md")]: {
      width: "unset",
    },
  },
  careVisitTiles: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    gap: "12px",
    marginTop: "10px",
    [breakpoints.down("md")]: {
      gridTemplateColumns: "auto",
    },
  },
}));

const VisitsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { goToNextPage, memberId, clinicContact, isMinor } =
    useOutletContext<IOutletContext>();
  const { visits, centers, services, locationState } = useSelector(
    (state: State) => state.ui.careAccess
  );
  const { allClinics } = useFetchCenters();
  const { appointmentTypes, isLoading } = useGetMemberAppointmentTypes({
    id: memberId || "",
    clinicId: centers.centerId !== "0" ? centers.centerId : undefined,
    serviceLineId: services.serviceLineId,
    usState: locationState.code,
  });

  const [filteredInPersonVisits, setFilteredInPersonVisits] = useState(
    appointmentTypes || []
  );
  const [filteredVirtualVisits, setFilteredVirtualVisits] = useState(
    appointmentTypes || []
  );
  const filteredVisits =
    visits.visitFilter === "in_person"
      ? filteredInPersonVisits
      : filteredVirtualVisits;

  useEffect(() => {
    const inPersonResults = appointmentTypes?.filter(
      (visit) => visit.method === "in_person"
    );
    setFilteredInPersonVisits(inPersonResults || []);

    const virtualResults = appointmentTypes?.filter(
      (visit) => visit.method === "video" || visit.method === "phone"
    );
    setFilteredVirtualVisits(virtualResults || []);
  }, [appointmentTypes]);

  useEffect(() => {
    if (!filteredInPersonVisits.length && !!filteredVirtualVisits.length) {
      dispatch(setVisitFilter("virtual"));
    } else {
      dispatch(setVisitFilter("in_person"));
    }
  }, [filteredInPersonVisits, filteredVirtualVisits]);

  const hasLookingForAnotherVisitType = filteredVisits?.length <= 2;
  const hasMultipleCenters = allClinics.length > 1;

  const handleVisitSelect = (visit: any) => {
    dispatch(setAppointmentType(visit?.id, visit?.name));
    goToNextPage(Steps.DATE_PICKER)();
  };

  if (isLoading) {
    return (
      <CareAccessContent>
        <div className={classes.wrapper}>
          <CentersVisitsSkeleton />
        </div>
      </CareAccessContent>
    );
  }

  return (
    <CareAccessContent>
      <div className={classes.wrapper}>
        <VisitsFilterSection
          hasMultipleCenters={hasMultipleCenters}
          allClinics={allClinics}
        />

        {!filteredVisits.length ? (
          <VisitEmptyState hasMultipleCenters={hasMultipleCenters} />
        ) : (
          <>
            <div className={classes.careVisitTiles}>
              {filteredVisits?.map((visit) => {
                return (
                  <div key={visit.id}>
                    <CareAccessCareTile
                      assetName={visit.iconName || ""}
                      title={visit.name || ""}
                      description={visit.careDescription || ""}
                      handleClick={() => handleVisitSelect(visit)}
                    />
                  </div>
                );
              })}
            </div>

            {hasLookingForAnotherVisitType && (
              <LookingForAnotherVisitType
                memberId={memberId || ""}
                isMinor={isMinor || false}
                hasClearFilterLink={hasMultipleCenters}
                clinicContact={clinicContact || ""}
              />
            )}
          </>
        )}
      </div>
    </CareAccessContent>
  );
};

export default VisitsPage;
