// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClinicConfigSchemaAnys,
    ClinicConfigSchemaAnysFromJSON,
    ClinicConfigSchemaAnysToJSON,
} from './ClinicConfigSchemaAnys';
import {
    ClinicConfigSchemaModalities,
    ClinicConfigSchemaModalitiesFromJSON,
    ClinicConfigSchemaModalitiesToJSON,
} from './ClinicConfigSchemaModalities';
import {
    ClinicConfigSchemaReexaminationWindows,
    ClinicConfigSchemaReexaminationWindowsFromJSON,
    ClinicConfigSchemaReexaminationWindowsToJSON,
} from './ClinicConfigSchemaReexaminationWindows';


/**
 * 
 * @export
 * @interface ClinicConfigSchema
 */
export interface ClinicConfigSchema  {
    /**
     * 
     * @type {number}
     * @memberof ClinicConfigSchema
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    clinicId: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    zone: string;
    /**
     * 
     * @type {Date}
     * @memberof ClinicConfigSchema
     */
    open?: Date;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    dial: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    scap: boolean;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    elationDefaultProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    elationPractice?: string;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    clinicType?: ClinicConfigSchemaClinicTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    enablePcpEnhancement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    enablePcpCareTeamEnhancement?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    hideLandingPageOfficeBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClinicConfigSchema
     */
    enablePros?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof ClinicConfigSchema
     */
    hoursOfOperation: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof ClinicConfigSchema
     */
    memberHoursOfOperation: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    wait?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClinicConfigSchema
     */
    zip?: Array<string>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaAnys>}
     * @memberof ClinicConfigSchema
     */
    anys?: Array<ClinicConfigSchemaAnys>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaModalities>}
     * @memberof ClinicConfigSchema
     */
    modalities?: Array<ClinicConfigSchemaModalities>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaReexaminationWindows>}
     * @memberof ClinicConfigSchema
     */
    reexaminationWindows?: Array<ClinicConfigSchemaReexaminationWindows>;
    /**
     * 
     * @type {Array<ClinicConfigSchemaReexaminationWindows>}
     * @memberof ClinicConfigSchema
     */
    bookingThrottles?: Array<ClinicConfigSchemaReexaminationWindows>;
    /**
     * 
     * @type {string}
     * @memberof ClinicConfigSchema
     */
    checkinPin?: string;
}

export function ClinicConfigSchemaFromJSON(json: any): ClinicConfigSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clinicId': json['clinic_id'],
        'name': json['name'],
        'code': json['code'],
        'zone': json['zone'],
        'open': !exists(json, 'open') ? undefined : new Date(json['open']),
        'dial': json['dial'],
        'scap': json['scap'],
        'state': json['state'],
        'elationDefaultProvider': !exists(json, 'elation_default_provider') ? undefined : json['elation_default_provider'],
        'elationPractice': !exists(json, 'elation_practice') ? undefined : json['elation_practice'],
        'clinicType': !exists(json, 'clinic_type') ? undefined : json['clinic_type'],
        'enablePcpEnhancement': !exists(json, 'enable_pcp_enhancement') ? undefined : json['enable_pcp_enhancement'],
        'enablePcpCareTeamEnhancement': !exists(json, 'enable_pcp_care_team_enhancement') ? undefined : json['enable_pcp_care_team_enhancement'],
        'hideLandingPageOfficeBooking': !exists(json, 'hide_landing_page_office_booking') ? undefined : json['hide_landing_page_office_booking'],
        'enablePros': !exists(json, 'enable_pros') ? undefined : json['enable_pros'],
        'hoursOfOperation': json['hours_of_operation'],
        'memberHoursOfOperation': json['member_hours_of_operation'],
        'wait': !exists(json, 'wait') ? undefined : json['wait'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'anys': !exists(json, 'anys') ? undefined : (json['anys'] as Array<any>).map(ClinicConfigSchemaAnysFromJSON),
        'modalities': !exists(json, 'modalities') ? undefined : (json['modalities'] as Array<any>).map(ClinicConfigSchemaModalitiesFromJSON),
        'reexaminationWindows': !exists(json, 'reexamination_windows') ? undefined : (json['reexamination_windows'] as Array<any>).map(ClinicConfigSchemaReexaminationWindowsFromJSON),
        'bookingThrottles': !exists(json, 'booking_throttles') ? undefined : (json['booking_throttles'] as Array<any>).map(ClinicConfigSchemaReexaminationWindowsFromJSON),
        'checkinPin': !exists(json, 'checkin_pin') ? undefined : json['checkin_pin'],
    };
}

export function ClinicConfigSchemaToJSON(value?: ClinicConfigSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'clinic_id': value.clinicId,
        'name': value.name,
        'code': value.code,
        'zone': value.zone,
        'open': value.open === undefined ? undefined : value.open.toISOString().substr(0,10),
        'dial': value.dial,
        'scap': value.scap,
        'state': value.state,
        'elation_default_provider': value.elationDefaultProvider,
        'elation_practice': value.elationPractice,
        'clinic_type': value.clinicType,
        'enable_pcp_enhancement': value.enablePcpEnhancement,
        'enable_pcp_care_team_enhancement': value.enablePcpCareTeamEnhancement,
        'hide_landing_page_office_booking': value.hideLandingPageOfficeBooking,
        'enable_pros': value.enablePros,
        'hours_of_operation': value.hoursOfOperation,
        'member_hours_of_operation': value.memberHoursOfOperation,
        'wait': value.wait,
        'zip': value.zip,
        'anys': value.anys === undefined ? undefined : (value.anys as Array<any>).map(ClinicConfigSchemaAnysToJSON),
        'modalities': value.modalities === undefined ? undefined : (value.modalities as Array<any>).map(ClinicConfigSchemaModalitiesToJSON),
        'reexamination_windows': value.reexaminationWindows === undefined ? undefined : (value.reexaminationWindows as Array<any>).map(ClinicConfigSchemaReexaminationWindowsToJSON),
        'booking_throttles': value.bookingThrottles === undefined ? undefined : (value.bookingThrottles as Array<any>).map(ClinicConfigSchemaReexaminationWindowsToJSON),
        'checkin_pin': value.checkinPin,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClinicConfigSchemaClinicTypeEnum {
    Virtual = 'virtual',
    OnSite = 'on_site'
}


