import React, { FunctionComponent, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { DocumentObjectDetails } from "shared/fetch/src/models/DocumentObjectDetails";
import { showSnackbar } from "shared/state/ui/snackbar";
import { IDocument } from "shared/state/ui/careAccess";
import AttachFile from "components/AttachFile";
import List from "components/List";
import ListItem from "components/List/ListItem";
import Typography from "components/Typography";
import IconButton from "components/Button/IconButton";

const useStyles = makeStyles(() => ({
  listItem: {
    padding: 0,
    "& p": {
      fontSize: "13px",
      lineHeight: "18px",
    },
  },
}));

interface IAttachments {
  documents: IDocument[];
  handleDetachFile: (id: string) => void;
}

interface IAttachFiles {
  handleAttachFile: (file: File, result: DocumentObjectDetails) => void;
  handleDetachFile: (id: string) => void;
  documents: IDocument[];
}

export const Attachments = ({ documents, handleDetachFile }: IAttachments) => {
  const classes = useStyles();

  return (
    <List ariaLabel="attachments">
      {documents.map((document: IDocument) => {
        return (
          <ListItem
            className={classes.listItem}
            aria-label={document.name}
            key={document.id}
          >
            <Typography noWrap appearance="body">
              {document.name}
            </Typography>
            <IconButton
              onClick={() => handleDetachFile(document.id)}
              aria-label={`Delete ${document.name || ""} Attachment`}
            >
              <CancelIcon />
            </IconButton>
          </ListItem>
        );
      })}
    </List>
  );
};

const CareAccessAttachFiles: FunctionComponent<IAttachFiles> = ({
  handleAttachFile,
  handleDetachFile,
  documents,
}) => {
  const dispatch = useDispatch();
  const [, setIsFileUploading] = useState();

  const handleStateOnAttach = ({ loading }: any) => {
    setIsFileUploading(loading);
  };

  const handleUploadError = () => {
    dispatch(showSnackbar("Error attaching file, please try again.", "danger"));
  };

  return (
    <div>
      {!!documents.length && (
        <Attachments
          handleDetachFile={handleDetachFile}
          documents={documents}
        />
      )}
      <AttachFile
        onAttachFile={handleAttachFile}
        onAttachingFile={handleStateOnAttach}
        onUploadError={handleUploadError}
      />
    </div>
  );
};

export default CareAccessAttachFiles;
