import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "components/Dialog";
import DialogContent from "components/Dialog/DialogContent";
import DialogActions from "components/Dialog/DialogActions";
import Typography from "components/Typography";
import Button from "components/Button";

interface ILocationDialogBox {
  isOpen: boolean;
  handleClose: () => void;
  handleDialogYesSelection: () => void;
}

const useStyles = makeStyles(({ palette }) => ({
  dialog: {
    "& .MuiPaper-root": {
      width: "389px",
      height: "318px",
    },
  },
  closeIcon: {
    padding: "20px 14px 0px",
    display: "flex",
    justifyContent: "flex-end",
    "& button": {
      width: "24px",
      height: "24px",
      color: palette?.text?.secondary,
      padding: "0px",
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  dialogContent: {
    textAlign: "center",
    paddingTop: "0px",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
  },
  warningIcon: {
    width: "62px",
    height: "62px",
    fill: palette?.text?.secondary,
  },
}));

const LocationDialogBox = ({
  isOpen,
  handleClose,
  handleDialogYesSelection,
}: ILocationDialogBox) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={isOpen}
        ariaLabelledBy="confimation-text"
        className={classes.dialog}
      >
        <div className={classes.closeIcon}>
          <Button onClick={handleClose} aria-label="Close modal">
            <CloseIcon />
          </Button>
        </div>

        <DialogContent className={classes.dialogContent}>
          <WarningIcon className={classes.warningIcon} />
          <Typography
            color="textPrimary"
            appearance="h4"
            style={{ marginBottom: "7px" }}
            id="confimation-text"
          >
            Are you sure you want to change location?
          </Typography>
          <Typography color="textPrimary" appearance="body">
            Eligibility may affect service availability and provider licensing.
            You'll be redirected to select a new service.
          </Typography>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button color="secondary" onClick={handleDialogYesSelection}>
            Yes
          </Button>
          <Button color="secondary" onClick={handleClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LocationDialogBox;
