// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     EpisodeSummaryCoreOutput,
    EpisodeSummaryCoreOutputFromJSON,
    EpisodeSummaryCoreOutputToJSON,
} from './EpisodeSummaryCoreOutput';
import {
     EpisodeSummaryCoreOutputMarketingMessage,
    EpisodeSummaryCoreOutputMarketingMessageFromJSON,
    EpisodeSummaryCoreOutputMarketingMessageToJSON,
} from './EpisodeSummaryCoreOutputMarketingMessage';
import {
     EpisodeSummaryCoreOutputXopPod,
    EpisodeSummaryCoreOutputXopPodFromJSON,
    EpisodeSummaryCoreOutputXopPodToJSON,
} from './EpisodeSummaryCoreOutputXopPod';
import {
     EpisodeWithActionableItemsObjectOutputAllOf,
    EpisodeWithActionableItemsObjectOutputAllOfFromJSON,
    EpisodeWithActionableItemsObjectOutputAllOfToJSON,
} from './EpisodeWithActionableItemsObjectOutputAllOf';
import {
     HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';
/**
 * @type EpisodeWithActionableItemsObjectOutput
 * @export
 */
export interface EpisodeWithActionableItemsObjectOutput extends EpisodeSummaryCoreOutput, EpisodeWithActionableItemsObjectOutputAllOf {
}

export function EpisodeWithActionableItemsObjectOutputFromJSON(json: any): EpisodeWithActionableItemsObjectOutput {
    return {
        ...EpisodeSummaryCoreOutputFromJSON(json),
        ...EpisodeWithActionableItemsObjectOutputAllOfFromJSON(json),
    };
}

export function EpisodeWithActionableItemsObjectOutputToJSON(value?: EpisodeWithActionableItemsObjectOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...EpisodeSummaryCoreOutputToJSON(value),
        ...EpisodeWithActionableItemsObjectOutputAllOfToJSON(value),
    };
}

