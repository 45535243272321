import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { MemberAccountInfoOutput } from "shared/fetch/src/models/MemberAccountInfoOutput";
import { MemberInfo } from "shared/fetch/src/models/MemberInfo";
import { getDaysDifference } from "utils/durationConverter";

/**
 *  model pattern here to help consolidate reusable logic
 *  the logic below having been happening quite regularly
 */

type UserTypes = JsonUser | MemberInfo | MemberAccountInfoOutput;

class User {
  public static isUserIneligible = (user: UserTypes) =>
    user?.status === "Ineligible";

  public static isUserPendingIneliglble = (user: UserTypes) =>
    !User.isUserIneligible(user) && user?.becomesIneligibleAt;

  public static isUserInactive = (user: UserTypes) =>
    user?.status === "Inactive";

  public static isUserActive = (user: UserTypes) => user?.status === "Active";

  public static isUserLocked = (user: UserTypes | any) => user?.locked === true;

  public static daysUntilIneligible = (user: UserTypes) => {
    // @ts-ignore: Object is possibly 'null'.
    return getDaysDifference(user?.becomesIneligibleAt);
  };

  public static getUserType = (user: JsonUser) =>
    user?.ctm ? "ctm" : "member";
}

export default User;
