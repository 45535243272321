import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "components/TextField";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "components/Autocomplete";
import Box from "@mui/material/Box";
import Typography from "components/Typography";
import ComponentBox from "components/Box";
import EocBadge from "components/EocBadge";
import palette from "styles/mui/palette";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      padding: 0,
      paddingLeft: "5px",
    },
  },
  wrapNameAndRole: {
    whiteSpace: "normal",
    maxWidth: "200px",
  },
  listboxItem: {
    "& .MuiAutocomplete-option": {
      "&.Mui-focused": {
        backgroundColor: "rgba(28, 116, 171, 0.04)",
        "&[aria-selected='true']": {
          backgroundColor: "rgba(28, 116, 171, 0.12)",
        },
      },
      "&[aria-selected='true']": {
        backgroundColor: "rgba(28, 116, 171, 0.08)",
      },
    },
  },
}));

interface ProviderAutocompleteProps {
  onSelectedProvider: (provider: any | null, clinicId?: string) => void;
  providers?: any;
  defaultProvider?: any;
  isPending: boolean;
  fullWidth?: boolean;
  selectedProvider?: any;
}

const ProviderAutocomplete: React.FC<ProviderAutocompleteProps> = ({
  onSelectedProvider,
  providers,
  defaultProvider,
  isPending,
  fullWidth = false,
  selectedProvider,
}) => {
  const [inputValue, setInputValue] = useState("");
  const classes = useStyles();
  useEffect(() => {
    if (defaultProvider) {
      setInputValue(defaultProvider.name);
      const clinicId = defaultProvider.clinics?.[0]?.id;
      onSelectedProvider(defaultProvider, clinicId);
    } else if (selectedProvider) {
      setInputValue(selectedProvider.name);
      const clinicId = selectedProvider.clinics?.[0]?.id;
      onSelectedProvider(selectedProvider, clinicId);
    }
  }, [defaultProvider, selectedProvider]);

  const handleProviderChange = (_event: any, newProvider: any) => {
    if (typeof newProvider === "string") {
      setInputValue("");
      onSelectedProvider(null);
    } else if (newProvider) {
      setInputValue(newProvider.name);
      const clinicId = newProvider.clinics?.[0]?.id;
      onSelectedProvider(newProvider, clinicId);
    } else {
      setInputValue("");
      onSelectedProvider(null);
    }
  };

  const handleInputChange = (_event: any, newProviderValue: string) => {
    setInputValue(newProviderValue);
  };

  if (isPending) {
    return <CircularProgress />;
  }

  return (
    <Autocomplete
      id="provider-autocomplete"
      className={classes.root}
      options={providers || []}
      getOptionLabel={(option) => option.name}
      value={
        providers.find((option: any) => option.name === inputValue) || null
      }
      ListboxProps={{
        className: classes.listboxItem,
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <div>
            <ComponentBox>
              <Typography
                appearance="smallBody"
                color="textPrimary"
                className={classes.wrapNameAndRole}
              >
                {option.name}
              </Typography>
            </ComponentBox>
            <ComponentBox style={{ width: "100%", display: "flex" }}>
              <Typography
                className={classes.wrapNameAndRole}
                appearance="smallBody"
                color="textSecondary"
                style={{ marginRight: 4 }}
              >
                {option.guilds?.[0] || option.role}
              </Typography>
              {option.isPcp ? (
                <EocBadge
                  badge={{
                    abbreviation: "PCP",
                    color: palette?.white,
                    backgroundColor: palette?.appBackground?.navy,
                  }}
                />
              ) : null}
            </ComponentBox>
          </div>
        </Box>
      )}
      onChange={handleProviderChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          color="secondary"
          placeholder="All providers"
          fullWidth={fullWidth}
        />
      )}
      style={{ width: fullWidth ? "100%" : 350 }}
    />
  );
};

export default ProviderAutocomplete;
