import React, { FunctionComponent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "@types";
import { setSelectedCenter } from "shared/state/ui/careAccess/actions";
import { MemberClinic } from "shared/fetch/src/models/MemberClinic";
import useMobileCheck from "hooks/useMobileCheck";
import LocationComboBox from "../LocationPicker/LocationComboBox";

interface ICentersComboBoxProps {
  allClinics: MemberClinic[];
}

const CentersComboBox: FunctionComponent<ICentersComboBoxProps> = ({
  allClinics,
}) => {
  const isMobile = useMobileCheck();
  const dispatch = useDispatch();
  const selectedCenter = useSelector(
    (state: State) => state.ui.careAccess.centers
  );
  const mappedSelectedCenter = {
    label: selectedCenter?.centerName,
    code: selectedCenter?.centerId,
  };
  const centersOptions = allClinics.map((clinic) => ({
    label: clinic?.name || "",
    code: clinic?.clinicId || "",
  }));
  centersOptions.unshift({ label: "All Centers", code: "0" });

  const [stateCenter, setStateCenter] = useState(
    mappedSelectedCenter || centersOptions[0]
  );

  const handleCenterChange = (newValue: any) => {
    if (newValue) {
      dispatch(
        setSelectedCenter({
          centerId: newValue?.code,
          centerName: newValue?.label,
        })
      );
      setStateCenter({
        label: newValue.label,
        code: newValue.code,
      });
    }
  };

  useEffect(() => {
    setStateCenter({
      label: mappedSelectedCenter.label,
      code: mappedSelectedCenter.code,
    });
  }, [selectedCenter]);

  return (
    <LocationComboBox
      options={centersOptions}
      selectedValue={stateCenter}
      onChange={handleCenterChange}
      customWidth={!isMobile ? "240px" : "100%"}
      isMobileScreen={isMobile}
      padding={!isMobile ? "2px" : "10px"}
    />
  );
};

export default CentersComboBox;
