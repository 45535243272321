import { MessageRecipientPropsTypeEnum } from "shared/fetch/src/models/MessageRecipientProps";
import { OOONotificationsArray } from "shared/fetch/src/models/OOONotificationsArray";
import { SlotOutput } from "shared/fetch/src/models/SlotOutput";

export const SET_VISIT_FILTER = "SET_VISIT_FILTER";
export const SET_APPOINTMENT_TYPE = "SET_APPOINTMENT_TYPE";
export const SET_SELECTED_CENTER = "SET_SELECTED_CENTER";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_SERVICE = "SET_SERVICE";
export const SET_USER_ID = "SET_USER_ID";
export const SET_CARE_TYPE = "SET_CARE_TYPE";
export const SET_DIRECT_MESSAGE_STEP = "SET_DIRECT_MESSAGE_STEP";
export const SET_LOCATION_STATE = "SET_LOCATION_STATE";
export const SET_SELECTED_PROVIDER = "SET_SELECTED_PROVIDER";
export const SET_SELECTED_SLOT_PROVIDER = "SET_SELECTED_SLOT_PROVIDER";
export const SET_SELECTED_SLOT = "SET_SELECTED_SLOT";
export const SET_ATTACHED_DOCUMENT = "SET_ATTACHED_DOCUMENT";
export const DETACH_ATTACHED_DOCUMENT = "DETACH_ATTACHED_DOCUMENT";
export const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
export const SET_TEXT_REMINDER = "SET_TEXT_REMINDER";
export const SET_PREFERRED_LANGUAGE = "SET_PREFERRED_LANGUAGE";
export const SET_ACCOMMODATIONS = "SET_ACCOMMODATIONS";
export const SET_ACCOMMODATION_CHECK = "SET_ACCOMMODATION_CHECK";
export const SET_REASON = "SET_REASON";
export const RESET_ALL_DATA = "RESET_ALL_DATA";
export const SET_LATE_CANCELATION_CHECK = "SET_LATE_CANCELATION_CHECK";
export const SET_MESSAGE_BODY = "SET_MESSAGE_BODY";
export const SET_MESSAGE_SUBJECT = "SET_MESSAGE_SUBJECT";
export const SET_MESSAGE_DOCUMENTS = "SET_MESSAGE_DOCUMENTS";
export const DELETE_MESSAGE_DOCUMENT = "DELETE_MESSAGE_DOCUMENT";
export const SET_MESSAGE_RECIPIENT = "SET_MESSAGE_RECIPIENT";
export const RESET_MESSAGE_DATA = "RESET_MESSAGE_DATA";
export const RESET_PREVIOUS_URL_IF_REDIRECT = "RESET_PREVIOUS_URL_IF_REDIRECT";
export const RESET_IS_PAYMENTS_REDIRECT = "RESET_IS_PAYMENTS_REDIRECT";
export const SET_HAS_SKIPPED_DECISIONS_PAGE = "SET_HAS_SKIPPED_DECISIONS_PAGE";
export const RESET_REASON_FOR_VISIT = "RESET_REASON_FOR_VISIT";

export interface ICurrentLocation {
  code: string;
  label: string;
}

export interface ISelectedProvider {
  code: string;
  name: string;
}

export interface IDocument {
  id: string;
  name: string;
  thumbnailUrl: string;
  mime: string;
}

export interface IAccommodations {
  audio: boolean;
  visual: boolean;
  translation: boolean;
}

export interface IPhone {
  phoneNumber: string;
  isValid: boolean;
}

export interface MessageRecipientState {
  id: string;
  name: string;
  type: MessageRecipientPropsTypeEnum | string;
  clinics: string[];
  oooNotifications: OOONotificationsArray[];
}

export interface MessageState {
  messageRecipient: MessageRecipientState;
  messageSubject: string;
  messageBody: string;
  documents: IDocument[];
  hasSkippedDecisionsPage: boolean;
  isPaymentsRedirect: boolean;
  previousUrlIfRedirect: string;
}

export interface CentersState {
  centerId: string;
  centerName: string;
}

export interface VisitsState {
  visitFilter: string;
  appointmentType: string;
  appointmentLabel: string;
}

export interface DatePickerState {
  provider: ISelectedProvider;
  slot: SlotOutput;
  slotProvider: any;
}

export interface FinalizeState {
  reason: string;
  documents: IDocument[];
  phone: IPhone;
  textReminder: boolean;
  preferredLanguage: { code: string; label: string };
  iNeedAccommodations: boolean;
  iAgreeToLateCancelationConsent: boolean;
  accommodations: IAccommodations;
  accommodationsList: string[];
}

export interface State {
  savedMemberId: string;
  locationState: ICurrentLocation;
  currentStep: string;
  userFlow: any[];
  services: {
    serviceLineId: string;
  };
  decisions: {
    careType: string;
  };
  message: MessageState;
  centers: CentersState;
  visits: VisitsState;
  datePicker: DatePickerState;
  finalize: FinalizeState;
}

export const getRecipientInitialState = (): MessageRecipientState => {
  return {
    id: "",
    name: "",
    type: "",
    oooNotifications: [],
    clinics: [],
  };
};

export const getMessageInitialState = (): MessageState => {
  return {
    messageRecipient: getRecipientInitialState(),
    messageSubject: "",
    messageBody: "",
    documents: [],
    hasSkippedDecisionsPage: false,
    isPaymentsRedirect: false,
    previousUrlIfRedirect: "",
  };
};

export const getCentersInitialState = (): CentersState => {
  return {
    centerId: "",
    centerName: "",
  };
};

export const getVisitsInitialState = (): VisitsState => {
  return {
    visitFilter: "in_person",
    appointmentType: "",
    appointmentLabel: "",
  };
};

export const getDatePickerInitialState = (): DatePickerState => {
  return {
    provider: { name: "", code: "" },
    slot: {},
    slotProvider: {},
  };
};

export const getAccommodationsInitialState = (): IAccommodations => {
  return {
    audio: false,
    visual: false,
    translation: false,
  };
};

export const getFinalizeInitialState = (): FinalizeState => {
  return {
    reason: "",
    documents: [],
    phone: { phoneNumber: "", isValid: false },
    textReminder: false,
    preferredLanguage: { code: "", label: "" },
    iNeedAccommodations: false,
    accommodations: getAccommodationsInitialState(),
    accommodationsList: [],
    iAgreeToLateCancelationConsent: false,
  };
};

export const getInitialCareAccessState = (): State => {
  return {
    savedMemberId: "",
    locationState: { code: "", label: "" },
    currentStep: "",
    userFlow: [],
    services: { serviceLineId: "" },
    decisions: { careType: "" },
    message: getMessageInitialState(),
    centers: getCentersInitialState(),
    visits: getVisitsInitialState(),
    datePicker: getDatePickerInitialState(),
    finalize: getFinalizeInitialState(),
  };
};

export const careAccessReducer = (
  state: State = getInitialCareAccessState(),
  action: any
): State => {
  if (action?.type) {
    const { type, payload } = action;

    switch (type) {
      case RESET_ALL_DATA:
        return getInitialCareAccessState();

      case SET_USER_ID:
        return {
          ...state,
          savedMemberId: payload,
        };

      case SET_VISIT_FILTER:
        return {
          ...state,
          visits: {
            ...state.visits,
            visitFilter: payload,
          },
        };

      case SET_APPOINTMENT_TYPE:
        return {
          ...state,
          visits: {
            ...state.visits,
            appointmentType: payload.appointmentType,
            appointmentLabel: payload.appointmentLabel,
          },
          datePicker: getDatePickerInitialState(),
        };

      case SET_SELECTED_CENTER:
        return {
          ...state,
          centers: {
            centerId: payload.centerId,
            centerName: payload.centerName,
          },
        };

      case SET_CURRENT_STEP:
        const userFlow = [...state.userFlow];
        let currentStep = payload.step;
        if (payload.isBackButton) {
          userFlow.pop();
          currentStep = userFlow[userFlow.length - 1] || "";
        } else {
          if (userFlow.indexOf(currentStep) === -1) {
            userFlow.push(currentStep);
          }
        }

        return {
          ...state,
          currentStep,
          userFlow,
        };

      case SET_SERVICE:
        return {
          ...state,
          services: { serviceLineId: payload.serviceLineId },
          finalize: {
            ...state.finalize,
            iAgreeToLateCancelationConsent: false,
          },
        };

      case SET_CARE_TYPE:
        return {
          ...state,
          decisions: { careType: payload.careType },
        };

      case SET_DIRECT_MESSAGE_STEP:
        const initialState: State = getInitialCareAccessState();

        if (payload.isPaymentsRedirect) {
          return {
            ...initialState,
            currentStep: "messages",
            userFlow: ["messages"],
            message: {
              ...initialState.message,
              isPaymentsRedirect: true,
              previousUrlIfRedirect: "/account/payments",
            },
          };
        } else {
          return {
            ...initialState,
            currentStep: "messages",
            userFlow: ["messages"],
          };
        }

      case RESET_IS_PAYMENTS_REDIRECT:
        return {
          ...state,
          message: {
            ...state.message,
            isPaymentsRedirect: false,
          },
        };

      case SET_LOCATION_STATE:
        return {
          ...state,
          locationState: payload.locationState,
          currentStep: "services",
          userFlow: ["services"],
        };

      case SET_SELECTED_PROVIDER:
        return {
          ...state,
          datePicker: { ...state.datePicker, provider: payload },
        };

      case SET_SELECTED_SLOT:
        return {
          ...state,
          datePicker: { ...state.datePicker, slot: payload },
        };

      case SET_SELECTED_SLOT_PROVIDER:
        return {
          ...state,
          datePicker: { ...state.datePicker, slotProvider: payload },
        };

      case SET_REASON:
        return {
          ...state,
          finalize: { ...state.finalize, reason: payload },
        };

      case SET_ATTACHED_DOCUMENT:
        return {
          ...state,
          finalize: {
            ...state.finalize,
            documents: [...state.finalize.documents, payload],
          },
        };

      case DETACH_ATTACHED_DOCUMENT:
        const filteredDocuments = state.finalize.documents.filter(
          (item) => item.id !== payload
        );
        return {
          ...state,
          finalize: { ...state.finalize, documents: filteredDocuments },
        };

      case SET_PHONE_NUMBER:
        return {
          ...state,
          finalize: { ...state.finalize, phone: payload },
        };

      case SET_TEXT_REMINDER:
        return {
          ...state,
          finalize: { ...state.finalize, textReminder: payload },
        };

      case SET_ACCOMMODATION_CHECK:
        return {
          ...state,
          finalize: {
            ...state.finalize,
            accommodations: !payload
              ? getAccommodationsInitialState()
              : state.finalize.accommodations,
            accommodationsList: !payload
              ? []
              : state.finalize.accommodationsList,
            iNeedAccommodations: payload,
          },
        };

      case SET_ACCOMMODATIONS:
        const accommodationsList: string[] = [];
        for (const [key, value] of Object.entries(payload)) {
          if (!!value) {
            accommodationsList.push(key);
          }
        }

        return {
          ...state,
          finalize: {
            ...state.finalize,
            accommodations: payload,
            accommodationsList,
          },
        };

      case SET_PREFERRED_LANGUAGE:
        return {
          ...state,
          finalize: { ...state.finalize, preferredLanguage: payload },
        };

      case SET_LATE_CANCELATION_CHECK:
        return {
          ...state,
          finalize: {
            ...state.finalize,
            iAgreeToLateCancelationConsent: payload,
          },
        };

      case SET_MESSAGE_DOCUMENTS:
        return {
          ...state,
          message: {
            ...state.message,
            documents: payload,
          },
        };

      default:
        return state;

      case DELETE_MESSAGE_DOCUMENT:
        const filteredMessageDocuments = state.message.documents.filter(
          (item) => item.id !== payload
        );
        return {
          ...state,
          message: { ...state.message, documents: filteredMessageDocuments },
        };

      case SET_MESSAGE_RECIPIENT:
        const {
          id,
          name,
          type: recipientType,
          oooNotifications,
          clinics,
        } = payload;
        return {
          ...state,
          message: {
            ...state.message,
            messageRecipient: {
              id,
              name,
              type: recipientType,
              oooNotifications,
              clinics,
            },
          },
        };

      case SET_MESSAGE_SUBJECT:
        return {
          ...state,
          message: {
            ...state.message,
            messageSubject: payload,
          },
        };

      case SET_MESSAGE_BODY:
        return {
          ...state,
          message: {
            ...state.message,
            messageBody: payload,
          },
        };

      case RESET_MESSAGE_DATA:
        // Due to the multiple entry points into messages page, this will help data not bleed into other steps
        return {
          ...state,
          message: getMessageInitialState(),
        };

      case RESET_PREVIOUS_URL_IF_REDIRECT:
        return {
          ...state,
          message: {
            ...state.message,
            previousUrlIfRedirect: "",
          },
        };

      case SET_HAS_SKIPPED_DECISIONS_PAGE:
        return {
          ...state,
          message: {
            ...state.message,
            hasSkippedDecisionsPage: payload,
          },
        };

      case RESET_REASON_FOR_VISIT:
        return {
          ...state,
          finalize: {
            ...state.finalize,
            reason: "",
          },
        };
    }
  }
  return state;
};
