import { useQuery } from "react-query";
import {
  GetMemberClinicsRequest,
  fetchMemberClinicsApi,
} from "shared/fetch/src/apis/MemberClinicsApi";

export const memberClinicsQueryKey = (
  requestParameters: GetMemberClinicsRequest
) => ["memberClinics", requestParameters];

const useGetMemberClinics = (
  requestParameters: GetMemberClinicsRequest,
  options?: any
) => {
  const { data, isLoading, refetch, isFetched } = useQuery(
    memberClinicsQueryKey(requestParameters),
    () => fetchMemberClinicsApi.getMemberClinics(requestParameters),
    {
      enabled: options?.enabled && !!requestParameters.id,
    }
  );

  return {
    memberClinics: data?.results,
    isPending: isLoading,
    isFinished: isFetched,
    getMemberClinics: refetch,
  };
};

export default useGetMemberClinics;
