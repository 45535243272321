// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CtmPodAssignmentFiltersResponseResults,
    CtmPodAssignmentFiltersResponseResultsFromJSON,
    CtmPodAssignmentFiltersResponseResultsToJSON,
} from './CtmPodAssignmentFiltersResponseResults';


/**
 * 
 * @export
 * @interface CtmPodAssignmentFiltersResponse
 */
export interface CtmPodAssignmentFiltersResponse  {
    /**
     * 
     * @type {CtmPodAssignmentFiltersResponseResults}
     * @memberof CtmPodAssignmentFiltersResponse
     */
    results: CtmPodAssignmentFiltersResponseResults;
}

export function CtmPodAssignmentFiltersResponseFromJSON(json: any): CtmPodAssignmentFiltersResponse {
    return {
        'results': CtmPodAssignmentFiltersResponseResultsFromJSON(json['results']),
    };
}

export function CtmPodAssignmentFiltersResponseToJSON(value?: CtmPodAssignmentFiltersResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': CtmPodAssignmentFiltersResponseResultsToJSON(value.results),
    };
}


