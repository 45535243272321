import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import {
  setCurrentStep,
  setSelectedCenter,
  setVisitFilter,
} from "shared/state/ui/careAccess/actions";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import Typography from "components/Typography";
import Asset from "components/Asset";

interface IProps {
  memberId: string;
  isMinor: boolean;
  hasClearFilterLink: boolean;
  clinicContact: string;
}

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    width: "390px",
    padding: "12px",
    marginTop: "20px",
  },
  copy: {
    "& button": {
      "& span": {
        color: palette.text.link,
      },
    },
    "& span": {
      color: palette.text.secondary,
      fontSize: "13.3px",
      lineHeight: "17.3px",
    },
    "& a": {
      fontSize: "13.3px",
      lineHeight: "17.3px",
    },
  },
  icon: {
    marginBottom: "8px",
  },
}));

const LookingForAnotherVisitType: FunctionComponent<IProps> = ({
  memberId,
  isMinor,
  hasClearFilterLink,
  clinicContact,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClearFilters = () => {
    dispatch(
      setSelectedCenter({
        centerId: "0",
        centerName: "All Centers",
      })
    );
    dispatch(setVisitFilter("in_person"));
  };

  return (
    <>
      {isMinor ? (
        <div className={classes.wrapper}>
          <Asset
            hiddenFromScreenReader
            name="care-visit-question"
            className={classes.icon}
          />
          <Typography
            appearance="bodyEmphasized"
            color="textPrimary"
            data-testid="looking-for-visit"
          >
            Looking for another visit type?
          </Typography>

          <div className={classes.copy}>
            <span>If you need to schedule a different visit type</span>
            {hasClearFilterLink ? (
              <>
                <span>, </span>
                <ButtonStyledAsLink onClick={handleClearFilters}>
                  clear your filters
                </ButtonStyledAsLink>
                <span>
                  {" "}
                  {`to see availability in all centers. You can also call your Care Team at ${clinicContact}`}
                </span>
              </>
            ) : (
              <span>{` call your Care Team at ${clinicContact}`}</span>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Asset
            hiddenFromScreenReader
            name="care-visit-question"
            className={classes.icon}
          />
          <Typography
            appearance="bodyEmphasized"
            color="textPrimary"
            data-testid="looking-for-visit"
          >
            Looking for another visit type?
          </Typography>

          <div className={classes.copy}>
            <span>If you need to schedule a different visit type, </span>
            {hasClearFilterLink && (
              <>
                <ButtonStyledAsLink onClick={handleClearFilters}>
                  clear your filters
                </ButtonStyledAsLink>
                <span> to see availability in all centers. You can also </span>
              </>
            )}
            <Link
              to={`/members/${memberId}/care-access/messages`}
              onClick={() => dispatch(setCurrentStep({ step: "messages" }))}
              data-testid="message-care-team"
            >
              message your Care Team
            </Link>
            <span>{`, or call them at ${clinicContact}`}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default LookingForAnotherVisitType;
