// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    DeleteDocumentRequestBody,
    DeleteDocumentRequestBodyFromJSON,
    DeleteDocumentRequestBodyToJSON,
} from '../models/DeleteDocumentRequestBody'
import {
    DocumentObjectDetails,
    DocumentObjectDetailsFromJSON,
    DocumentObjectDetailsToJSON,
} from '../models/DocumentObjectDetails'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UpdateDocumentRequestBody,
    UpdateDocumentRequestBodyFromJSON,
    UpdateDocumentRequestBodyToJSON,
} from '../models/UpdateDocumentRequestBody'
import {
    UserDocumentsFetchResponse,
    UserDocumentsFetchResponseFromJSON,
    UserDocumentsFetchResponseToJSON,
} from '../models/UserDocumentsFetchResponse'


export interface DeleteDocumentRequest {
    id: string;
    deleteDocumentRequestBody?: DeleteDocumentRequestBody;
}

export interface FetchDocumentRequest {
    id: string;
    thumbnail?: boolean;
}

export interface FetchUserDocumentsRequest {
    id: string;
}

export interface FetchUserPaginatedDocumentsRequest {
    id: string;
    page?: string;
}

export interface UpdateDocumentRequest {
    id: string;
    updateDocumentRequestBody?: UpdateDocumentRequestBody;
}

export interface UploadUserDocumentRequest {
    id: string;
    file: Blob;
    title?: Blob;
    docType?: string;
}


/**
 * Delete a document by ID
 */
function deleteDocumentRaw<T>(requestParameters: DeleteDocumentRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDocument.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters || DeleteDocumentRequestBodyToJSON(requestParameters.deleteDocumentRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a document by ID
*/
export function deleteDocument<T>(requestParameters: DeleteDocumentRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteDocumentRaw(requestParameters, requestConfig);
}

/**
 * Fetch a document by ID
 */
function fetchDocumentRaw<T>(requestParameters: FetchDocumentRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchDocument.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.thumbnail !== undefined) {
        queryParameters['thumbnail'] = requestParameters.thumbnail;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Fetch a document by ID
*/
export function fetchDocument<T>(requestParameters: FetchDocumentRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return fetchDocumentRaw(requestParameters, requestConfig);
}

/**
 * Fetch mimetypes supported for document upload
 */
function fetchDocumentMimeTypesRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<string>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/documents-mimetypes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Fetch mimetypes supported for document upload
*/
export function fetchDocumentMimeTypes<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<string>>): QueryConfig<T> {
    return fetchDocumentMimeTypesRaw( requestConfig);
}

/**
 * Fetch documents for a user ID
 */
function fetchUserDocumentsRaw<T>(requestParameters: FetchUserDocumentsRequest, requestConfig: runtime.TypedQueryConfig<T, Array<DocumentObjectDetails>> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserDocuments.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(body.map(DocumentObjectDetailsFromJSON), text);
    }

    return config;
}

/**
* Fetch documents for a user ID
*/
export function fetchUserDocuments<T>(requestParameters: FetchUserDocumentsRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<DocumentObjectDetails>>): QueryConfig<T> {
    return fetchUserDocumentsRaw(requestParameters, requestConfig);
}

/**
 * Fetch paginated documents for a user ID
 */
function fetchUserPaginatedDocumentsRaw<T>(requestParameters: FetchUserPaginatedDocumentsRequest, requestConfig: runtime.TypedQueryConfig<T, UserDocumentsFetchResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserPaginatedDocuments.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/paginated_documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UserDocumentsFetchResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch paginated documents for a user ID
*/
export function fetchUserPaginatedDocuments<T>(requestParameters: FetchUserPaginatedDocumentsRequest, requestConfig?: runtime.TypedQueryConfig<T, UserDocumentsFetchResponse>): QueryConfig<T> {
    return fetchUserPaginatedDocumentsRaw(requestParameters, requestConfig);
}

/**
 * Update a document by ID
 */
function updateDocumentRaw<T>(requestParameters: UpdateDocumentRequest, requestConfig: runtime.TypedQueryConfig<T, DocumentObjectDetails> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDocument.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/documents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UpdateDocumentRequestBodyToJSON(requestParameters.updateDocumentRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DocumentObjectDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Update a document by ID
*/
export function updateDocument<T>(requestParameters: UpdateDocumentRequest, requestConfig?: runtime.TypedQueryConfig<T, DocumentObjectDetails>): QueryConfig<T> {
    return updateDocumentRaw(requestParameters, requestConfig);
}

/**
 * Upload a document for a user ID
 */
function uploadUserDocumentRaw<T>(requestParameters: UploadUserDocumentRequest, requestConfig: runtime.TypedQueryConfig<T, DocumentObjectDetails> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadUserDocument.');
    }

    if (requestParameters.file === null || requestParameters.file === undefined) {
        throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadUserDocument.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const formData = new FormData();
    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    if (requestParameters.title !== undefined) {
        formData.append('title', requestParameters.title as any);
    }

    if (requestParameters.docType !== undefined) {
        formData.append('doc_type', requestParameters.docType as any);
    }

    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/documents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(DocumentObjectDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Upload a document for a user ID
*/
export function uploadUserDocument<T>(requestParameters: UploadUserDocumentRequest, requestConfig?: runtime.TypedQueryConfig<T, DocumentObjectDetails>): QueryConfig<T> {
    return uploadUserDocumentRaw(requestParameters, requestConfig);
}

