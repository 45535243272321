import React from "react";
import { useMediaQuery, useTheme, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: "110vw",
      [theme.breakpoints.down("lg")]: {
        width: "90vw",
      },
    },
  })
);

const ServicesSkeleton: React.FunctionComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileAndIpadScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <div className={classes.root}>
      {isMobileAndIpadScreen ? (
        <>
          <Grid container style={{ marginTop: "15px" }}>
            <Skeleton
              height="15px"
              width="92%"
              style={{ marginLeft: "10px" }}
            />
          </Grid>
          <Grid container style={{ height: "225px" }}>
            <Skeleton
              height="350px"
              width="43%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
            <Skeleton
              height="350px"
              width="43%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
          </Grid>
          <Grid container style={{ height: "290px" }}>
            <Skeleton
              height="350px"
              width="43%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
            <Skeleton
              height="350px"
              width="43%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
          </Grid>

          <Grid container>
            <Skeleton
              height="15px"
              width="40%"
              style={{ marginLeft: "3px", marginBottom: "15px" }}
            />
          </Grid>
          <Grid container>
            <Skeleton height="15px" width="70%" style={{ marginLeft: "3px" }} />
          </Grid>
          <Grid container>
            <Skeleton height="15px" width="40%" style={{ marginLeft: "3px" }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid container style={{ marginTop: "15px" }}>
            <Skeleton
              height="15px"
              width="40%"
              style={{ marginLeft: "10px" }}
            />
          </Grid>
          <Grid container style={{ height: "225px" }}>
            <Skeleton
              height="350px"
              width="20%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
            <Skeleton
              height="350px"
              width="20%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
            <Skeleton
              height="350px"
              width="20%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
          </Grid>
          <Grid container style={{ height: "290px" }}>
            <Skeleton
              height="350px"
              width="20%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
            <Skeleton
              height="350px"
              width="20%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
            <Skeleton
              height="350px"
              width="20%"
              style={{
                margin: "10px 10px",
                borderRadius: "10px",
                position: "relative",
                bottom: "60px",
              }}
            />
          </Grid>

          <Grid container>
            <Skeleton
              height="15px"
              width="30%"
              style={{ marginLeft: "3px", marginBottom: "15px" }}
            />
          </Grid>
          <Grid container>
            <Skeleton height="15px" width="40%" style={{ marginLeft: "3px" }} />
          </Grid>
          <Grid container>
            <Skeleton height="15px" width="30%" style={{ marginLeft: "3px" }} />
          </Grid>
        </>
      )}
    </div>
  );
};

export default ServicesSkeleton;
