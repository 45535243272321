// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberMessageRecipientsResponse,
    MemberMessageRecipientsResponseFromJSON,
    MemberMessageRecipientsResponseToJSON,
} from '../models/MemberMessageRecipientsResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    RecipientID,
    RecipientIDFromJSON,
    RecipientIDToJSON,
} from '../models/RecipientID'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface DeleteRecipientRequest {
    id: string;
    recipientID: RecipientID;
}

export interface FetchMemberMessageRecipientsRequest {
    id: string;
    serviceSubtype?: string;
    serviceLineId?: string;
    state?: string;
}

export interface GetEpisodeMessageRecipientsRequest {
    id: string;
}

export interface GetTimelineEventMessageRecipientsRequest {
    id: string;
}


/**
 * Remove Message Recipient
 * Remove Message Recipient
 */
function deleteRecipientRaw<T>(requestParameters: DeleteRecipientRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRecipient.');
    }

    if (requestParameters.recipientID === null || requestParameters.recipientID === undefined) {
        throw new runtime.RequiredError('recipientID','Required parameter requestParameters.recipientID was null or undefined when calling deleteRecipient.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/member_message_recipients/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters || RecipientIDToJSON(requestParameters.recipientID),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Remove Message Recipient
* Remove Message Recipient
*/
export function deleteRecipient<T>(requestParameters: DeleteRecipientRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteRecipientRaw(requestParameters, requestConfig);
}

/**
 * View the list of recipients for the member direct message
 */
function fetchMemberMessageRecipientsRaw<T>(requestParameters: FetchMemberMessageRecipientsRequest, requestConfig: runtime.TypedQueryConfig<T, MemberMessageRecipientsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberMessageRecipients.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.serviceSubtype !== undefined) {
        queryParameters['service_subtype'] = requestParameters.serviceSubtype;
    }


    if (requestParameters.serviceLineId !== undefined) {
        queryParameters['service_line_id'] = requestParameters.serviceLineId;
    }


    if (requestParameters.state !== undefined) {
        queryParameters['state'] = requestParameters.state;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["member"]];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/message_recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MemberMessageRecipientsResponseFromJSON(body), text);
    }

    return config;
}

/**
* View the list of recipients for the member direct message
*/
export function fetchMemberMessageRecipients<T>(requestParameters: FetchMemberMessageRecipientsRequest, requestConfig?: runtime.TypedQueryConfig<T, MemberMessageRecipientsResponse>): QueryConfig<T> {
    return fetchMemberMessageRecipientsRaw(requestParameters, requestConfig);
}

/**
 * Fetch message recipients for a given episode
 * Fetch message recipients for a given episode
 */
function getEpisodeMessageRecipientsRaw<T>(requestParameters: GetEpisodeMessageRecipientsRequest, requestConfig: runtime.TypedQueryConfig<T, MemberMessageRecipientsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getEpisodeMessageRecipients.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/message_recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MemberMessageRecipientsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch message recipients for a given episode
* Fetch message recipients for a given episode
*/
export function getEpisodeMessageRecipients<T>(requestParameters: GetEpisodeMessageRecipientsRequest, requestConfig?: runtime.TypedQueryConfig<T, MemberMessageRecipientsResponse>): QueryConfig<T> {
    return getEpisodeMessageRecipientsRaw(requestParameters, requestConfig);
}

/**
 * Fetch the possible message recipients for a timeline event
 * Fetch message recipients for the current timeline event/conversation
 */
function getTimelineEventMessageRecipientsRaw<T>(requestParameters: GetTimelineEventMessageRecipientsRequest, requestConfig: runtime.TypedQueryConfig<T, MemberMessageRecipientsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTimelineEventMessageRecipients.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/timeline_events/{id}/message_recipients`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MemberMessageRecipientsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch the possible message recipients for a timeline event
* Fetch message recipients for the current timeline event/conversation
*/
export function getTimelineEventMessageRecipients<T>(requestParameters: GetTimelineEventMessageRecipientsRequest, requestConfig?: runtime.TypedQueryConfig<T, MemberMessageRecipientsResponse>): QueryConfig<T> {
    return getTimelineEventMessageRecipientsRaw(requestParameters, requestConfig);
}

