// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentTypeConfigSchemaDefaultReportingMapping,
    AppointmentTypeConfigSchemaDefaultReportingMappingFromJSON,
    AppointmentTypeConfigSchemaDefaultReportingMappingToJSON,
} from './AppointmentTypeConfigSchemaDefaultReportingMapping';


/**
 * 
 * @export
 * @interface AppointmentTypeConfigRequestBody
 */
export interface AppointmentTypeConfigRequestBody  {
    /**
     * This is a 5 digit numeric unique id for the appointment
     * @type {number}
     * @memberof AppointmentTypeConfigRequestBody
     */
    appointmentTypeId?: number;
    /**
     * This field determines what types of slots this appointment can be booked to.
     * @type {number}
     * @memberof AppointmentTypeConfigRequestBody
     */
    uses?: number;
    /**
     * This the name that displays in xop drop-down when patients and staff schedule the appointment.
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    name?: string;
    /**
     * This displays a note in the xop when a patient goes to schedule the appointment type.
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    note?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTypeConfigRequestBody
     */
    care?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTypeConfigRequestBody
     */
    covidSurveyEmail?: boolean;
    /**
     * This is hex color value the changes the color that shows up in XOcal
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    color?: string;
    /**
     * This value can only be in_person or video - there is special logic in the application
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    method?: string;
    /**
     * This is a short label that shows up on XOCal when looking at the calendar.
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    label?: string;
    /**
     * This is just for charges.
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    receiptCtg?: string;
    /**
     * This is an HTML note that gets injected into the xop and gives the patients context on what the appointment is about.
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentTypeConfigRequestBody
     */
    must?: number;
    /**
     * This is the type of appointment
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    configType?: AppointmentTypeConfigRequestBodyConfigTypeEnum;
    /**
     * 
     * @type {AppointmentTypeConfigSchemaDefaultReportingMapping}
     * @memberof AppointmentTypeConfigRequestBody
     */
    defaultReportingMapping?: AppointmentTypeConfigSchemaDefaultReportingMapping;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTypeConfigRequestBody
     */
    isPcp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTypeConfigRequestBody
     */
    isPediatric?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTypeConfigRequestBody
     */
    isBlocked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    elationName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentTypeConfigRequestBody
     */
    skipCheckin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    shortCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentTypeConfigRequestBody
     */
    gatedAppointments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    iconName?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentTypeConfigRequestBody
     */
    careAccessDescription?: string;
}

export function AppointmentTypeConfigRequestBodyFromJSON(json: any): AppointmentTypeConfigRequestBody {
    return {
        'appointmentTypeId': !exists(json, 'appointment_type_id') ? undefined : json['appointment_type_id'],
        'uses': !exists(json, 'uses') ? undefined : json['uses'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'care': !exists(json, 'care') ? undefined : json['care'],
        'covidSurveyEmail': !exists(json, 'covid_survey_email') ? undefined : json['covid_survey_email'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'receiptCtg': !exists(json, 'receipt_ctg') ? undefined : json['receipt_ctg'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'must': !exists(json, 'must') ? undefined : json['must'],
        'configType': !exists(json, 'config_type') ? undefined : json['config_type'],
        'defaultReportingMapping': !exists(json, 'default_reporting_mapping') ? undefined : AppointmentTypeConfigSchemaDefaultReportingMappingFromJSON(json['default_reporting_mapping']),
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'isPediatric': !exists(json, 'is_pediatric') ? undefined : json['is_pediatric'],
        'isBlocked': !exists(json, 'is_blocked') ? undefined : json['is_blocked'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'elationName': !exists(json, 'elation_name') ? undefined : json['elation_name'],
        'skipCheckin': !exists(json, 'skip_checkin') ? undefined : json['skip_checkin'],
        'shortCode': !exists(json, 'short_code') ? undefined : json['short_code'],
        'gatedAppointments': !exists(json, 'gated_appointments') ? undefined : json['gated_appointments'],
        'iconName': !exists(json, 'icon_name') ? undefined : json['icon_name'],
        'careAccessDescription': !exists(json, 'care_access_description') ? undefined : json['care_access_description'],
    };
}

export function AppointmentTypeConfigRequestBodyToJSON(value?: AppointmentTypeConfigRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointment_type_id': value.appointmentTypeId,
        'uses': value.uses,
        'name': value.name,
        'note': value.note,
        'care': value.care,
        'covid_survey_email': value.covidSurveyEmail,
        'color': value.color,
        'method': value.method,
        'label': value.label,
        'receipt_ctg': value.receiptCtg,
        'description': value.description,
        'must': value.must,
        'config_type': value.configType,
        'default_reporting_mapping': AppointmentTypeConfigSchemaDefaultReportingMappingToJSON(value.defaultReportingMapping),
        'is_pcp': value.isPcp,
        'is_pediatric': value.isPediatric,
        'is_blocked': value.isBlocked,
        'duration': value.duration,
        'elation_name': value.elationName,
        'skip_checkin': value.skipCheckin,
        'short_code': value.shortCode,
        'gated_appointments': value.gatedAppointments,
        'icon_name': value.iconName,
        'care_access_description': value.careAccessDescription,
    };
}

/**
* @export
* @enum {string}
*/
export enum AppointmentTypeConfigRequestBodyConfigTypeEnum {
    InternalOnly = 'internal_only',
    PublicOnly = 'public_only',
    PublicAndInternal = 'public_and_internal'
}


