import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "components/Grid";
import Divider from "components/Divider";
import ProviderDetail from "./ProviderDetail";
import VisitTimeAndDate from "./VisitTimeAndDate";

interface IProps {
  providerName: string;
  avatarUrl: string;
  clinic?: string;
  visitType?: string;
  serviceLine: string;
  providerId: string;
  timeAndDate?: string;
  labelWithWeekday?: string;
  step: string;
  isPcp: boolean;
}
const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  wrapper: {
    borderRadius: spacing(1),
    padding: spacing(2),
    backgroundColor: palette?.appBackground?.main,
    // width: "492px",
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pickerPageWrapper: {
    // width: "492px",
    [breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  divider: {
    border: `1px solid ${palette.borderGray}`,
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  rowContainer: {
    display: "flex",
  },
  dateAndTimeRow: {
    alignItems: "center",
  },
  headline: {
    marginBottom: spacing(0.5),
  },
  bodyText: {
    color: palette?.text?.primary,
  },
  smallBodyText: {
    color: palette?.text?.secondary,
    display: "flex",
  },
  avatarContainer: {
    height: "100%",
    maxWidth: "30%",
    marginRight: spacing(1),
  },
}));

const ProviderVisitTile = ({
  providerName,
  clinic,
  visitType,
  serviceLine,
  labelWithWeekday,
  timeAndDate,
  avatarUrl,
  providerId,
  step,
  isPcp,
}: IProps) => {
  const classes = useStyles();
  const hasVisitTimeAndDate = !!timeAndDate;

  return (
    <Grid
      container
      direction="row"
      className={
        step === "providerDatePicker"
          ? classes.pickerPageWrapper
          : classes.wrapper
      }
    >
      <Grid container>
        <Grid item>
          <ProviderDetail
            providerName={providerName}
            clinic={clinic}
            visitType={visitType}
            serviceLine={serviceLine}
            avatarUrl={avatarUrl}
            classNames={classes}
            providerId={providerId}
            step={step}
            isPcp={isPcp}
          />
        </Grid>
      </Grid>
      {!!hasVisitTimeAndDate && (
        <>
          <Grid container>
            <Grid item sm={12}>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid container lg={12}>
            <Grid item>
              <VisitTimeAndDate
                timeAndDate={timeAndDate!}
                labelWithWeekday={labelWithWeekday}
                classNames={classes}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProviderVisitTile;
