import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { ButtonBase } from "@mui/material";
import parseISO from "date-fns/parseISO";
import Box from "components/Box";
import Typography from "components/Typography";
import { format } from "date-fns";

interface IProps {
  numSlots?: number;
  date?: string;
  onClick: any;
  provider: string;
}

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  hasSlots: {
    "&:hover": {
      backgroundColor: palette?.blueHover,
      cursor: "pointer",
    },
    backgroundColor: palette?.appBackground?.blue,
    color: palette?.white,
  },
  noSlots: {
    backgroundColor: palette?.appBackground?.main,
    color: palette?.appBackground?.darkGrey,
  },
  wrapper: {
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    borderRadius: spacing(1),
    width: "72px",
    height: "72px",
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    marginBottom: spacing(1),
    flexShrink: 0,
    textAlign: "left",
    [breakpoints.up("xl")]: {
      flexGrow: 1,
    },
  },
  topRow: {
    marginBottom: spacing(0.5),
    fontWeight: 700,
  },
  row: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
}));

const DateTile = ({ date, numSlots, onClick, provider }: IProps) => {
  const classes = useStyles();

  const isoDate = parseISO(date || "");
  const formattedDate = format(isoDate, "EEE MMM d");
  const [weekday, month, day] = formattedDate.toString().split(" ");

  const formatVisits = () => {
    if (!!numSlots) {
      if (numSlots === 1) {
        return "1 visit";
      } else {
        return `${numSlots} visits`;
      }
    } else {
      return "no visits";
    }
  };
  return (
    <ButtonBase
      onClick={onClick}
      disabled={!numSlots}
      data-testid={!numSlots ? "visit-slot-disabled" : "visit-slot-enabled"}
      aria-label={`${weekday} ${month} ${day} ${formatVisits()} with ${provider}`}
    >
      <Box
        className={clsx(
          classes.wrapper,
          !!numSlots ? classes.hasSlots : classes.noSlots
        )}
      >
        <Box className={clsx(classes.topRow, classes.row)}>
          <Typography appearance="smallBody">{weekday}</Typography>
          <Typography className={classes.topRow}>
            {month} {day}
          </Typography>
        </Box>
        <Typography className={classes.row} appearance="smallBody">
          {formatVisits()}
        </Typography>
      </Box>
    </ButtonBase>
  );
};
export default DateTile;
