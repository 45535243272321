// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InternalServerErrorResponse,
    InternalServerErrorResponseFromJSON,
    InternalServerErrorResponseToJSON,
} from '../models/InternalServerErrorResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    QuestionSetDetailsSchema,
    QuestionSetDetailsSchemaFromJSON,
    QuestionSetDetailsSchemaToJSON,
} from '../models/QuestionSetDetailsSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    XopQuestionSetCoreOutputSchema,
    XopQuestionSetCoreOutputSchemaFromJSON,
    XopQuestionSetCoreOutputSchemaToJSON,
} from '../models/XopQuestionSetCoreOutputSchema'


export interface DeleteQuestionSetRequest {
    id: string;
}

export interface FetchQuestionSetByIdRequest {
    episodeId: string;
    questionSetId: string;
}

export interface UpdateQuestionSetByIdRequest {
    id: string;
    questionSetDetailsSchema?: QuestionSetDetailsSchema;
}


/**
 * deactivate a question set by id
 */
function deleteQuestionSetRaw<T>(requestParameters: DeleteQuestionSetRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteQuestionSet.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* deactivate a question set by id
*/
export function deleteQuestionSet<T>(requestParameters: DeleteQuestionSetRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteQuestionSetRaw(requestParameters, requestConfig);
}

/**
 * Fetch question set by ID
 * Fetch question set by ID
 */
function fetchQuestionSetByIdRaw<T>(requestParameters: FetchQuestionSetByIdRequest, requestConfig: runtime.TypedQueryConfig<T, XopQuestionSetCoreOutputSchema> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling fetchQuestionSetById.');
    }

    if (requestParameters.questionSetId === null || requestParameters.questionSetId === undefined) {
        throw new runtime.RequiredError('questionSetId','Required parameter requestParameters.questionSetId was null or undefined when calling fetchQuestionSetById.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/question_sets/{question_set_id}`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"question_set_id"}}`, encodeURIComponent(String(requestParameters.questionSetId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(XopQuestionSetCoreOutputSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch question set by ID
* Fetch question set by ID
*/
export function fetchQuestionSetById<T>(requestParameters: FetchQuestionSetByIdRequest, requestConfig?: runtime.TypedQueryConfig<T, XopQuestionSetCoreOutputSchema>): QueryConfig<T> {
    return fetchQuestionSetByIdRaw(requestParameters, requestConfig);
}

/**
 * update the specified question set
 * update the specified question set
 */
function updateQuestionSetByIdRaw<T>(requestParameters: UpdateQuestionSetByIdRequest, requestConfig: runtime.TypedQueryConfig<T, QuestionSetDetailsSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateQuestionSetById.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || QuestionSetDetailsSchemaToJSON(requestParameters.questionSetDetailsSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(QuestionSetDetailsSchemaFromJSON(body), text);
    }

    return config;
}

/**
* update the specified question set
* update the specified question set
*/
export function updateQuestionSetById<T>(requestParameters: UpdateQuestionSetByIdRequest, requestConfig?: runtime.TypedQueryConfig<T, QuestionSetDetailsSchema>): QueryConfig<T> {
    return updateQuestionSetByIdRaw(requestParameters, requestConfig);
}

