// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpisodeSummaryCoreOutputXopPod
 */
export interface EpisodeSummaryCoreOutputXopPod  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutputXopPod
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutputXopPod
     */
    clinicId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutputXopPod
     */
    virtual?: boolean;
}

export function EpisodeSummaryCoreOutputXopPodFromJSON(json: any): EpisodeSummaryCoreOutputXopPod {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'virtual': !exists(json, 'virtual') ? undefined : json['virtual'],
    };
}

export function EpisodeSummaryCoreOutputXopPodToJSON(value?: EpisodeSummaryCoreOutputXopPod): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'clinic_id': value.clinicId,
        'virtual': value.virtual,
    };
}


