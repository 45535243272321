import React from "react";
import Box from "components/Box";
import Typography from "components/Typography";
import makeStyles from "@mui/styles/makeStyles";

interface IProps {
  month: string;
  day: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  cardBackground: {
    height: "40px",
    width: "40px",
    padding: spacing(1),
    borderRadius: "4px",
    backgroundColor: palette?.appBackground?.navy,
  },
  textBackground: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: palette?.white,
  },
}));

const DateCard = ({ month, day }: IProps) => {
  const classes = useStyles();
  const monthDisplay = month?.slice(0, 3).toUpperCase();
  return (
    <Box className={classes.cardBackground}>
      <Box className={classes.textBackground}>
        <Typography appearance="miniCaption">{monthDisplay}</Typography>
        <Typography appearance="smallBody">{day}</Typography>
      </Box>
    </Box>
  );
};

export default DateCard;
