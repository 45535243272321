import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { State } from "shared/types";
import { setCurrentLocation } from "shared/state/ui/careAccess/actions";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { ICurrentLocation } from "shared/state/ui/careAccess";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import LocationComboBox from "./LocationComboBox";
import LocationDialogBox from "./LocationDialogBox";
import { USStatesList } from "features/careAccess/utils/states";

interface ILocationPicker {
  customWidth?: string;
  isMobileScreen?: boolean;
  isSideBar?: boolean;
}

const LocationPicker: FunctionComponent<ILocationPicker> = ({
  customWidth = "",
  isMobileScreen = false,
  isSideBar = false,
}) => {
  const user = selectLoggedInUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationState } = useSelector((state: State) => state.ui.careAccess);
  const [selectedState, setSelectedState] = useState(locationState);
  const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);

  useEffect(() => {
    setSelectedState(locationState);
  }, [locationState]);

  const { accountInfo } =
    useGetAccountInfo({
      id: user?.id?.toString() || "",
    }) || {};

  const allStatesNameCodes =
    USStatesList.map((state) => {
      return { code: state.value, label: state.text };
    }) || [];

  useEffect(() => {
    if (!locationState?.code && !!accountInfo?.state) {
      const matchedState = allStatesNameCodes.find(
        (state) => state?.code === accountInfo?.state
      ) || { code: "", label: "" };
      handleLocationChange(matchedState);
    }
  }, [accountInfo?.state]);

  const handleLocationChange = (value: ICurrentLocation) => {
    if (isSideBar) {
      setSelectedState(value);
      setIsDialogBoxOpen(true);
    } else {
      setSelectedState(value);
      dispatch(setCurrentLocation(value));
    }
  };

  const handleDialogYesSelection = () => {
    setIsDialogBoxOpen(false);
    dispatch(setCurrentLocation(selectedState));
    navigate(`/members/${user?.id}/care-access/services`);
  };

  const handleDialogClose = () => {
    setIsDialogBoxOpen(false);
    setSelectedState(locationState);
  };

  return (
    <>
      <LocationComboBox
        isPrimary={!isSideBar}
        options={allStatesNameCodes}
        selectedValue={selectedState}
        onChange={handleLocationChange}
        customWidth={customWidth}
        isMobileScreen={isMobileScreen}
        padding={"2px"}
      />

      <LocationDialogBox
        isOpen={isDialogBoxOpen}
        handleClose={handleDialogClose}
        handleDialogYesSelection={handleDialogYesSelection}
      />
    </>
  );
};

export default LocationPicker;
