import { useQuery } from "react-query";
import {
  FetchMemberServiceLinesRequest,
  fetchServiceLinesApi,
} from "shared/fetch/src/apis/ServiceLinesApi";

export const memberServiceLinesQueryKey = (
  id: string | number,
  usState?: string
) => ["memberServiceLines", id, usState];

const useGetMemberServiceLines = (request: FetchMemberServiceLinesRequest) => {
  const { data, isLoading } = useQuery(
    memberServiceLinesQueryKey(request.id, request.usState),
    () => fetchServiceLinesApi.fetchMemberServiceLines(request),
    {
      enabled: !!request.id && request.id !== "undefined",
    }
  );

  return {
    memberServiceLines: data?.results || [],
    isLoading,
  };
};

export default useGetMemberServiceLines;
