// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    CmsServiceLineItem,
    CmsServiceLineItemFromJSON,
    CmsServiceLineItemToJSON,
} from '../models/CmsServiceLineItem'
import {
    CmsServiceLineRequestBody,
    CmsServiceLineRequestBodyFromJSON,
    CmsServiceLineRequestBodyToJSON,
} from '../models/CmsServiceLineRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ServiceLineEditRequestBody,
    ServiceLineEditRequestBodyFromJSON,
    ServiceLineEditRequestBodyToJSON,
} from '../models/ServiceLineEditRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface AddServiceLineRequest {
    cmsServiceLineRequestBody?: CmsServiceLineRequestBody;
}

export interface DeleteServiceLineRequest {
    serviceLine: string;
}

export interface UpdateServiceLineRequest {
    serviceLine: string;
    serviceLineEditRequestBody?: ServiceLineEditRequestBody;
}


/**
 * creates a service line
 * creates a service line
 */
function addServiceLineRaw<T>(requestParameters: AddServiceLineRequest, requestConfig: runtime.TypedQueryConfig<T, CmsServiceLineItem> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/service_lines`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CmsServiceLineRequestBodyToJSON(requestParameters.cmsServiceLineRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CmsServiceLineItemFromJSON(body), text);
    }

    return config;
}

/**
* creates a service line
* creates a service line
*/
export function addServiceLine<T>(requestParameters: AddServiceLineRequest, requestConfig?: runtime.TypedQueryConfig<T, CmsServiceLineItem>): QueryConfig<T> {
    return addServiceLineRaw(requestParameters, requestConfig);
}

/**
 * deactivate a service line
 */
function deleteServiceLineRaw<T>(requestParameters: DeleteServiceLineRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
        throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling deleteServiceLine.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* deactivate a service line
*/
export function deleteServiceLine<T>(requestParameters: DeleteServiceLineRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteServiceLineRaw(requestParameters, requestConfig);
}

/**
 * update service line
 * update service line
 */
function updateServiceLineRaw<T>(requestParameters: UpdateServiceLineRequest, requestConfig: runtime.TypedQueryConfig<T, CmsServiceLineItem> = {}): QueryConfig<T> {
    if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
        throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling updateServiceLine.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || ServiceLineEditRequestBodyToJSON(requestParameters.serviceLineEditRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CmsServiceLineItemFromJSON(body), text);
    }

    return config;
}

/**
* update service line
* update service line
*/
export function updateServiceLine<T>(requestParameters: UpdateServiceLineRequest, requestConfig?: runtime.TypedQueryConfig<T, CmsServiceLineItem>): QueryConfig<T> {
    return updateServiceLineRaw(requestParameters, requestConfig);
}

