// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberCentersObject
 */
export interface MemberCentersObject  {
    /**
     * ID associated with the specific team or center
     * @type {string}
     * @memberof MemberCentersObject
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MemberCentersObject
     */
    podName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberCentersObject
     */
    podId: string;
    /**
     * 
     * @type {string}
     * @memberof MemberCentersObject
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberCentersObject
     */
    coreCareTeam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberCentersObject
     */
    generic?: boolean;
    /**
     * Boolean used to determine if pod can be set as default
     * @type {boolean}
     * @memberof MemberCentersObject
     */
    canBeDefaultPod?: boolean;
}

export function MemberCentersObjectFromJSON(json: any): MemberCentersObject {
    return {
        'id': json['id'],
        'podName': !exists(json, 'pod_name') ? undefined : json['pod_name'],
        'podId': json['pod_id'],
        'name': json['name'],
        'coreCareTeam': !exists(json, 'core_care_team') ? undefined : json['core_care_team'],
        'generic': !exists(json, 'generic') ? undefined : json['generic'],
        'canBeDefaultPod': !exists(json, 'can_be_default_pod') ? undefined : json['can_be_default_pod'],
    };
}

export function MemberCentersObjectToJSON(value?: MemberCentersObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'pod_name': value.podName,
        'pod_id': value.podId,
        'name': value.name,
        'core_care_team': value.coreCareTeam,
        'generic': value.generic,
        'can_be_default_pod': value.canBeDefaultPod,
    };
}


