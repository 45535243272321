import { reduce } from "lodash";
import { useInfiniteQuery } from "react-query";
import {
  fetchSlotsApi,
  GetAvailableTimeslotsRequest,
} from "shared/fetch/src/apis";
import { omit, isEmpty } from "lodash";
import { useEffect } from "react";

export const availableTimeslotsQueryKey = (key: any) => [
  "availableTimeslots",
  key,
];

let fetching = false;

export const useGetAvailableTimeslots = (
  request: GetAvailableTimeslotsRequest
) => {
  const key = omit(request, ["startDate"]);

  const {
    data,
    fetchNextPage: fetchNextTimeslotsPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  } = useInfiniteQuery(
    availableTimeslotsQueryKey(key),
    ({ pageParam }) => {
      if (!pageParam) {
        return fetchSlotsApi.getAvailableTimeslots(request);
      }
      return fetchSlotsApi.getAvailableTimeslots({
        ...request,
        startDate: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (!isEmpty(lastPage) && !lastPage?.nextStartAt) {
          return false;
        }
        return lastPage?.nextStartAt;
      },
      keepPreviousData: true,
      enabled: !!request.startDate,
    }
  );

  const availableSlots = reduce(
    data?.pages,
    (acc: any, { results: fetchedSlots = [] }) => {
      return [...acc, ...fetchedSlots];
    },
    []
  );

  const fetchNextPage = (force?: boolean) => {
    if (!fetching || force) {
      fetching = true;
      fetchNextTimeslotsPage();
    }
  };

  useEffect(() => {
    fetching = false;
  }, [availableSlots.length]);

  return {
    results: availableSlots,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
  };
};

export default useGetAvailableTimeslots;
