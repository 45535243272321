import React from "react";
import { useOutletContext } from "react-router";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { State } from "@types";
import { Steps } from "shared/state/ui/careAccess/steps";
import Header from "../CareAccessHeader";
import Footer from "../CareAccessFooter";
import ButtonBase from "components/Button/ButtonBase";
import useMobileCheck from "hooks/useMobileCheck";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  wrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  content: {
    width: "100%",
    marginLeft: "28px",
    [breakpoints.down("sm")]: {
      marginLeft: "8px",
    },
  },
  backButton: {
    color: palette.text.secondary,
  },
}));

const CareAccessContent: React.FC = ({ children }) => {
  const classes = useStyles();
  const isMobile = useMobileCheck();
  const { goToPreviousPage } = useOutletContext<IOutletContext>();
  const { currentStep } = useSelector((state: State) => state.ui.careAccess);
  const excludeFooterPages = [Steps.MESSAGE, Steps.DATE_PICKER, Steps.FINALIZE];

  const renderBackButton = () => {
    return (
      <ButtonBase
        className={classes.backButton}
        onClick={goToPreviousPage}
        aria-label="Head back to the previous page"
      >
        <ArrowBackIcon />
      </ButtonBase>
    );
  };

  return (
    <>
      {isMobile ? (
        <div data-testid={`care-access-${currentStep}`}>
          <div style={{ display: "flex" }}>
            {renderBackButton()}
            <Header currentStep={currentStep} />
          </div>
          {children}
          {!excludeFooterPages.includes(currentStep) && <Footer />}
        </div>
      ) : (
        <div
          className={classes.wrapper}
          data-testid={`care-access-${currentStep}`}
        >
          {renderBackButton()}
          <div className={classes.content}>
            <Header currentStep={currentStep} />
            {children}
            {!excludeFooterPages.includes(currentStep) && <Footer />}
          </div>
        </div>
      )}
    </>
  );
};

export default CareAccessContent;
