// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberServiceLinesSchema
 */
export interface MemberServiceLinesSchema  {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    careDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    fullDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    abbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    serviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    assetName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceLinesSchema
     */
    visitMethods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceLinesSchema
     */
    hasMessaging?: boolean;
}

export function MemberServiceLinesSchemaFromJSON(json: any): MemberServiceLinesSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'careDescription': !exists(json, 'care_description') ? undefined : json['care_description'],
        'fullDescription': !exists(json, 'full_description') ? undefined : json['full_description'],
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'backgroundColor': !exists(json, 'background_color') ? undefined : json['background_color'],
        'serviceType': !exists(json, 'service_type') ? undefined : json['service_type'],
        'assetName': !exists(json, 'asset_name') ? undefined : json['asset_name'],
        'visitMethods': !exists(json, 'visit_methods') ? undefined : json['visit_methods'],
        'hasMessaging': !exists(json, 'has_messaging') ? undefined : json['has_messaging'],
    };
}

export function MemberServiceLinesSchemaToJSON(value?: MemberServiceLinesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'care_description': value.careDescription,
        'full_description': value.fullDescription,
        'abbreviation': value.abbreviation,
        'color': value.color,
        'background_color': value.backgroundColor,
        'service_type': value.serviceType,
        'asset_name': value.assetName,
        'visit_methods': value.visitMethods,
        'has_messaging': value.hasMessaging,
    };
}


