import { useMutation } from "react-query";
import { fetchEpisodesOfCareApi } from "shared/fetch/src/apis";
import { EpisodeCreationInput } from "shared/fetch/src/models/EpisodeCreationInput";

const useCreateEpisode = () => {
  const { isLoading: isPending, mutateAsync: createEpisode } = useMutation(
    async (request: EpisodeCreationInput) =>
      fetchEpisodesOfCareApi.startEpisodeOfCare({
        episodeCreationInput: request,
      })
  );

  return {
    isPending,
    createEpisode,
  };
};

export default useCreateEpisode;
