import React, { useState } from "react";
import Grid from "components/Grid";
import Typography from "components/Typography";
import DateTile from "./DateTile";
import { CapacityFetchResponseSlotsCount } from "shared/api/src/models/CapacityFetchResponseSlotsCount";
import { Button, ClassNameMap } from "@mui/material";
import NoAvailableSlots from "./NoAvailableSlots";
import SlotsDrawer from "../../components/SlotsDrawer";
import { ProviderSummarySchema } from "shared/fetch/src/models/ProviderSummarySchema";
import useMobileCheck from "hooks/useMobileCheck";
interface IClinicSlotsProps {
  clinicName?: string;
  clinicId: string;
  provider: ProviderSummarySchema;
  clinicSlots?: CapacityFetchResponseSlotsCount[];
  nextAvailableText: string;
  nextDateWithSlots?: string;
  classes?: ClassNameMap;
  visitType: any;
  onAppointmentSlotSelected: any;
  appointmentName: string;
  isVirtualAppointment: boolean;
  careAccessState?: string;
}

const ClinicSlotsRow = ({
  clinicName,
  clinicId,
  visitType,
  provider,
  clinicSlots,
  classes,
  onAppointmentSlotSelected,
  appointmentName,
  nextAvailableText,
  nextDateWithSlots,
  isVirtualAppointment,
  careAccessState,
}: IClinicSlotsProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [infiniteScrollMode, setInfiniteScrollMode] = useState(false);
  const [seeMoreAvailability, setSeeMoreAvailability] = useState(false);

  const showNoAvailableSlotsView = clinicSlots?.every(
    (slot) => slot?.count === 0
  );

  const handleDateClick = (date: any, isSeeMore: boolean) => {
    setStartDate(date);
    setSeeMoreAvailability(isSeeMore);
    if (isSeeMore) {
      setInfiniteScrollMode(true);
    }
    setIsDrawerOpen(true);
  };

  const isMobile = useMobileCheck();

  const getClassName = () => {
    if (isVirtualAppointment) {
      if (showNoAvailableSlotsView) {
        return classes?.noAvailableVirtualVisits;
      } else {
        return classes?.virtualClinicsAndSlotsContainer;
      }
    } else {
      return classes?.clinicAndSlotsContainer;
    }
  };

  return (
    <>
      <Grid container className={getClassName()}>
        <Grid xs={12}>
          <Typography className={classes?.clinicName} appearance="smallBody">
            {clinicName ?? ""}
          </Typography>
        </Grid>
        <Grid
          className={
            isVirtualAppointment ? classes?.slotsRowVirtual : classes?.slotsRow
          }
        >
          {showNoAvailableSlotsView ? (
            <NoAvailableSlots
              nextAvailableText={nextAvailableText}
              classes={classes}
              onDateSelected={() => handleDateClick(nextDateWithSlots, false)}
            />
          ) : (
            clinicSlots?.map(
              (slots: CapacityFetchResponseSlotsCount, i: number) => (
                <DateTile
                  key={`slots-for-date-${i}`}
                  date={slots.tzDate}
                  numSlots={slots.count}
                  provider={provider?.name}
                  onClick={() => {
                    if (slots.count) {
                      handleDateClick(slots.tzDate, false);
                    }
                  }}
                />
              )
            )
          )}
        </Grid>
        <SlotsDrawer
          onClose={() => {
            setIsDrawerOpen(false);
            setStartDate("");
            setInfiniteScrollMode(false);
            setSeeMoreAvailability(false);
          }}
          onSelectDate={onAppointmentSlotSelected}
          provider={provider}
          clinicId={clinicId}
          clinicName={clinicName || ""}
          selectedStartDate={startDate}
          seeMoreAvailability={seeMoreAvailability}
          visitType={visitType}
          appointmentName={appointmentName}
          open={isDrawerOpen}
          infiniteScrollMode={infiniteScrollMode}
          setInfiniteScrollMode={setInfiniteScrollMode}
          careAccessState={careAccessState}
        />
      </Grid>
      {isMobile && !showNoAvailableSlotsView && (
        <Button
          color="secondary"
          onClick={
            clinicSlots
              ? () => {
                  handleDateClick(clinicSlots[0].tzDate, true);
                }
              : undefined
          }
          className={classes?.seeMore}
        >
          See more availability
        </Button>
      )}
    </>
  );
};

export default ClinicSlotsRow;
