import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router";
import { isEqual, lowerCase, upperFirst } from "lodash";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import { State } from "@types";
import { IDocument } from "shared/state/ui/careAccess";
import { DocumentObjectDetails } from "shared/fetch/src/models/DocumentObjectDetails";
import {
  setMessageDocuments,
  setMessageBody,
  deleteMessageDocument,
  resetAllCareAccessData,
} from "shared/state/ui/careAccess/actions";
import { Steps } from "shared/state/ui/careAccess/steps";
import useGetSubjects from "shared/features/useGetSubject";
import { useFetchMemberRecipient } from "shared/state/messageRecipients";
import { showSnackbar } from "shared/state/ui/snackbar";
import useCreateEpisode from "shared/features/episodes/useCreateEpisode";
import CareAccessContent from "features/careAccess/components/CareAccessContent";
import SubjectComboBox from "features/careAccess/components/SubjectComboBox";
import RecipientComboBox from "features/careAccess/components/RecipientComboBox";
import TextField from "components/TextField";
import Button from "components/Button";
import Box from "components/Box";
import Tooltip from "components/Tooltip";
import ButtonBase from "components/Button/ButtonBase";
import OOONotification from "components/DirectMessaging/OOONotification";
import ConsentForCareModal from "components/ServiceMenu/SubmitEOC/ConsentForCareModal";
import CareAccessAttachFiles from "features/careAccess/components/CareAccessAttachFiles";
import IOutletContext from "features/careAccess/utils/OutletContextInterface";
import { isXopPodOutputSchema } from "components/ProviderDropDown/utils";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  icon: {
    fill: palette?.appBackground?.darkGrey,
    marginLeft: "6px",
  },
  tooltip: {
    width: "250px",
    fontSize: "13.3px",
    backgroundColor: palette?.appBackground?.main,
    border: `1px solid ${palette?.text?.strokes}`,
    boxShadow: `2px 2px 6px ${palette.boxShadowLightGray}`,
    borderRadius: 4,
    color: palette?.appBackground?.darkGrey,
  },
  messageBox: {
    marginTop: "32px",
    minHeight: "219px",
    display: "block",
    border: `1px solid ${palette?.text?.tertiary}`,
    borderRadius: "8px",
    "& .MuiFilledInput-root": {
      backgroundColor: palette?.white,
      borderRadius: "8px",
      borderBottom: "none",
    },
    "& .MuiFilledInput-root:before": {
      borderBottom: "none !important",
    },
    "& .MuiFilledInput-root:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-root:hover": {
      borderBottom: "none",
    },
    "@media (min-width: 576px)": {
      width: "474px",
    },
  },
  submitButton: {
    display: "block",
    marginTop: "16px",
    [breakpoints.down("md")]: {
      width: "100%",
    },
  },
  tooltipBtnBase: {
    marginTop: 20,
  },
  recipientTooltip: {
    display: "flex",
  },
}));

const MessagesPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { memberId, hasConsented } = useOutletContext<IOutletContext>();
  const {
    userFlow,
    message: {
      messageRecipient,
      messageBody,
      messageSubject,
      documents: messageDocuments,
      hasSkippedDecisionsPage,
    },
    services,
    locationState,
  } = useSelector((state: State) => state.ui.careAccess);

  const subjects = useGetSubjects().data || [];
  const providers = useFetchMemberRecipient({
    id: memberId || "",
    serviceLineId: services.serviceLineId,
    state: locationState.code,
  });

  const { isPending, createEpisode } = useCreateEpisode();

  const subjectOptions = useMemo(() => {
    return subjects?.map((s: string) => {
      return upperFirst(lowerCase(s));
    });
  }, [subjects]);

  const [message, setMessage] = useState("");
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const isCMByMessageFlow = isEqual(userFlow, [
    Steps.SERVICES,
    Steps.DECISIONS,
    Steps.MESSAGE,
  ]);
  const isDMFlow = isEqual(userFlow, [Steps.MESSAGE]);

  const placeholder = isCMByMessageFlow
    ? "Please provide any details and attach any relevant photos. The more information, the better."
    : "Compose message";

  const contacts = providers?.memberRecipients?.recipients || [];
  const infoText =
    contacts.length > 0
      ? "If you select a specific provider they might not be available. In this case, another member of your Care Team will respond to this message to ensure a timely response."
      : "As a first-time user, your Care Team will first review your message before directing it to an appropriate provider.";

  const oooNotifications = messageRecipient?.oooNotifications || [];

  const handleAttachFile = (_file: File, result: DocumentObjectDetails) => {
    setDocuments((prevDocuments: any) => {
      const { id, name, thumbnailUrl, mime } = result;
      const newDocuments = [
        ...prevDocuments,
        {
          name,
          id,
          thumbnailUrl,
          mime,
        },
      ];
      dispatch(setMessageDocuments(newDocuments));
      return newDocuments;
    });
  };

  const handleDetachFile = (id: string) => {
    dispatch(deleteMessageDocument(id));
  };

  const [consentModalOpen, setConsentModalOpen] = useState(false);
  const handleConsentModalClose = () => setConsentModalOpen(false);
  const handleConsentAffirmation = () => {
    setConsentModalOpen(false);
    handleCreateEpisode();
  };

  const handleSubmit = () => {
    if (!hasConsented) {
      setConsentModalOpen(true);
    } else {
      handleCreateEpisode();
    }
  };

  const handleCreateEpisode = () => {
    const convertSub = messageSubject
      ?.trim()
      .replace(/\s/gi, "_")
      .toLowerCase();
    const isSubjectFreeText = subjects
      ? !subjects.find((value) => value === convertSub)
      : true;

    createEpisode({
      memberId: memberId || "",
      body: message,
      documentIds: documents.map((doc) => doc.id),
      recipientPodId:
        !!messageRecipient && isXopPodOutputSchema(messageRecipient)
          ? messageRecipient?.id
          : undefined,
      recipientId:
        !!messageRecipient && isXopPodOutputSchema(messageRecipient)
          ? undefined
          : messageRecipient?.id,
      subjectFreetext: isSubjectFreeText ? messageSubject.trim() : undefined,
      subject: !isSubjectFreeText ? convertSub : undefined,
      ccCtmIds: [],
      usState: locationState.code,
    })
      .then((response) => {
        dispatch(showSnackbar("Success! Message has been sent!"));
        const episodeId = response?.id;
        const memId = response?.memberInfo?.id;
        navigate(`/members/${memId}/conversations/${episodeId}`);
        dispatch(resetAllCareAccessData());
      })
      .catch(() => {
        dispatch(
          showSnackbar("There was a problem sending this message.", "danger")
        );
      });
  };

  useEffect(() => {
    if (messageBody) {
      setMessage(messageBody);
    }

    if (messageDocuments) {
      setDocuments(messageDocuments);
    }
  }, [messageBody, messageSubject, messageDocuments]);

  return (
    <CareAccessContent>
      <Box mt={4}>
        {(isCMByMessageFlow || isDMFlow || hasSkippedDecisionsPage) && (
          <Box>
            {oooNotifications.length > 0 && (
              <OOONotification
                isCareAccess
                name={messageRecipient?.name}
                endDate={oooNotifications[0]?.endDate}
                clinic={messageRecipient?.clinics?.[0]}
              />
            )}
            <Box
              mt={2}
              mb={2}
              alignItems="center"
              className={classes.recipientTooltip}
            >
              <RecipientComboBox
                recipients={providers?.memberRecipients?.recipients}
                careTeam={providers?.memberRecipients?.careTeam}
                isPending={providers?.queryState?.isPending}
              />
              <Tooltip
                title={infoText}
                placement="bottom-start"
                classes={{ tooltip: classes.tooltip }}
                aria-label="Info"
              >
                <ButtonBase className={classes.tooltipBtnBase}>
                  <InfoIcon className={classes.icon} />
                </ButtonBase>
              </Tooltip>
            </Box>
          </Box>
        )}

        <SubjectComboBox
          options={subjectOptions}
          characterLimit={65}
          label="Subject"
        />
      </Box>

      <TextField
        data-testid="care-access-dm-message"
        className={classes.messageBox}
        multiline
        fullWidth
        variant="filled"
        margin="dense"
        inputProps={{
          style: { minHeight: "219px" },
        }}
        value={messageBody}
        placeholder={placeholder}
        onChange={(event) => {
          setMessage(event.target.value);
          dispatch(setMessageBody(event.target.value));
        }}
      />
      <CareAccessAttachFiles
        handleDetachFile={handleDetachFile}
        handleAttachFile={handleAttachFile}
        documents={messageDocuments}
      />

      <Button
        color="primary"
        className={classes.submitButton}
        onClick={handleSubmit}
        disabled={!messageSubject || !message || !messageRecipient || isPending}
      >
        Create conversation
      </Button>

      <ConsentForCareModal
        open={consentModalOpen}
        toggle={handleConsentModalClose}
        consentAffirmed={handleConsentAffirmation}
        noConsent={handleConsentModalClose}
      />
    </CareAccessContent>
  );
};

export default MessagesPage;
