import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "components/Typography";
import LocationPicker from "../LocationPicker";
import useMobileCheck from "hooks/useMobileCheck";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    "& p": {
      marginRight: "7px",
    },
  },
}));

const ServicesLocation = () => {
  const classes = useStyles();
  const isMobile = useMobileCheck();

  return (
    <div className={classes.wrapper}>
      <Typography variant="body2" color="textSecondary">
        Where will you be at the time of service?
      </Typography>
      <LocationPicker
        customWidth={isMobile ? "160px" : "240px"}
        isMobileScreen={isMobile}
      />
    </div>
  );
};

export default ServicesLocation;
