// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    RescheduleAppointment,
    RescheduleAppointmentFromJSON,
    RescheduleAppointmentToJSON,
} from '../models/RescheduleAppointment'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface RescheduleAppointmentOfComponentByIDRequest {
    id: string;
    rescheduleAppointment?: RescheduleAppointment;
}


/**
 * Reschedule the appointment associated with the online visit referral component with the given ID initiated either by Member or CTM, and, migrate the component state accordingly.
 */
function rescheduleAppointmentOfComponentByIDRaw<T>(requestParameters: RescheduleAppointmentOfComponentByIDRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rescheduleAppointmentOfComponentByID.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/reschedule`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || RescheduleAppointmentToJSON(requestParameters.rescheduleAppointment),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Reschedule the appointment associated with the online visit referral component with the given ID initiated either by Member or CTM, and, migrate the component state accordingly.
*/
export function rescheduleAppointmentOfComponentByID<T>(requestParameters: RescheduleAppointmentOfComponentByIDRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return rescheduleAppointmentOfComponentByIDRaw(requestParameters, requestConfig);
}

