import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  container: {
    [breakpoints.up("xl")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
  },
  container2: {
    [breakpoints.up("xl")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  ctaContainerGrid: {
    alignItems: "stretch",
    padding: spacing(2, 0),
    [breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  ctaCard: {
    flexGrow: 1,
    height: "100%",
    [breakpoints.down("md")]: {
      maxWidth: 450,
    },
  },
  header: {
    color: palette.common.white,
    alignContent: "center",
  },
  getCare: {
    marginTop: "32px",
    height: "44px",
    alignSelf: "center",
  },
  centerText: {
    textAlign: "center",
    paddingTop: spacing(3),
    paddingLeft: "56px",
    paddingRight: "56px",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [breakpoints.up("xl")]: {
      paddingLeft: "115px",
      paddingRight: "151px",
    },
    [breakpoints.down("md")]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  preventiveCare: {
    padding: spacing(4.3, 0),
    paddingBottom: spacing(20),
  },
  preventiveCareContainer: {
    backgroundColor: "white",
    maxWidth: "100%",
    [breakpoints.only("xl")]: {
      paddingLeft: "220px",
      paddingRight: "220px",
    },
  },
  dashboardContainerAffinity: {
    [breakpoints.only("sm")]: {
      paddingLeft: "20px",
    },
    paddingTop: "0px",
  },
  dashboardContainer: {
    paddingTop: "0px",
  },
  headerContainer: {
    paddingBottom: spacing(2),
    paddingTop: 56,
    paddingLeft: 56,
    paddingRight: 56,
    [breakpoints.up("xl")]: {
      paddingLeft: 100,
      paddingRight: 100,
    },
    [breakpoints.only("md")]: {
      paddingTop: 50,
    },
    [breakpoints.down("md")]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(1),
    },
  },
  headerContainerAffinity: {
    [breakpoints.only("sm")]: {
      padding: "unset",
      height: "120px",
      alignItems: "end",
      display: "flex",
    },
  },
  headerMobile: {
    paddingTop: 20,
    paddingLeft: spacing(2),
  },
  dashboardButtonIcon: {
    width: "12.12px",
    height: "20px",
    marginLeft: "10px",
  },
  dashboardTitle: {
    color: palette.text.secondary,
    marginBottom: 8,
    [breakpoints.only("sm")]: {
      paddingLeft: "5px",
    },
  },
  dashboardTitlePadding: {
    paddingTop: spacing(4),
  },
  emptyComponentTitle: {
    color: palette.text.secondary,
    marginBottom: 8,
  },
  dashboardLink: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
    [breakpoints.only("sm")]: {
      paddingLeft: "5px",
    },
  },
  episodeContainer: {
    marginTop: 40,
    [breakpoints.down("md")]: {
      display: "block",
    },
  },
  dependentContainer: {
    paddingRight: 0,
  },
  navLinkPlaceholder: {
    maxWidth: 420,
    marginTop: 24,
    [breakpoints.down("md")]: {
      marginLeft: 24,
      marginRight: 24,
    },
    [breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  disclaimerText: {
    marginTop: "25px",

    [breakpoints.down("md")]: {
      width: "98%",
      marginTop: spacing(3),
      marginRight: spacing(1),
    },
  },
  healthRecordsLink: {
    [breakpoints.down("md")]: {
      marginBottom: "20px",
    },
  },
  conversationsList: {
    "& li": {
      listStyle: "none",
    },
    padding: 0,
  },
  mobileView: {
    display: "none",
    [breakpoints.down("lg")]: {
      display: "block",
    },
  },
  desktopView: {
    display: "block",
    [breakpoints.down("lg")]: {
      display: "none",
    },
  },
}));
